import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerVisibilityService } from "ng-http-loader";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "../services/shared.service";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-suggestions-card",
  templateUrl: "./suggestions-card-component.html",
  styleUrls: ["./suggestions-card-component.scss"],
})

export class SuggestionsCardsComponent implements OnInit {
  @ViewChild("cancelRequest") cancelRequest: TemplateRef<any>;
  
    bandSuggestionList:any = [];
  modalref: any;
  constructor(
    private modalService: BsModalService,
    public router: Router,
    private service: SharedService,
    private toastrService: ToastrService,
    private spinner: SpinnerVisibilityService
  ) {}

  ngOnInit(): void {
    // this.getPlayList();
    this.getBandSuggestion(1);
  }
  getBandSuggestion(page){
    let data = {
      "radius": 200,
      "phoneBook":[],
      "pageNumber":page,
      "bandName":'',
      "limit":1000,
      "location":{
        "lat": '',
        "long":'',    
      }
    }
    this.service.getBandSuggestions(data).subscribe((res:any) => {
      if(res.type == 'success'){
        this.bandSuggestionList = res.data;
      }
    })
  }
  addFriendButtonText:string = "Add Friend";

  AddFriend(id:any, index){
    let data = document.getElementById('addFriendText'+id).innerText;
    if(data=='Pending'){
      // this.spinner.show();
      // document.getElementById('addFriendText'+id).innerText = 'Add Friend'
      // document.getElementById('addFriendText'+id).style.background = 'black';
      // document.getElementById('addFriendText'+id).style.color = 'white';
      this.cancelRequestId = id;
      this.modalref = this.modalService.show(this.cancelRequest,{class:'cancelrequestmodall'});
      // this.service.cancelRequest({"action":"CANCEL"},id).subscribe((res:any)=>{
      //   if(res.type=='success'){
      //     this.toastrService.success(res.message); 
      //   }
      // })
      // this.spinner.hide();
    }else if(data=='Add Friend'){
      this.spinner.show();
      document.getElementById('addFriendText'+id).innerText = 'Pending'
      document.getElementById('addFriendText'+id).style.background='white';
      document.getElementById('addFriendText'+id).style.color='black';
      this.service.AddFriend({"recipientId":id}).subscribe((res:any) => {      
        if(res.type == 'success'){
          // this.toastrService.success(res.message); 
          this.toastrService.success("Friend request sent"); 
        }else{
        }
      },(err) => {
        this.toastrService.error(err.error.message);
      })
      this.spinner.hide();
    }else if(data=='Accept'){ 
      this.spinner.show();
      document.getElementById('addFriendText'+id).innerText = 'Friend'; 
      document.getElementById('addFriendText'+id).classList.add("friend");
      document.getElementById('addFriendText'+id).classList.remove("requestSent");

      document.getElementById('addFriendText'+id).classList.remove('btn:enabled');
      // document.getElementById('bandSuggestionList'+id).style.color='black';
      this.service.AcceptFriendRequest({"recipientId": id,"action": "ACCEPT"}).subscribe((res:any) => {
        if(res.type == 'success'){
          // this.toastr.success(res.message);   
          this.toastrService.success("Friend request accepted");
          // debugger;
          // let index = this.moreData.findIndex((obj)=>{
          //   return obj._id == id
          // });
          // if(index != -1){
          //   this.moreData[index].friendStatus = "FRIEND";
          // }
        }else{
          this.toastrService.error(res.message);   
        }
      },(err) => {
        this.toastrService.error(err.error.message);
      })
      this.spinner.hide();
    }
  
}
cancelRequestId;
cancelRequestModalRef;

showCancelRequestLoader: Boolean = false;
cancelFriendRequest(){
  this.showCancelRequestLoader = true;
  let id = this.cancelRequestId;
  
  
  this.service.cancelRequest(id,{"action":"CANCEL"}).subscribe((res:any)=>{
    this.showCancelRequestLoader = false;
    if(res.type=='success'){
      this.toastrService.success("Cancelled friend request");   
      document.getElementById('addFriendText'+id).innerText = 'Add Friend'
      document.getElementById('addFriendText'+id).style.background = 'black';
      document.getElementById('addFriendText'+id).style.color = 'white';
    }
    else{
      this.toastrService.info("please try after some time!"); 
    }
    this.modalref.hide();
  },err => {
    this.showCancelRequestLoader = false;
    this.toastrService.info("please try after some time!"); 
    this.modalref.hide();
  })
}

}
