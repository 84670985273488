import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {


  slides = [
    {image: '../../../../assets/sliders/indieslider1.svg', text: 'First'},
    {image: '../../../../assets/sliders/indieslider2.svg',text: 'Second'},
    {image: '../../../../assets/sliders/indieslider3.svg',text: 'Third'}
 ];
 noWrapSlides = false;
 showIndicator = false;
  constructor(
    private router: Router
  ) { }



  receiveEmail:any;
  counter!: Subscription;
  count:any=0;
  ngOnInit() {
      this.getTime();
   }

   getTime(){
    this.counter = timer(0,1000).subscribe((res)=> {
      let date = new Date();
      let second = date.getSeconds();
      this.count++;
      if(this.count==3){
        this.router.navigateByUrl('/auth/login');
      }
    })
   }

}
