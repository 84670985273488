import { Component, HostListener, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { DOCUMENT } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-spotify',
  templateUrl: './spotify.component.html',
  styleUrls: ['./spotify.component.scss','../dynamic-profile/dynamic-profile.component.scss']
})
export class SpotifyComponent implements OnInit {

  constructor(
    private service: SharedService,
    private toastrService: ToastrService,
    private spinner: SpinnerVisibilityService,
    @Inject(DOCUMENT) private document: Document,
    private modalService: BsModalService,
    private route: Router
  ) { }

  spotifyPlayerNew;
  spotifyToken;
  device_id;
  myInputValuedyn = '';
  @Input() userId;

  @ViewChild("removeSpotifyModal") removeSpotifyModal: TemplateRef<any>;


  openSpotify:Boolean = false;

  ngOnInit(): void {
    this.getSpotifyList();

    /** No Authorization 
    this.checkSpotifyAuthorized();*/



    // this.spotifyAuth();
    // this.spinner.show();
    // this.spinner.hide();
  }

  getSpotifyList(){
    this.spinner.show();
    this.service.getSpotifyList(this.userId,this.spotifyPageNumber,this.spotifyPageLimit).subscribe((res:any)=>{
      console.log(res);
      this.spinner.hide();
      if(res.data.length){
        for(let i=0;i<res.data.length;i++){
          if(res.data[i].contentType == 'playlist' || res.data[i].contentType == 'album'){
            res.data[i].expanded = false;
          }
        }
      }
      this.spotifyList = res.data;
      // this.originalSpotifyList = res.data;
      // if(this.spotifyList[0].trackType == 'playlist'){
      //   this.currentlyPlayingSpotifySong = this.spotifyList[0];
      //   this.spotifySongSelectedIndex = 0;
      // }
      // else{

      // }

      
      // debugger;
      // if(res.data.length && res.data.length == 1){
      //   if(this.spotifyList[0].contentType == 'playlist'){
      //     this.spotifySongTrackType = 'playlist';
      //     this.spotifySongPlaylistIndex = 0;
      //     this.spotifySongSelectedIndex = 0;
      //     this.currentlyPlayingSpotifySong = this.spotifyList[0].items[0];
      //     this.spotifySongSelected = true;
      //   }
      //   else{
      //     this.spotifySongTrackType = 'singleTrack';
      //     this.spotifySongSelectedIndex = 0;
      //     this.currentlyPlayingSpotifySong = this.spotifyList[0].items[0];
      //     this.currentlyPlayingSpotifySong.imageUrl = this.spotifyList[0].imageUrl;
      //     this.spotifySongSelected = true;
      //   }
      // }
      // else if(res.data.length){

      //   for(let i=0;i<this.spotifyList.length;i++){
      //     if(this.spotifyList[i].contentType == 'track'){
      //       this.spotifySongTrackType = 'singleTrack';
      //       this.spotifySongSelectedIndex = 0;
      //       this.currentlyPlayingSpotifySong = this.spotifyList[i].items[0];
      //       this.currentlyPlayingSpotifySong.imageUrl = this.spotifyList[i].imageUrl;
      //       this.spotifySongSelected = true;
      //       break;
      //     }
      //   // }

      // }
      
      // this.spinner.hide();
    },(err) => {
      this.spinner.hide();
      console.log("some error occured");
      this.toastrService.info("some error occured, please try again");
    }
    )
  }

  ngOnDestroy(){
    console.log("destroy");
    this.spotifyPlayerNew?.pause().then(() => {
      console.log('Paused!');
      this.spotifySongPaused = true;
      document.getElementById("spotifySongPlay").style.display = 'block';
      document.getElementById("spotifySongPause").style.display = 'none';
    });
  }

  spotifySongSelectedIndex:any;
  spotifySongSelected:boolean = false;
  currentlyPlayingSpotifySong;

  sidebarFixed:any;

  @HostListener('window:scroll', ['$event']) onScroll(event) {

//  console.log(window.scrollY);
    if (window.scrollY > 350) {
      this.sidebarFixed = true;
    }
    else if (window.scrollY < 350) {
      this.sidebarFixed = false;
    }

  }

  async spotifyInitialization() {
    
    // const token = "BQDa4OM2ETnfXrs_te2UPsh4FG-gE0wE_4OUSpPKO0JAWkWA3kDB_i5GcRXD_N1zXFlxu_kP64kD_WzStIrjETUTDsmRtR6Oh6SnM8G4kLbyr-t5KDbMIBC_mWb3KUqLiBnUbLSZF7ljNRwZeivyIAlqde6V9CsDy3Z5ClJ5uKObaNPlScemEElXJE0G9bQMCcgm2mTXDhJhyYVXfL2S_A54NUz8ECKc4m7gZDZmG7gLAbpFlui_Ixqeiw";
    // const token = "BQCavXJA-UdLHHtPDlSbZRJ45TIcp59Hir7wa86AeuyAGgArNGs-cQSxMZeOAR5fDjnH9n24vjnT0pC_jRvIvP0dJpEjlXMfHRHSmpAtmDC2HLBAnnakgEWrZZTC4qkLzcjw10aLvtT81vzdopYm4WbgpH06NYhksTeq6bj2E82JP0IBVrWC5kfSqQ3Cq-VHqTXPw7mghpV4j63RO_MHp7RPT-7xEuXVf_NBlJerdmkWf0eM6BbiyNwjww";
    // const token = "BQBssH7wcAx7c5diUlPcTjA6RiJNV103jaiaWgS1fSBh_UYu2Pg8rAlI_-1snXdLSj7DUSBFBdPXR8BLOxQRcH3E-aQx3W2AdX4m1AtCu8pAeo0OpzE2s_rNcgjDGhN4oSbScDHgBMS0fUbR_0P61RLVHIMhQZOMjSpeT3IdITK2WHNjZw6HkptDSZKqGSsX6kHSJbH_JzEoTm4ODNdIAI0tRT3n_jKrP_95o4UmR-GiEd6Pv_7ooUHIlQ";
    // const token = "BQAG-hn1hI8C6iN1Rz0YOGaff33zVyV5gDKeURTTqck9gysOKvjrDBH0UdSXDTlUyATm0Dq4vkqLEIqiHvS0OX17QQRG9m-Z8NGlP6MMqqPZHeh-WA4kAB28WmhoAd6diYyk9Hqu2LmaF-Y5Wa6Cf0f6vvTwb5gwXqxlR5g3RZHjaBDNfzsPxZpkD-naLNmDMGuoD27h8cKtTduNyEUwTnvS0Bmeo6ZuYy_RGenfnEyWcwq2REe3oO5ODA";
    //const token = "BQC2TWaAmcQZRKXXraIC2XwKVXwF_9LY44ITTgeKocRTvupOjZmhRDl4gzVVvatUS0bYbJ5Ni_mWkPqcFFjq1PeA9sFTPsEoYn8DgDgLE6rG9_DfSQAl14_dbZmfptYqMvv6IO84YA8sWrtvqXujURDhGpl28RSkxnP-kDAwWviuymOzQN9wCqENQg-jVmNDVPBm3q70WiL1S_Ibd3vaByeR3o0t2DHSM-5YxzxNjXMV8UA52UKNDU88MQ";
    // const token = "BQDQbKgEpL7LfpvPfnUAIiLWlKAQxC1RA5m-98RVQmlgGc6wdpA-JhYBEwgTnA4F4LNDFJwUblCdfMfgTIII6XyMYuURxVdKSD-9_kMSzkslTV5-yhg9bQpqRpF6YrG3_aDqF_j13-yMrgSaJAbbrFJiM5ij8NaTLcS97n8BQ4NgPYuEx1iGA_QyQ15ZDkabps2gTP0J1lxYIcUhTZVrCZMSd_m6xZ-QlwLuQ9jCJDhfUWmB3QZJwsd9Yg";
    // const token = "BQAwAt3z_WeJELJMoqPb8b5roH0DGLSViY1Ki_nv0IfGWI9WUE0lwIfNcVt79j7FCw8BaN0H_6zdoWlqmuDi_rI6Q8-YchKNtxuAqIH26aXlTz6owSGTAzmpMDJoWDJiqFHE5Sls9RHwFOPYUxeI_SIXXmHwBk1IfAn9c6NzxQuR0J0wOhEOUObYInGJ22uipMS5EEyaQgnWog19hnvL3E0lTMiFfiwgHzl4xdBJA39mR6U69KI6ZYJTpA";
    // const token = "BQDQbKgEpL7LfpvPfnUAIiLWlKAQxC1RA5m-98RVQmlgGc6wdpA-JhYBEwgTnA4F4LNDFJwUblCdfMfgTIII6XyMYuURxVdKSD-9_kMSzkslTV5-yhg9bQpqRpF6YrG3_aDqF_j13-yMrgSaJAbbrFJiM5ij8NaTLcS97n8BQ4NgPYuEx1iGA_QyQ15ZDkabps2gTP0J1lxYIcUhTZVrCZMSd_m6xZ-QlwLuQ9jCJDhfUWmB3QZJwsd9Yg";
    // const token = "BQBla4Zxrkw6Htc5yg7f6EcgEY988E3iKrenxxykF7pY02NQWlRr3B_xk1-5sBIPNf8ZSsPWC4SmeAto8Pc4QufYDF0LezoLygBsUGPPqiz5qSnn5I3wRRDyR5Z8D6YWRHyqMaJZVCip0T00EPT6qjO5Dbs3N9myNHwTf6VVnKLk9ACl_latNRFrwBj4U_cdEF10y1WoCibSZpa04VB3MgRouXOxbZ3c3Y3gpFat97axEeTW8-i2oLASXg";
    // this.service.getSpotifyToken().subscribe(async(res:any)=>{
    //   console.log(res);
    //   this.spotify = new SpotifyPlayer("test12");
    //   await this.spotify.connect(res?.data?.access_token);
    // })
    // const uri = "spotify:track:3GCwsB2loRGeUL8oq2Y4TW";
    
    // this.spotify = new SpotifyPlayer("test12");
    // await this.spotify.connect(token);

    const script = document.createElement('script')
    script.src = 'https://sdk.scdn.co/spotify-player.js'
    script.type = 'text/javascript'
    script.addEventListener('load', e => {
      console.log(e)
    });
    document.head.appendChild(script);

    (<any>window).onSpotifyWebPlaybackSDKReady = () => {
      // this.spotifyToken = 'BQAT3ywNQLFFwApAcZIwBdX3KOpi2THCpgcbQktLHa_zcmbYT_k_kckdxw-SjlA22fwrOygbALYZQNZHcI3wzE4PcOdy3tA4KmzKi22jiSxgNpXcefemHH-JYZsgjNacBCO4nYK6EXDvbg5iaJnQYoKc6Sfo7chjBCKBwgasmsKyd5s0UoeAuWKwqhmzOB9_UllWwoLfY58PCQVmHNa2mJkI9mee2U22xbm7wiJKXzutMSbngls0T3zwqQ';
      // this.spotifyToken = "BQAc4b9Lp4nD-D-WljcC8XoSJjEhteGPmT_V7vQxKGpO5Aqi2V5_5KeVEPkDpA2lcwC69gLET0WwqESlchV6crOcdO_N-kgQyx1pdL6eLryV5VTtvewM1gWa6fSetmgJOdFHjTJcATxdXg8YO6KrqJFYeP_cXn6uQGxEJV4nGSG4Ivd6IShEK3NvUwT73wLZm63DaDB_bgbWxoTLqTvOcJ5mNTW-p_O1hoQFnBgZzGyLlclmeG-cv3fMVg";
      // this.spotifyToken = "BQC9-Cnx8EmSGYlI-mb4wf5vEfKFFvY7nnoeu9xGDYJ76HVH_qzwp4qeEU2o-8egQXIF0eBfmXyfN_UAkAlomX_FUWnO1hfxu3KV6nAfjulBGyq4G0wVEVo5KQjSwC5Hv2Vud8zXS9KbHkw7RUkHBAsMkeZJhHF6veIRi16Wrx8jQWhLfng-FMdiMW1ROJ0Q09DB6JHF1BwtKh5J8Snk9aC4w6DU1vKorecgviPyDszjDG0CTX40W18aRg";
      // this.spotifyToken = this.service.getSpotifyToken().subscribe(
        // (res:any)=>{
        // let token = this.spotifyToken;


        // this.spotifyToken = res.data["access_token"];
        // this.spotifyToken = JSON.parse(localStorage.getItem('spotifyTokenInfo')).accessToken;
          //  this.spotifyToken = this.getSpotifyToken();
        // Get the token and assign here

        this.spotifyToken = this.spotifyTokenInfo.access_token;
        let token = this.spotifyToken;
        this.spotifyPlayerNew = new (<any>window).Spotify.Player({
          name: 'Web Playback SDK Quick Start Player',
          getOAuthToken: cb => { cb(token); },
          volume: 0.5
        }, (res:any)=> {
          // this.spinner.hide();
        });
  
        console.log(this.spotifyPlayerNew);
  
        this.spotifyPlayerNew.addListener('ready', ({ device_id }) => {
          console.log('Ready with Device ID', device_id);
          this.device_id = device_id;
        });
      
        // Not Ready
        this.spotifyPlayerNew.addListener('not_ready', ({ device_id }) => {
          console.log('Device ID has gone offline', device_id);
        });

        this.spotifyPlayerNew.addListener('player_state_changed', state => {
          // this.paused1 = state.paused;
          // this.state1 = state;
          // // this.spotifySongProgressBarValue = Math.trunc((state.position/state.duration)*100);
          // this.position1 = state.position;
          // this.duration1 = state.duration;
          this.currState["paused"] = state?.paused;
          this.currState["position"] = state?.position;
          this.currState['duration'] = state?.duration;
          this.currState['updateTime'] = performance.now();
        });
  
        this.spotifyPlayerNew.connect();
  
      // })
      
      // this.spotifyPlayerNew.addListener('player_state_changed', ({
      //   position,
      //   duration,
      //   track_window: { current_track }
      // }) => {
      //   this.spotifySongProgressBarValue = Math.trunc((position/duration)*100);
      //   this.position1 = position;
      //   this.duration1 = duration;
      // });

      
      // setInterval(()=>{
      //   // console.log(this.spotifySongProgressBarValue);
      //   // console.log(this.position1);
      //   // console.log(this.duration1);
      //   // console.log(this.paused1);
      //   // console.log(this.state1);
      //   // let aa = this.getStatePosition();
      //   // console.log(aa);
      //   this.spotifyPlayerNew.getCurrentState().then(state => {
      //     if (!state) {
      //       console.error('User is not playing music through the Web Playback SDK');
      //       return;
      //     }
      //     console.log(state);
        
      //     console.log('Currently Playing');
      //     console.log('Playing Next');
      //   });
      // });

      // setTimeout(()=>{
      //   this.spotifyPlayerNew.getCurrentState().then(state => {
      //     if (!state) {
      //       console.error('User is not playing music through the Web Playback SDK');
      //       return;
      //     }
        
      //     var current_track = state.track_window.current_track;
      //     var next_track = state.track_window.next_tracks[0];
        
      //     console.log('Currently Playing', current_track);
      //     console.log('Playing Next', next_track);
      //   });
      // },10000)
    }

  }

  getStatePosition() {
    if (this.currState?.paused) {
       return this.currState?.position;
    }
    console.log("position ",this.currState?.position);
    console.log("pnow ",performance.now());
    console.log("upddatetime ",this.currState?.updateTime);
    let position = this.currState?.position + (performance.now() - this.currState?.updateTime) / 1000;

    console.log("final pos",position);
    console.log("final duration",this.currState?.duration);
    return position > this.currState?.duration ? this.currState?.duration : position;
  }
  // currState:any;
  currState = {
    paused:false,
    position:0,
    duration:0,
    updateTime:0
  }
  position1;
  duration1;
  paused1;
  state1;
  abc(position,duration){
    console.log("123");
    this.spotifySongProgressBarValue = Math.trunc((position/duration)*100);
  }
  // playSelectedSong(songDetails,index:any){
  //   // var names = document.querySelectorAll('ang-music-player audio');
  //   // var image = document.querySelectorAll('ang-music-player img');
  //   // var title = document.querySelectorAll('ang-music-player p');
  
  //   console.log(songDetails);
  //   console.log(index);
    
  //   this.spotifySongSelected = true;
  //   this.spotifySongSelectedIndex = index;
  //   this.currentlyPlayingSpotifySong = this.audioList[index];
    
  //   this.play(this.currentlyPlayingSpotifySong.url);
  //   document.getElementById("spotifySongPlay").style.display = 'none';
  //   document.getElementById("spotifySongPause").style.display = 'block';
  //   // console.log(this.audioList[index].url);
  //   // this.spotify.play(this.audioList[index].url);
  //   console.log("playing spotify song");
  //   // console.log(this.playList);
  //   // for(var i=0; i<title.length; i++){
  //   //   (<HTMLInputElement>title[i]).innerHTML =  data.title;
  //   // }
  
  //   // for(var i=0; i<names.length; i++){
  //   //   (<HTMLInputElement>names[i]).src =  data.url;
  //   // }
  
  //   // for(var i=0; i<image.length; i++){
  //   //   (<HTMLInputElement>image[i]).src =  data.cover;
  //   // }
  //   // document.getElementsByTagName('audio')[0].autoplay=true;
   
  //     // this.spotifySingleData = data;
  //     // this.openModal(this.spotifyPlay)
  // }

  spotifySongTrackType;
  spotifySongPlaylistIndex;
  playSelectedSong(type,index,subTrackIndex:any){
    // var names = document.querySelectorAll('ang-music-player audio');
    // var image = document.querySelectorAll('ang-music-player img');
    // var title = document.querySelectorAll('ang-music-player p');
  
    console.log(type);
    console.log(index);
    console.log(subTrackIndex);
    
    this.spotifySongSelected = true;
    if(type == 'playlist'){
      this.spotifySongTrackType = 'playlist';
      this.spotifySongPlaylistIndex = index;
      this.spotifySongSelectedIndex = subTrackIndex;
      this.currentlyPlayingSpotifySong = this.spotifyList[index].items[subTrackIndex];
    }
    else{
      this.spotifySongTrackType = 'singleTrack';
      this.spotifySongSelectedIndex = index;
      this.currentlyPlayingSpotifySong = this.spotifyList[index].items[0];
      this.currentlyPlayingSpotifySong.imageUrl = this.spotifyList[index].imageUrl;
    }
    
    console.log(this.currentlyPlayingSpotifySong);
    this.play(this.currentlyPlayingSpotifySong.uri);
    setTimeout(()=>{
      document.getElementById("spotifySongPlay").style.display = 'none';
      document.getElementById("spotifySongPause").style.display = 'block';
      console.log("playing spotify song");
    },1000);
    
    
  }

  spotifyVolumeOn = true;
  spotifyVolumeToggle(){
    if(this.spotifyVolumeOn){
      this.spotifyPlayerNew.setVolume(0).then(() => {
        console.log('Muted');
        this.spotifyVolumeOn = false;
        this.spotifyVolume = 0;
      });
    }
    else{
      this.spotifyPlayerNew.setVolume(0.3).then(() => {
        console.log('UnMuted');
        this.spotifyVolumeOn = true;
        this.spotifyVolume = 30;
      });
    }
    

  }
  
  spotifyPlayPrevious(){
    // if(this.spotifyShuffleModeOn){
    //   this.spotifySongSelectedIndex = Math.floor(Math.random()*this.audioList.length)
    // }
    // else if(this.spotifySongSelectedIndex == 0){
    //   // if(this.spotifySongRepeatModeOn){
    //     this.spotifySongSelectedIndex = this.audioList.length;
    //   // }
    // }
    // this.spotifySongSelectedIndex-=1;


    if(this.spotifySongRepeatModeOn){
      this.spotifySongPlay();
      return;
    }
    if(this.spotifyShuffleModeOn){
      this.playShuffledSong();
      return;
    }
    if(this.spotifySongTrackType == 'playlist'){
      console.log(this.spotifySongSelectedIndex)
      if(this.spotifySongSelectedIndex == 0){
        this.spotifySongSelectedIndex = this.spotifyList[this.spotifySongPlaylistIndex].items.length-1;
      }
      else{
        this.spotifySongSelectedIndex--;
      }
      this.playSelectedSong('playlist',this.spotifySongPlaylistIndex,this.spotifySongSelectedIndex);
    }
    else{
      this.getPreviousSingleAndPlay();
    }


    /*********************************  Latest Code ************************************* */
    /** 
    if((this.spotifySongTrackType == 'playlsit' && this.spotifySongPlaylistIndex == 0 && this.spotifySongSelectedIndex == 0) || (this.spotifySongTrackType == 'singleTrack' && this.spotifySongSelectedIndex == 0)){
      // this.spotifySongSelectedIndex = -1;
      let subTrackIndex = this.spotifyList[this.spotifyList.length-1].trackType == 'playlist' ? this.spotifyList[this.spotifyList.length-1].items[this.spotifyList[this.spotifyList.length-1].items.length-1] : null;
      this.playSelectedSong(this.spotifyList[this.spotifyList.length-1].trackType,this.spotifyList.length-1,subTrackIndex);
      return;
    } 
    else if(this.spotifySongTrackType == 'playlist'){
      if(this.spotifySongSelectedIndex == 0){
        this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex-1].items[this.spotifySongSelectedIndex];
      }
      else{
        this.spotifySongSelectedIndex = this.spotifySongSelectedIndex - 1;
        this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex].items[this.spotifySongSelectedIndex];
      }
    }
    else{
      this.spotifySongSelectedIndex = this.spotifySongSelectedIndex - 1;
      this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongSelectedIndex].items[0];
    }
    // this.currentlyPlayingSpotifySong = this.audioList[this.spotifySongSelectedIndex];
    this.play(this.currentlyPlayingSpotifySong.uri);
    document.getElementById("spotifySongPlay").style.display = 'none';
    document.getElementById("spotifySongPause").style.display = 'block';
    this.spotifySongPaused = false;
    */
  }

  spotifySongPlay(){
    // this.spotify
    // debugger;
    // if(this.spotifySongSelected)
    //   this.spotify.play(this.audioList[this.spotifySongSelectedIndex].url);
    // else{
    //   this.spotifySongSelected = true;
    //   this.spotifySongSelectedIndex = 0;
    //   this.spotify.play(this.audioList[this.spotifySongSelectedIndex].url);
    // }
    // this.spotifyPlayerNew.play();
   
    // this.spotifyPlayerNew.getCurrentState().then(state => {
    //   console.log(state);
    //   if (!state) {
    //     console.log("playing new")
    //     this.play(this.currentlyPlayingSpotifySong.url);
    //     document.getElementById("spotifySongPlay").style.display = 'none';
    //     document.getElementById("spotifySongPause").style.display = 'block';
    //   }
    //   else{
    //     this.spotifyPlayerNew.resume().then(() => {
    //       console.log('Resumed!');
    //       document.getElementById("spotifySongPlay").style.display = 'none';
    //       document.getElementById("spotifySongPause").style.display = 'block';
    //     });
    //   }
    // });
    // if(document.getElementById("spotifySongPlay").style.display == 'block'){
    //   document.getElementById("spotifySongPlay").style.display = 'none';
    //   document.getElementById("spotifySongPause").style.display = 'block';
    //   this.play(this.currentlyPlayingSpotifySong.url);
    // }
    // else{
    //   document.getElementById("spotifySongPlay").style.display = 'block';
    //   document.getElementById("spotifySongPause").style.display = 'none';
    //   this.spotifyPlayerNew.resume();
    // }

    if(this.spotifySongPaused){
      this.spotifyPlayerNew.resume();
      this.spotifySongPaused = false;
      document.getElementById("spotifySongPlay").style.display = 'none';
      document.getElementById("spotifySongPause").style.display = 'block';
    }
    else{
      this.play(this.currentlyPlayingSpotifySong.uri);
      document.getElementById("spotifySongPlay").style.display = 'none';
      document.getElementById("spotifySongPause").style.display = 'block';
    }
    
    
  }

  spotifySongProgressBarValue = 0;
  spotifySongPaused = false;
  spotifySongRepeatModeOn = false;
  spotifyVolume = 50;
  songEnded = false;
  already = true;
  spotifySongPause(){
    this.spotifyPlayerNew.pause().then(() => {
      console.log('Paused!');
      this.spotifySongPaused = true;
      document.getElementById("spotifySongPlay").style.display = 'block';
      document.getElementById("spotifySongPause").style.display = 'none';
    }); 
  }
  volumeChange(e){
    console.log(e.target.value);
    this.spotifyPlayerNew.setVolume(e.target.value*0.01).then(() => {
      console.log('Volume updated!');
      if(e.target.value == 0){
        this.spotifyVolumeOn = false;
      }
      else if(!this.spotifyVolumeOn){
        this.spotifyVolumeOn = true;
      }
    });
  }
  spotifyPlayNext(){
    // if(this.spotifyShuffleModeOn){
    //   this.spotifySongSelectedIndex = Math.floor(Math.random()*this.audioList.length)
    // }
    // else if(this.spotifySongSelectedIndex == this.spotifyList.length-1){
    //   // if(this.spotifySongRepeatModeOn){
    //     this.spotifySongSelectedIndex = -1;
    //   // }
    // } 

    
    
    // this.currentlyPlayingSpotifySong = this.audioList[this.spotifySongSelectedIndex];
    // this.play(this.audioList[this.spotifySongSelectedIndex].url);
    
    /****  ***************************** Latest Commented ************************************ */


    // Check if this is last song
    // if((this.spotifySongTrackType == 'playlsit' && this.spotifySongPlaylistIndex == this.spotifyList.length-1 && this.spotifySongSelectedIndex == this.spotifyList[this.spotifySongPlaylistIndex].length-1) || (this.spotifySongTrackType == 'singleTrack' && this.spotifySongSelectedIndex == this.spotifyList.length-1)){
    //   // this.spotifySongSelectedIndex = -1;
    //   let subTrackIndex = this.spotifyList[0].trackType == 'playlist' ? 0 : null;
    //   this.playSelectedSong(this.spotifyList[0].trackType,0,subTrackIndex);
    //   return;
    // } 
    // else if(this.spotifySongTrackType == 'playlist'){
    //   if(this.spotifyList[this.spotifySongPlaylistIndex].items.length-1 == this.spotifySongSelectedIndex){
    //     this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex+1].items[this.spotifySongSelectedIndex];
    //   }
    //   else{
    //     this.spotifySongSelectedIndex = this.spotifySongSelectedIndex + 1;
    //     this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex].items[this.spotifySongSelectedIndex];
    //   }
    // }
    // else{
    //   this.spotifySongSelectedIndex = this.spotifySongSelectedIndex + 1;
    //   this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongSelectedIndex].items[0];
    // }
    // this.play(this.currentlyPlayingSpotifySong.uri);
    // document.getElementById("spotifySongPlay").style.display = 'none';
    // document.getElementById("spotifySongPause").style.display = 'block';
    // this.spotifySongPaused = false;
    
    //Check if it the last song
    // this.audioList.length

    /******************************************************************************************** */
    // if(this.spotifyShuffleModeOn){
    //   this.spotifySongSelectedIndex = Math.floor(Math.random()*this.spotifyList.length);

    //   if(this.spotifyList)

    // }
    // else
    // debugger;

    if(this.spotifySongRepeatModeOn){
      this.spotifySongPlay();
      return;
    }
    if(this.spotifyShuffleModeOn){
      this.playShuffledSong();
      return;
    }
    if(this.spotifySongTrackType == 'playlist'){
      console.log(this.spotifySongSelectedIndex)
      if(this.spotifySongSelectedIndex == this.spotifyList[this.spotifySongPlaylistIndex].items.length-1){
        this.spotifySongSelectedIndex = 0;
      }
      else{
        this.spotifySongSelectedIndex++;
      }
      this.playSelectedSong('playlist',this.spotifySongPlaylistIndex,this.spotifySongSelectedIndex);
    }
    else{
      this.getNextSingleAndPlay();
    }
  }
  spotifyProgressBarInterval;
  // play(url){
  //   // this.songEnded = false;
  //   setTimeout(()=>{
  //     this.already = true;
  //   },5000)
    
  //   $.ajax({
  //     url: "https://api.spotify.com/v1/me/player/play?device_id=" + this.device_id,
  //     type: "PUT",
  //     data: `{"uris": ["${url}"]}`,
  //     // data: `{"uris": ["}"]}`,
  //     beforeSend: (xhr)=>{xhr.setRequestHeader('Authorization', 'Bearer ' + this.spotifyToken );},
  //     success: (data) => { 
  //       console.log(data);
  //       this.startProgress();
  //       // this.spotifyProgressBarInterval = setInterval(()=>{
  //       //   // console.log(this.spotifySongProgressBarValue);
  //       //   // console.log(this.position1);
  //       //   // console.log(this.duration1);
  //       //   // console.log(this.paused1);
  //       //   // console.log(this.state1);
  //       //   // let aa = this.getStatePosition();
  //       //   // console.log(aa);
  //       //   this.spotifyPlayerNew.getCurrentState().then(state => {
  //       //     if (!state) {
  //       //       console.error('User is not playing music through the Web Playback SDK');
  //       //       return;
  //       //     }
  //       //     console.log(state);
  //       //     this.spotifySongProgressBarValue = (state.position/state.duration)*100;
  //       //     console.log(this.spotifySongProgressBarValue);

  //       //     if(state.paused && !Math.trunc(this.spotifySongProgressBarValue)){
  //       //       clearInterval(this.spotifyProgressBarInterval);
  //       //     }

  //       //   });
  //       // });
  //     }
  //   });
  // }
  play(url){
    // this.songEnded = false;
    setTimeout(()=>{
      this.already = true;
    },5000)
    
    $.ajax({
      url: "https://api.spotify.com/v1/me/player/play?device_id=" + this.device_id,
      type: "PUT",
      data: `{"uris": ["${url}"]}`,
      // data: `{"uris": ["}"]}`,
      beforeSend: (xhr)=>{xhr.setRequestHeader('Authorization', 'Bearer ' + this.spotifyToken );},
      success: (data) => { 
        console.log(data);
        this.startProgress();
      },
      error:(arg1,arg2,arg3) => {
        console.log("Error occured while playing the song");
        console.log(arg1);
        console.log(arg2);
        console.log(arg3);
      }
    });
  }
  spotifySongPlayedTill = '0:00';
  spotifyShuffleModeOn = false;
  originalSpotifyList = [];
  spotifySongShuffleToggle(){
    this.spotifyShuffleModeOn = !this.spotifyShuffleModeOn;
    // if(this.spotifyShuffleModeOn){
    //   this.spotifyList = this.shuffle(this.spotifyList);
    // }
    // else{
    //   this.spotifyList = this.originalSpotifyList;
    // }
  }

  shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }


  spotifySongState;
  startProgress(){
    console.log("started");
    this.spotifySongProgressBarValue = 0;
    this.spotifyProgressBarInterval = setInterval(()=>{
          // console.log(this.spotifySongProgressBarValue);
          // console.log(this.position1);
          // console.log(this.duration1);
          // console.log(this.paused1);
          // console.log(this.state1);
          // let aa = this.getStatePosition();
          // console.log(aa);
          this.spotifyPlayerNew.getCurrentState().then(state => {
            if (!state) {
              console.error('User is not playing music through the Web Playback SDK');
              return;
            }
            this.spotifySongState = state;
            // console.log(state);
            this.spotifySongProgressBarValue = (state.position/state.duration)*100;
            this.spotifySongPlayedTill = this.getTimeInMinuteAndSeconds(state.position);
            // console.log(this.spotifySongProgressBarValue);

if(this.already){
  if(state.paused && !this.spotifySongProgressBarValue){
    clearInterval(this.spotifyProgressBarInterval);
    console.log("end");
    // if(this.already){
      this.checkNextAndPlay();
      this.already = false;
    // }
    // if(!this.songEnded){
      // let timer = 0;
      // if(this.spotifySongSelectedIndex == this.audioList.length-1){
      //   timer = 4000;
      // }
      // else{
      //   timer = 1000;
      // }
      // setTimeout(()=>{
      //   this.checkNextAndPlay();
      // },timer);
      
      // this.songEnded = true;
    // }
    
  }
}
            

          });
        },1000);

  }
 
  checkNextAndPlay(){
    if(this.spotifySongRepeatModeOn){
      this.spotifySongPlay();
    }
    else{
      console.log("1");
      console.log("2");
      console.log("3");
      console.log("4");
      if(this.spotifyShuffleModeOn){
        this.playShuffledSong();
        // this.spotifySongSelectedIndex = Math.floor(Math.random()*this.audioList.length)
      }
      else if(this.spotifySongTrackType == 'playlist'){
        console.log(this.spotifySongSelectedIndex)
        if(this.spotifySongSelectedIndex == this.spotifyList[this.spotifySongPlaylistIndex].items.length-1){
          this.spotifySongSelectedIndex = 0;
        }
        else{
          this.spotifySongSelectedIndex++;
        }
        this.playSelectedSong('playlist',this.spotifySongPlaylistIndex,this.spotifySongSelectedIndex);
      }
      else{
        this.getNextSingleAndPlay();
      }

      // else if((this.spotifySongTrackType == 'playlsit' && this.spotifySongPlaylistIndex == this.spotifyList.length-1 && this.spotifySongSelectedIndex == this.spotifyList[this.spotifySongPlaylistIndex].length-1) || (this.spotifySongTrackType == 'singleTrack' && this.spotifySongSelectedIndex == this.spotifyList.length-1)){
      //   // this.spotifySongSelectedIndex = -1;
      //   let subTrackIndex = this.spotifyList[0].trackType == 'playlist' ? 0 : null;
      //   this.playSelectedSong(this.spotifyList[0].trackType,0,subTrackIndex);
      // } 
      // else{
      //   this.spotifyPlayNext();
      // }
      
    }
    
  }
  getNextSingleAndPlay(){
    for(let i=this.spotifySongSelectedIndex;i<this.spotifyList.length;){
      if(i == this.spotifyList.length-1)
        i = 0;
      else
        i++;
      if(this.spotifyList[i].contentType == 'track'){
        this.spotifySongSelectedIndex = i;
        break;
      }
    }
    this.playSelectedSong('singleTrack',this.spotifySongSelectedIndex,'');
  }
  getPreviousSingleAndPlay(){
    for(let i=this.spotifySongSelectedIndex;i<this.spotifyList.length;){
      if(i == 0)
        i = this.spotifyList.length-1;
      else
        i--;
      if(this.spotifyList[i].contentType == 'track'){
        this.spotifySongSelectedIndex = i;
        break;
      }
    }
    this.playSelectedSong('singleTrack',this.spotifySongSelectedIndex,'');
  }
  playShuffledSong(){


    

    if(this.spotifySongTrackType == 'playlist'){
      let index = Math.floor(Math.random()*this.spotifyList[this.spotifySongPlaylistIndex].items.length);
      this.spotifySongTrackType = 'playlist';
      // this.spotifySongPlaylistIndex = index;
      this.spotifySongSelectedIndex = index;
      this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex].items[index];
    }
    else{

      let flag = true;
      while(flag){
        let index = Math.floor(Math.random()*this.spotifyList.length);
        if(this.spotifyList[index].contentType == 'track'){
          this.spotifySongTrackType = 'singleTrack';
          this.spotifySongSelectedIndex = index;
          this.currentlyPlayingSpotifySong = this.spotifyList[index].items[0];
          this.currentlyPlayingSpotifySong.imageUrl = this.spotifyList[index].imageUrl;
          flag = false;
          break;
        }
      }
      

    }

    this.play(this.currentlyPlayingSpotifySong.uri);
    document.getElementById("spotifySongPlay").style.display = 'none';
    document.getElementById("spotifySongPause").style.display = 'block';
    this.spotifySongPaused = false;

    // if(this.spotifySongTrackType == 'playlist'){
    //   if(this.spotifyList[this.spotifySongPlaylistIndex].items.length-1 == this.spotifySongSelectedIndex){
    //     //If this is the last song, then get random and play
    //     // this.spotifySongSelectedIndex = Math.floor(Math.random()*this.spotifyList.length)
    //     if(this.spotifyList[index].trackType == 'playlist'){
    //       this.spotifySongTrackType = 'playlist';
    //       this.spotifySongPlaylistIndex = index;
    //       this.spotifySongSelectedIndex = 0;
    //       this.currentlyPlayingSpotifySong = this.spotifyList[index].items[0];
    //     // }
    //     // else{
          
    //     }
    //     else{
    //       this.spotifySongTrackType = 'singleTrack';
    //       this.spotifySongSelectedIndex = index;
    //       this.currentlyPlayingSpotifySong = this.spotifyList[index].items[0];
    //     }
    //     // this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex+1].items[this.spotifySongSelectedIndex];
    //   }
    //   else{
    //     // this.spotifySongSelectedIndex = this.spotifySongSelectedIndex + 1;
    //     // this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex].items[this.spotifySongSelectedIndex];
    //     this.spotifyPlayNext();
    //   }
    // }
    // else{
    //   this.spotifySongTrackType = 'singleTrack';
    //   this.spotifySongSelectedIndex = index;
    //   this.currentlyPlayingSpotifySong = this.spotifyList[index].items[0];
    // }
    // this.play(this.currentlyPlayingSpotifySong.uri);
    // document.getElementById("spotifySongPlay").style.display = 'none';
    // document.getElementById("spotifySongPause").style.display = 'block';
    // this.spotifySongPaused = false;


    // if(this.spotifySongTrackType == 'playlist'){
    //   if(this.spotifyList[this.spotifySongPlaylistIndex].items.length-1 == this.spotifySongSelectedIndex){
    //     this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex+1].items[this.spotifySongSelectedIndex];
    //   }
    //   else{
    //     this.spotifySongSelectedIndex = this.spotifySongSelectedIndex + 1;
    //     this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongPlaylistIndex].items[this.spotifySongSelectedIndex];
    //   }
    // }
    // else{
    //   this.spotifySongSelectedIndex = this.spotifySongSelectedIndex + 1;
    //   this.currentlyPlayingSpotifySong = this.spotifyList[this.spotifySongSelectedIndex].items[0];
    // }
    // this.play(this.currentlyPlayingSpotifySong.uri);
    // document.getElementById("spotifySongPlay").style.display = 'none';
    // document.getElementById("spotifySongPause").style.display = 'block';
    // this.spotifySongPaused = false;
    
    
  }
  spotifySongRepeatToggle(){
    this.spotifySongRepeatModeOn = !this.spotifySongRepeatModeOn;
  }
  changeSongPosition(e){
    console.log(e.target.value);
    console.log(this.spotifySongProgressBarValue);
    console.log(this.spotifyPlayerNew);
    // this.spotifyPlayerNew.getCurrentState().then(state => {
    //   if (!state) {
    //     console.error('User is not playing music through the Web Playback SDK');
    //     return;
    //   }
      if(this.spotifySongState){
        this.spotifyPlayerNew.seek((e.target.value/100)*this.spotifySongState.duration).then(() => {
          console.log('Changed position!');
        });
      }
      else {
            console.error('User is not playing music through the Web Playback SDK');
            return;
          }
      
    // });
  }

  getTimeInMinuteAndSeconds = function(timeInMilli){
    let seconds = timeInMilli/1000;
    if(Math.trunc(seconds%60).toString().length == 1){
      return Math.trunc(seconds/60) + ":0" + Math.trunc(seconds%60);
    }
    else{
      return Math.trunc(seconds/60) + ":" + Math.trunc(seconds%60);
    }
    
  }
  
  async checkSpotifyAuthorized(){
    // let spotifyTokenInfo = localStorage.getItem("spotifyTokenInfo");
    // if(spotifyTokenInfo){
    //   console.log(spotifyTokenInfo);
    //   let spotifyTokenInfoParsed = JSON.parse(spotifyTokenInfo);
    //   console.log(spotifyTokenInfoParsed);
    //   if(!spotifyTokenInfoParsed.accessToken){
    //     this.spotifyAuth();
    //   }
    //   else{
    //     // Check token validity
    //     console.log("check token validity");
    //   }
    // }
    // else{
    //   this.spotifyAuth();
    // }
    let spotifyTokenInfoParsed:any = await this.getSpotifyToken();
    // debugger;
    // spotifyTokenInfoParsed
    console.log(spotifyTokenInfoParsed);
    if(spotifyTokenInfoParsed){
      console.log(spotifyTokenInfoParsed);
      // let spotifyTokenInfoParsed = JSON.parse(spotifyTokenInfo);
      console.log(spotifyTokenInfoParsed);

      // Check token expired
      // new Date().getTime() - spotifyTokenInfoParsed.expiresAtDate) >= (30*60*1000
      if(new Date().getTime() >= (spotifyTokenInfoParsed.expiresAtDate - (30*60*1000))){
        let refreshTokenResponse =  await this.refreshSpotifyToken(spotifyTokenInfoParsed.refresh_token);
        refreshTokenResponse['expiresAtDate'] = (refreshTokenResponse.expires_in*1000) + new Date().getTime();
        this.updateSpotifyToken(refreshTokenResponse);
        this.spotifyTokenInfo = refreshTokenResponse;
        this.spotifyInitialization();
      }
      else{
        this.spotifyTokenInfo = spotifyTokenInfoParsed;
        this.spotifyInitialization();
      }
      
    }
    else{
      this.spotifyAuth();
    }
  }

  spotifyTokenInfo:any;

  updateSpotifyToken(data){
    this.service.saveOrUpdateSpotifyToken(data).subscribe((res:any)=>{
      console.log(data);
    })
  }

  async refreshSpotifyToken(refresh_token){

    let body = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
      client_id: 'b9568077e42a48ba89e30e3c7aa48b33',
    });
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      body:body,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    });

    const data = await response.json();

    console.log(data);

    if(data.error){
      this.spotifyAuth();
    }
    else{
      return data;
    }

    // this.service.saveOrUpdateSpotifyToken(data).subscribe((res:any)=>{
    //   console.log(data);
    // })
  }

  async getSpotifyToken(){
    return new Promise((resolve,reject)=>{
      this.service.getSpotifyAuthToken().subscribe((res:any)=>{
        console.log(res);
        if(res.data && res.data.access_token){
          resolve(res.data);
        }
        else{
          this.spotifyAuth();
        }
      },
      err => {
        console.log(err);
        this.toastrService.info("There is some issue with spotify.Please try after sometime");
        resolve('');
      })
    })
    // return 
    // let tokenInfo = localStorage.getItem('spotifyTokenInfo');
    // return tokenInfo;
  }

  generateRandomString(length) {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  async generateCodeChallenge(codeVerifier) {
    function base64encode(string) {
      return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    }
  
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
  
    return base64encode(digest);
  }

  spotifyAuth(){
    console.log("from spotify-auth");
    const clientId = 'b9568077e42a48ba89e30e3c7aa48b33';

    // const redirectUri = 'http://localhost:4200/spotify-auth-redirect';

    const redirectUri = 'https://indietown-web.approd.ca/spotify-auth-redirect';

    let codeVerifier = this.generateRandomString(128);

    console.log("codeVerifier",codeVerifier);

    this.generateCodeChallenge(codeVerifier).then(codeChallenge => {
      let state = this.generateRandomString(16);
      console.log("state",state);

      let scope = 'streaming user-read-email user-modify-playback-state user-read-private';

      localStorage.setItem('code_verifier', codeVerifier);

      let args = new URLSearchParams({
        response_type: 'code',
        client_id: clientId,
        scope: scope,
        redirect_uri: redirectUri,
        state: state,
        code_challenge_method: 'S256',
        code_challenge: codeChallenge
      });

      // Location.g
      // Location.go('https://accounts.spotify.com/authorize?' + args);
      // Location.
      this.document.location.href = 'https://accounts.spotify.com/authorize?' + args;
    });
  }
  showAddUrlLoader:Boolean = false;
  addSpotifyUrl(){
    this.showAddUrlLoader = true;
    console.log((<HTMLInputElement>document.getElementById("spotifyUrlNew")).value);
    let spotifyUrl = (<HTMLInputElement>document.getElementById("spotifyUrlNew")).value;
    this.service.addSpotifyUrl({url:spotifyUrl}).subscribe((res:any)=>{
      this.showAddUrlLoader = false;
      console.log(res);
      this.spotifyList.unshift(res.data);
      (<HTMLInputElement>document.getElementById("spotifyUrlNew")).value = '';
      this.myInputValuedyn = '';
    },(err)=>{
      this.showAddUrlLoader = false;
      console.log(err);
      this.toastrService.info(err?.error?.message);
      (<HTMLInputElement>document.getElementById("spotifyUrlNew")).value = '';
      this.myInputValuedyn = '';
    })
  }

  spotifyList = [];
  

  // removeSingleTrack(index){
  //   let id = this.spotifyList[index]._id;
  //   this.service.removeSingleTrack(id).subscribe((res:any)=>{
  //     console.log(res);
  //     if(res.type == 'success'){
  //       this.spotifyList.splice(index,1);
  //     }
  //   })
  // }

  // removePlaylist(index){
  //   let id = this.spotifyList[index]._id;
  //   this.service.removePlaylist(id).subscribe((res:any)=>{
  //     console.log(res);
  //     if(res.type == 'success'){
  //       this.spotifyList.splice(index,1);
  //     }
  //   })
  // }

  // removePlaylistItem(index, subtrackIndex){
  //   let id = this.spotifyList[index].items[subtrackIndex]._id;
  //   this.service.removePlaylistItem(id).subscribe((res:any)=>{
  //     console.log(res);
  //     if(res.type == 'success'){
  //       this.spotifyList[index].items.splice(subtrackIndex,1);
  //       if(!this.spotifyList[index].items.length){
  //         this.spotifyList.splice(index,1);
  //       }
  //     }
  //   })
  // }

  playlistOpened(index){
    // expanded = !expanded;
    console.log("playlist opened",index);
    this.spotifyList[index].expanded = true;
    // this.selectedSpotifyPlaylistIndex = index;
    // 22931509_SID@ftts.BSNL.IR
    let playlistId = this.spotifyList[index]._id;
    this.service.getPlaylistItems(playlistId).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.spotifyList[index].items = res.data;
      }
      this.spotifyList[index].expanded = false;
    },err => {
      this.toastrService.info("Please try after sometime!")
      this.spotifyList[index].expanded = false;
    })
  }

  getMoreSpotifyTracks:Boolean = true;
  spotifyPageNumber = 1;
  spotifyPageLimit = 20;
  loadMoreSpotifyTracks(e){
    console.log("load more spotify tracks");
    if(this.getMoreSpotifyTracks){
      this.spotifyPageNumber++;
      this.service.getSpotifyList(this.userId,this.spotifyPageNumber,this.spotifyPageLimit).subscribe((res:any)=>{
        console.log(res);
        if(res.data.length){
          for(let i=0;i<res.data.length;i++){
            if(res.data[i].contentType == 'playlist' || res.data[i].contentType == 'album'){
              res.data[i].expanded = false;
            }
          }
          this.spotifyList.push(...res.data);
        }
        else{
          this.getMoreSpotifyTracks = false;
        }
      })
    }
  }

  removeSpotifyId;
  removeSpotifyIndex;
  removeSpotifySubtrackIndex;
  removeModalref;
  removingItem = '';

  // openRemoveYoutubeUrlModal(id, index){
  //   this.removeYoutubeId = id;
  //   this.removeYoutubeIndex = index;
  //   this.removeModalref = this.modalService.show(this.removeYoutubeUrlModal);
  // }

  openRemoveSpotifyModal(type,index, subtrackIndex){
    if(type == 'single'){
      this.removingItem = 'track';
    }
    else if(type == 'playlist'){
      this.removingItem = 'playlist';
    }
    else if(type == 'playlistItem'){
      this.removingItem = 'track from playlist';
    }
    this.removeSpotifySubtrackIndex = subtrackIndex;
    this.removeSpotifyIndex = index;
    this.removeModalref = this.modalService.show(this.removeSpotifyModal,{class:'removespotifypopupp'});
  }

  showRemoveUrlLoader: Boolean = false;
  removeSpotify(){
    this.showRemoveUrlLoader = true;
    switch(this.removingItem){
      case 'track' : this.removeSpotifyUrl(); break;
      case 'playlist' : this.removePlaylist(); break;
      case 'track from playlist' : this.removePlaylistItem(); break;
    }
  }

  removeSpotifyUrl(){
    this.service.removeSpotifyUrl(this.spotifyList[this.removeSpotifyIndex]._id).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.spotifyList.splice(this.removeSpotifyIndex,1);
        this.checkSpotifyListAndHidePlayer();
      }
      this.showRemoveUrlLoader = false;
      this.removeModalref.hide();
    },err => {
      this.showRemoveUrlLoader = false;
      this.removeModalref.hide();
      this.toastrService.info("please try after sometime");
    })
  }

  removePlaylist(){
    let id = this.spotifyList[this.removeSpotifyIndex]._id;
    this.service.removeSpotifyPlaylist(id).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.spotifyList.splice(this.removeSpotifyIndex,1);
        this.checkSpotifyListAndHidePlayer();
      }
      this.showRemoveUrlLoader = false;
      this.removeModalref.hide();
    },err => {
      this.showRemoveUrlLoader = false;
      this.removeModalref.hide();
      this.toastrService.info("please try after sometime");
    })
  }

  removePlaylistItem(){
    let id = this.spotifyList[this.removeSpotifyIndex].items[this.removeSpotifySubtrackIndex]._id;
    this.service.removeSpotifyPlaylistItem(id).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.spotifyList[this.removeSpotifyIndex].items.splice(this.removeSpotifySubtrackIndex,1);
        if(!this.spotifyList[this.removeSpotifyIndex].items.length){
          this.spotifyList.splice(this.spotifyList[this.removeSpotifyIndex],1);
          // this.SpotifyPlayListModalRef?.hide();
        }
        this.checkSpotifyListAndHidePlayer();
      }
      this.showRemoveUrlLoader = false;
      this.removeModalref.hide();
    },err => {
      this.showRemoveUrlLoader = false;
      this.removeModalref.hide();
      this.toastrService.info("please try after sometime");
    })
  }

  checkSpotifyListAndHidePlayer(){
    if(!this.spotifyList.length){
      this.spotifyPlayerNew.pause();
      this.spotifySongSelected = false;
    }
  }

  @Input('addSpotify')
  set addSpotify(data: any) {
      //do whatever you want with your data here, this data will be passed from parent component
      console.log("spotify to add",data);
      if(data && Object.keys(data).length){
        this.spotifyList.unshift(data);
      }
  }

  selectedSpotifySong;
  navigateToSelectedSong(type,index,subTrackIndex:any){
    // var names = document.querySelectorAll('ang-music-player audio');
    // var image = document.querySelectorAll('ang-music-player img');
    // var title = document.querySelectorAll('ang-music-player p');
  
    // SPOTIFY NEW UI
    // this.route.navigateByUrl("/spotify-view");

    console.log(type);
    console.log(index);
    console.log(subTrackIndex);
    
    if(type == 'playlist'){
      this.selectedSpotifySong = this.spotifyList[index].items[subTrackIndex];
    }
    else{
      this.selectedSpotifySong = this.spotifyList[index].items[0];;
    }

    console.log(this.selectedSpotifySong);
    console.log(this.selectedSpotifySong?.uri?.split(":")[2]);

    let trackPath = this.selectedSpotifySong?.uri?.split(":")[2];
    
    window.open("https://open.spotify.com/track/"+trackPath,'_blank');
  }

}
