<div id="auth-width" class="signupscreen">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
            <div class="login-banner"  *ngIf="!isMobile">
                <!-- <img src="../../../../assets/Indietown_icons/login-banner.svg" alt="login-banner" class="img-fluid"> -->
                <div style="width: 100%;">
                  <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator" [interval]="2200" [isAnimated]="true">
                     <slide *ngFor="let slide of slides; let index=index">
                        <img [src]="slide.image" [id]="slide.id" alt="image slide" style="display: block; width: 100%;">
                        <div class="carousel-caption">
                            <h4 class="carouseltitle">Your Indie Music<br> Community</h4>
                            <!-- <p class="carouselcap">Connect, Share, and Build Community with <br>Independent Artists Everywhere. </p> -->
                            <p class="carouselcap">Connect and Share with <br>Independent Artists Everywhere. </p>
                        </div>
                     </slide>
                  </carousel>
                </div>
              </div>
        </div>
        <div class="col-md-6">
          <div class="login-field">
            <div class="login-box">
                <div style="margin-top:35px;display: flex;justify-content:center ;">
                    <img src="../../../../assets/Indietown_icons/login-logo.svg" style="    height: 78.65px;" alt="">
                  </div>
              <h3 style="text-align: center;" class="signuptxt">Sign Up</h3>

              <form [formGroup]="signUpForm">
                <div class="change-pass">
                    <div class="form-group">
                        <input style="    margin-bottom: 0.34375em;" tabindex="1" type="email" name="" id="" class="form-control emailaddress" formControlName="email" placeholder="Email Address" 
                        [ngClass]="{'dynamic':signUpForm.value.email != '' && signUpForm.value.email !=  undefined ,'redbordercolor':(p.email?.errors?.required && (p.email?.dirty || p.email?.touched)) || (emailFocusOut && p.email?.errors?.pattern && (p.email?.dirty || p.email?.touched))}"
                        (keyup)="removeError($event)" (blur)="emailFocusOut = true" (focus)="emailFocusOut = false" (keypress)="CheckEnterPress($event)">
                        <!-- <div class="alert alert-danger" *ngIf="changed && p.email.errors">
                            <span *ngIf="p.email.errors.pattern">
                                enter a valid Email Address
                            </span>
                            <span *ngIf="p.email.errors.required">
                                *Email required
                            </span>
                        </div> -->
                        <div class="alert alert-danger" *ngIf="p.email?.errors?.required && (p.email?.dirty || p.email?.touched)">
                            <span >
                                *Email required
                            </span>
                        </div>
                        <div class="alert alert-danger" *ngIf="emailFocusOut && p.email?.errors?.pattern && (p.email?.dirty || p.email?.touched)">
                            <span>
                                *Enter a valid Email Address
                            </span>
                        </div>
                        
                    </div>

                    <div class="form-group" style="margin-top: 0%; margin-bottom: 14px;">
                        <!-- <label for="">New Password</label> -->
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- maxlength="10" -->
                            <input matInput [type]="hide1 ? 'password' : 'text'" value="" class="signuppassward"
                                formControlName="password" placeholder="Password" (keypress)="CheckEnterPress($event)"  (keyup)="removeError($event)"
                                [ngClass]="{'dynamic':signUpForm.value.password != '' && signUpForm.value.password != undefined ,'redbordercolor': errorMessage}" tabindex="2">
                            <!-- <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                formControlName="password" placeholder="Password" maxlength="10"> -->
                            
                            <button mat-icon-button matSuffix (click)="hide1 = !hide1" 
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                <mat-icon style="top: -24px;">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>


                            <div class="alert alert-danger" *ngIf="changed && p.password.errors">
                                <span *ngIf="p.password.errors.required">
                                    *New password is required.
                                </span>
                                <span *ngIf="p.password.errors.minlength">
                                    *Password must have at least 6 characters.
                                </span>
                                <!-- <div class="alert alert-danger">
                                    {{errorMessage}}
                                </div> -->
                            </div>
                           
                        </mat-form-field>
                        <div class="alert alert-danger">
                            {{errorMessage}}
                        </div>
                    </div>

                    <div class="form-group" style="margin-top: 0; margin-bottom: 15px;">
                        <!-- <label for="">Confirm Password</label> -->
                        <!-- maxlength="10" -->
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput tabindex="3" [type]="hide2 ? 'password' : 'text'" value="" class=""
                                formControlName="confirmPassword" (keyup.enter)="SignUp()" (keypress)="CheckEnterPress($event)" placeholder="Retype Password" (keyup)="removeError($event)"
                                [ngClass]="{'dynamic':signUpForm.value.confirmPassword != '' && signUpForm.value.confirmPassword != undefined , 'redbordercolor':match==false}"
                                >
                            <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                <mat-icon  style="top: -24px;">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <div class="alert alert-danger" *ngIf="changed && p.confirmPassword.errors">
                                <span *ngIf="p.confirmPassword.errors.required">
                                    *Confirm password is required.
                                </span>
                                <span *ngIf="p.confirmPassword.errors.minlength">
                                    *Password must have at least 6 characters.
                                </span>
                            </div>
                           
                            <!-- <div class="alert alert-danger" style="position: unset;">
                                {{errorMessage}}
                            </div> -->
                        </mat-form-field>
                        <div *ngIf="match==false" class="alert alert-danger">
                            *Password doesn't match.
                        </div>
                    </div>

                    <!-- <div  class="alert alert-danger">{{errorMessage}}</div> -->
                </div>
            
  
              <div style="text-align: left;margin-top: 0px;width: 94%;
              margin-left: auto;     z-index: 99;
    position: relative;" class="checbox">
                <div>


                    <div class="container">
                        <div class="round">
                          <input type="checkbox" class="form-check-input" formControlName="term" id="checkbox" />
                          <label for="checkbox" style="border: solid 1px #ced4da;"></label>
                        </div>
                    </div>


                     <!-- <span><input type="checkbox" class="form-check-input" formControlName="term"></span> -->
                      <span style="font-size: 12px;">
                        By continuing, you agree to Indie Town's   <a href="javascript:void()" (click)="termCondition()" style="color: #000000;font-weight: bold;">Terms and Conditions</a> &
                    <a href="javascript:void()" (click)="onclickPrivacy()" style="color: #000000;font-weight: bold;">Privacy Policy</a>
                      </span>
                </div>
              </div>
            

            <div class="btn-signup">
                <button (click)="SignUp()" class="btn btn-primary" style="background-color: #000000;border: solid 1px #000000;" [disabled]="signUpForm.invalid || signUpForm.value.term==false">Sign Up</button>
            </div>
            <div style="text-align: center; margin-top: 20px;">
                <p class="accounttxt">Already have an account? <a href="javascript:void()" routerLink="/login" style="color: #000000;font-weight: bold;">Log in</a></p>
                <p>
                    Need an invite? Request one at <br><a href="mailto:invites@indietown.org" class="sent-email">invites@indietown.org</a>
                </p>
            </div>

            <div style="display: flex;
            justify-content: space-evenly;">
                <a target="_blank" href="https://apps.apple.com/us/app/indie-town/id6445808133"><img class="mobile-view" src="assets/Indietown_icons/surface1.png" alt=""></a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.incubator.indietown&pli=1"><img class="mobile-view" src="assets/Indietown_icons/Group 1171276041.png" alt=""></a>
            </div>
        </form>
    </div>
        
      </div>
    </div>
  </div>