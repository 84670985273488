import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../services/shared.service';
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { CarouselComponent, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { CarouselService } from 'ngx-owl-carousel-o/lib/services/carousel.service';
import { PhotoAlbumSliderModalContentComponent } from './photo-album-slider.component';
// import * as $ from 'jquery';
declare var $: any;
@Component({
  selector: 'app-photo-album',
  templateUrl: './photo-album.component.html',
  styleUrls: ['./photo-album.component.scss']
})
export class PhotoAlbumComponent implements OnInit {

  uploadPhotoAlbumUrl:string;
  deleteAlbumImage:any;
  modalRef: BsModalRef;
  photoAlbumCheckbox:any;
  @Input() userId: string;
  @Input() userAvailable: boolean;
  @Input() deleteButton: boolean;

  owlRef:any;

  constructor(
    private service: SharedService,
    private toaster: ToastrService,
    private modalService: BsModalService,
    // private albumModalComponent: PhotoAlbumSliderModalContentComponent
  ) { 
    // this.albumModalComponent.deletePhotoFromAlbum.subscribe({
    //   next: (event: MyServiceEvent) => {
    //       console.log(`Received message #${event.eventId}: ${event.message}`);
    //   }
  }

  @ViewChild("deleteAlbumImages") deleteAlbumImages: TemplateRef<any>;  
  @ViewChild("photoAlbumSlider") photoAlbumSlider: TemplateRef<any>;
  // @ViewChild('owlCar', { static: true }) owlCar: CarouselComponent;
  

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    // items: 1,
    responsive: {
      0: {
        items: 3
      },
      // 200: {
      //   items: 3
      // },
      // 300: {
      //   items: 3
      // }
    },
    nav: true
  }
  number = 500;
  customOptions2: OwlOptions = {
    center: true,
    items:3,
    // loop:true,
    margin:15,
    // autoplay: true,
    // autoPlay: 3000
    loop: false,
    rewind: false,
    autoWidth:true,
    // loop: true,
    // center: true,
    // rewind:true,
    // autoWidth:true,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    dots: false,
    // navSpeed: 700,
    navText: ['<img class="leftnav" width="40" height="40" src="../../../../../assets/Indietown_icons/circle-chevron-right-solid.svg">', '<img class="rightnav" width="40" height="40" src="../../../../../assets/Indietown_icons/circle-chevron-right-solid.svg">'],
    // items: 3,
    // margin:10,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      // 740: {
      //   items: 3
      // },
      // 940: {
      //   items: 3
      // }
    },
    nav: true
  }

  slidesStore :any= [];
 noWrapSlides = false;
 showIndicator = true;
 centeralign:boolean = true;
 deleteImagePath:any;
  

  ngOnInit(): void {
        console.log("user Id",this.userId);
        this.uploadPhotoAlbumUrl='../../../assets/Indietown_icons/upload-photo-album.svg';
        setTimeout(()=>{
          this.getAlbum(this.userId)
        },3000)
  }


  uploadPhotoAlbum(e:any){
    let files = e.target.files;
    [...e.target.files].map((file)=>{
      let fd = new FormData();
      fd.append('file', file);
      this.service.uploadImage(fd).subscribe((res:any)=> {
        if(res.type == 'success'){
          this.uploadPhotoAlbumUrl = res.data.fileUrl;
          let data = {
            "album":[
              {
                "fileUrl": this.uploadPhotoAlbumUrl,
                "key": res.data.key
              }
            ]
        }
        this.createPhotoAlbum(data);
        }
      })
    })
  }
  
  uploadToServer(file){
    // let file = e.target.files[0];
    let fd = new FormData();
    fd.append('file', file);
    this.service.uploadImage(fd).subscribe((res:any)=> {
      if(res.type == 'success'){
        this.uploadPhotoAlbumUrl = res.data.fileUrl;
        let data = {
          "album":[
            {
              "fileUrl": this.uploadPhotoAlbumUrl,
              "key": res.data.key
            }
          ]
      }
      this.createPhotoAlbum(data);
      }
    })
  }

   createPhotoAlbum(data){
    this.service.createAlbum(data).subscribe((res:any)=>{
      if(res.type == 'success'){
        // this.getAlbum(this.userId);
        // this.slidesStore.push(data?.album[0]);
        this.slidesStore.push(res.data[res.data.length-1]);
        this.toaster.success(res.message);
        this.uploadPhotoAlbumUrl='../../../assets/Indietown_icons/upload-photo-album.svg';
      }else{
        this.toaster.error(res.message);
      }
    })
   }

   deletePhotoAlbumIndex:any;
   deleteAlbumModalRef:any;

   onClickDeletePhotoAlbum(index:any){
    //  this.modalRef.hide();
    //  this.openModal(this.deleteAlbumImages);
    this.deleteAlbumModalRef = this.modalService.show(this.deleteAlbumImages,{class: 'deletedd'});
     console.log(this.activeSlides);
   }

   openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  photoDelete(){
    // console.log(this.deletePhotosIds);
    // if(this.firstTimePhotoId){
    //   this.deletePhoto(this.firstTimePhotoId);  
    // }else{
      // this.deletePhoto(this.deletePhotosIds);
    // }
    // this.deletePhotosIds = [];
    if(this.deletePhotosIds?.length){
      console.log(1);
      this.deletePhoto(this.deletePhotosIds);
      this.modalRef.hide();
      this.photoAlbumCheckbox = false; 
    }
    else{
      console.log("no");
      let arr = [{photoId:this.slidesStore[this.activeSlidePosition]._id,imageIndex:this.activeSlidePosition}]
      this.deletePhoto(arr);
      this.deleteAlbumModalRef.hide();
    }
  }

  deletePhoto(deletePhotosIds){
    console.log(deletePhotosIds);
    let imageIds = deletePhotosIds.map(elm => elm.photoId);
    this.service.deleteMultiplePhotos({photoIds:imageIds}).subscribe((res:any)=>{
      if(res.type == 'success'){
        this.toaster.success(res.message);
        console.log(deletePhotosIds);

        // this.getAlbum(this.userId);
        deletePhotosIds.forEach(element => {
          console.log("innn");
          console.log(this.slidesStore);
          // this.slidesStore.splice(element.imageIndex,1);
          let index = this.slidesStore.findIndex((elm)=>{
            return elm._id == element.photoId
          })
          this.slidesStore.splice(index,1);
          console.log(this.slidesStore);
        });
        console.log("end");
        this.deletePhotosIds = [];
      }
    })
  }

  // get active slide owl carousel
  activeSlides:any=SlidesOutputData;
  activeSlidePosition:any = 0;
  // activeImageData:any;

  getActiveSlideData(data: SlidesOutputData) {
    console.log("active slide",data);
    // if(data.startPosition != this.slidesStore.length){
    //   this.activeSlidePosition = data.startPosition;
    // }
    // else{
      this.activeSlidePosition = data.startPosition;
    // }
    console.log(this.activeSlidePosition);
    
    // let ids = [];
    // // this.activeId= this.slidesStore.splice(this.activeSlides?.startPosition,this.activeSlides?.startPosition);
    // console.log("=======================",this.activeId?._id);
    // this.firstTimePhotoId=[];
    // this.deletePhotosIds[0]=this.activeId?._id;

  }

  deletePhotosIds:any=[];
  onClickCheckbox(e:any, photoId:any,imageIndex){
    console.log(e);
    console.log(imageIndex);
    // let ids = e.checked;
    if(e.checked){
        this.deletePhotosIds.push({photoId,imageIndex});
    }else{
      for(var i=0;i<this.deletePhotosIds.length;i++){
        if(this.deletePhotosIds[i]?.photoId==photoId){
          this.deletePhotosIds.splice(i,1);
          break;
        }
      }
    }
  }

  openSlider(imageIndex){
    if(this.photoAlbumCheckbox == false || this.photoAlbumCheckbox == undefined){
      // this.openModal(this.photoAlbumSlider);
      let initialState: ModalOptions = {
        initialState: {
          slidesStore:this.slidesStore,
          customOptions2:this.customOptions2,
          selectedImageIndex:imageIndex,
          userAvailable:this.userAvailable
        } as Partial<Object>,
        class:'photoalbumpopup'
      };
      this.modalRef = this.modalService.show(PhotoAlbumSliderModalContentComponent,initialState);
      this.modalRef.content.deletePhotoFromAlbum.subscribe((result)=>{
        console.log(result);  
        this.activeSlidePosition = result;
        this.onClickDeletePhotoAlbum('');
      })
    }

    // setTimeout(()=>{
    //   // this.owlRef = $('.owl-carousel')[0];
    //   // // this.owlRef?.owlCarousel();
    //   // console.log(this.owlRef);
    //   let anyService = this.owlCar as any;
    //   let carouselService = anyService.carouselService as CarouselService; 
    //   console.log(carouselService); 
    //   debugger;
    // },10000)

  }

  deleteMultiplePhotos(){
    // this.openModal(this.deleteAlbumImages)
    this.modalRef = this.modalService.show(this.deleteAlbumImages, {class:'deletealbumpopup'});

  }

  cancel(){
    // this.modalRef.hide();
    if(this.deletePhotosIds?.length){
      this.modalRef.hide();
      this.photoAlbumCheckbox = false; 
    }
    else{
      this.deleteAlbumModalRef.hide();
    }
  }

  onClickCancleImage(){
    this.photoAlbumCheckbox = false;
    this.photoAlbumCheckbox = false;
    this.deletePhotosIds=[];
  }

  showCheckbox(){
    this.photoAlbumCheckbox = true;
  }

  firstTimePhotoId:any=[];
  getAlbum(id:any){
    let data;
    if(this.userAvailable){
      data = {userId:this.userId}
    }
    this.service.getPhotoAlbum(data,this.pageNumber,this.pageLimit).subscribe((res:any)=>{
      if(res.type == 'success'){
        this.slidesStore = res.data;
        this.firstTimePhotoId[0]=this.slidesStore[0]?._id;
        if(localStorage.getItem("profileorCoverImageDetails")){
          let imageDetails = JSON.parse(localStorage.getItem("profileorCoverImageDetails"));
          this.slidesStore.unshift(imageDetails);
          localStorage.removeItem("profileorCoverImageDetails");
          this.openSlider(imageDetails._id);
        }
      }
    })
  }
  pageNumber = 1;
  pageLimit = 200;
  scrollForPhotos = true;
  getMorePhotosOfAlbum(e){
    let data;
    this.pageNumber++;
    if(this.userAvailable)
      data = {userId:this.userId}
    this.service.getPhotoAlbum(data,this.pageNumber,this.pageLimit).subscribe((res:any)=>{
      if(res.type == 'success'){
        if(res?.data?.length)
          this.slidesStore .push(...res.data);
        else
          this.scrollForPhotos = false;
      }
    })
  }
}

// import { CarouselComponent, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';


