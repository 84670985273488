import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
// import { RegistrationPartService } from '../../registration-part/services/registration.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import { LocationStrategy } from '@angular/common';
// import { UrlService } from '../../registration-part/services/url.service';
import { filter } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'shared-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {
  updateProfile = new FormGroup({
    profilePic: new FormControl(''),
    profilePicKey: new FormControl('')
  });

  submitted: boolean = false;
  imgURLCover:any;
  currentUser:any;
  selectedFile:any;
  showDeleteProfileButton: boolean;
  popupedit: boolean;
  profilePicUrl:any;
  coverPicUrl:any;
  profileData:any;
  profileImageFileUrl:any;
  profileImageKey:any;
  change:boolean = false;
  showEditButton:boolean;
  showDeleteButton:boolean;
  imageChangedEvent: string;
  originalProfilePicRef: any;
  profilePicCropperUrl: string;
  imageTransform: {};
  scale: number = 5;
  originalProfilePicName: any;
  profilePicCropModalRef: any;
  // modalService: any;
  croppedProfilePicRef: any;

  @Output() profilePicDetails = new EventEmitter();

  @ViewChild("template") template: TemplateRef<any>;
  constructor(
    // private service: RegistrationPartService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private location: LocationStrategy,
    // private urlService: UrlService,
    private sharedService: SharedService
  ) {   }
  ngOnInit(): void {
    this.profilePicUrl = "../../../../assets/Indietown_icons/upload-profile-pic.png";
    this.coverPicUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';

    this.sharedService.getProfile().subscribe((res:any)=> {
        if(res.type == 'success'){
          this.profileData = res.data;
          if(res.data?.profileImage.fileUrl){
            this.profilePicUrl = res.data?.profileImage.fileUrl;
            this.showDeleteButton = true;
            this.showDeleteProfileButton = true;
            this.showEditButton = true;
            this.profileImageFileUrl = res.data?.profileImage.fileUrl;
            this.profileImageKey = res.data?.profileImage.key;
            this.updateProfile.controls["profilePic"].setValue(this.profileImageFileUrl);
            this.updateProfile.controls["profilePicKey"].setValue(this.profileImageKey);
          }
        }
    })    
  }
  openProfilePictureModal(type, fileChangedEvent: any, ProfilePicCropModal: TemplateRef<any>) {
    this.popupedit = false;
    this.imageChangedEvent = '';
    this.originalProfilePicRef = '';
    this.profilePicCropperUrl = '';
    this.imageTransform = {};
    this.scale = 1;
    let ngbModalOptions: any = {
      backdrop: 'static',
      keyboard: false,
      class: "profilepiccropmodall"
    };
    // this.profilePicCropModalRef = this.modalService.show(ProfilePicCropModal);
    if (type == 'choose') {
      this.originalProfilePicRef = fileChangedEvent;
      this.imageChangedEvent = fileChangedEvent;
      this.originalProfilePicName = fileChangedEvent?.target?.files[0]?.name;
      this.profilePicCropModalRef = this.modalService.show(ProfilePicCropModal, ngbModalOptions);
    }
    else if (type == 'edit') {
      console.log(this.profilePicUrl);
      this.imageChangedEvent = null;
      this.profilePicCropperUrl = this.profilePicUrl;
      this.profilePicCropModalRef = this.modalService.show(ProfilePicCropModal, ngbModalOptions);
    }

  }

  // uploadProfile(e:any){

  //   let files = e.target.files;
  //   let file = e.target.files[0];
  //   let fd = new FormData();
  //   fd.append("file", file);
  //   if (e.target.files && e.target.files.length) {
  //     for (const file of e.target.files) {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => {
  //         const img = new Image();
  //         img.src = reader.result as string;
  //         img.onload = () => {
  //             this.sharedService.uploadProfile(fd).subscribe((res:any)=>{
  //               this.change = true;
  //               this.profilePicUrl = res.data.fileUrl;
  //               this.profileImageFileUrl = res.data.fileUrl
  //               this.profileImageKey = res.data.key;
  //               this.showEditButton = true;
  //               this.showDeleteButton = true;
  //             })
  //         }
  //       }
  //     }
  //   }
  // }
  // UPDATED CODE FOR EDIT OPTIONS
  uploadProfile() {
    // let files = e.target.files;
    let file = this.croppedProfilePicRef.base64;
    let fd = new FormData();
    const imageFile = new File([this.dataURItoBlob(file)], this.originalProfilePicName, { type: 'image/png' });
    fd.append("file", imageFile);
    // if (e.target.files && e.target.files.length) {
    //   for (const file of e.target.files) {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //       const img = new Image();
    //       img.src = reader.result as string;
    //       img.onload = () => {
    // const height = img.naturalHeight;
    // const width = img.naturalWidth;
    // console.log("Width and Height", width, height);
    // if(width <=2500 && height <= 1200){
    this.sharedService.uploadProfile(fd).subscribe((res: any) => {
      this.profilePicCropModalRef.hide();
      this.showDeleteProfileButton = true;
      this.profilePicUrl = res.data.fileUrl;
      this.updateProfile.controls["profilePic"].setValue(res.data.fileUrl);
      this.updateProfile.controls["profilePicKey"].setValue(res.data.key);
      if (this.originalProfilePicRef?.target?.value) {
        this.originalProfilePicRef.target.value = '';
      }
    })
  }
  save(){
    let data = {
      "profileImage":{
          "fileUrl": this.updateProfile.controls["profilePic"].value,
          "key": this.updateProfile.controls["profilePicKey"].value,
      }
    } 

      // this.profilePicDetails.emit(JSON.stringify(data));

    this.sharedService.updateProfile(data).subscribe((res:any)=> {
      if(res.type == 'success'){
        // this.router.navigateByUrl("/profile/main");
        this.profilePicDetails.emit(JSON.stringify(data));
      }
    })
  }
dataURItoBlob(dataURI) {
  const parts = dataURI.split(';base64,');
  const byteString = atob(parts[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/png' });
  return blob;
}
updateProfileData() {
  // throw new Error('Method not implemented.');
  let data = {
    "fileUrl": this.updateProfile.value.profilePic,
    "key": this.updateProfile.value.profilePicKey
  };

  localStorage.setItem('profilePicture', JSON.stringify(data));
  localStorage.setItem("screensCompleted", 'profile-pic');
  // this.router.navigateByUrl('/').then(() =>{
    this.router.navigateByUrl('register/cover-pic');
  // })
}

zoomOut() {
  if (this.scale == 1) {
    return;
  }

  if (this.scale >= 1) {

    this.scale -= .1;
    this.imageTransform = {
      ...this.imageTransform,
      scale: this.scale
    };
  }

}

zoomIn() {
  if (this.scale <= 2) {
    this.scale += .1;
    console.log( this.scale)
    if (this.scale <= 10) {
      console.log( "hello")
      this.imageTransform = {
        ...this.imageTransform,
        scale: this.scale
      };
    } else {
      console.log( "hi")
      this.scale = 1;
      this.scale += .1;
      this.imageTransform = {
        ...this.imageTransform,
        scale: this.scale
      };
    }
  }
}

ZoomImage(e) {
  var slider: any = document.getElementById("myRange");;
  console.log(slider.value)
  this.scale = slider.value;
  this.imageTransform = {
    ...this.imageTransform,
    scale: slider.value
  };

}

closeProfilePicModal() {
  this.profilePicCropModalRef?.hide()
  // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //   this.router.navigate(['/register/profile-pic']);
  // });

}
// deleteProfilePic(){
//   this.showEditButton = false;
//   this.showDeleteButton=false;
//   this.profilePicUrl = "../../../../assets/Indietown_icons/upload-profile-pic.png";
//   this.profileImageFileUrl = '';
//   this.profileImageKey = '';
// }
deleteProfilePic() {
  this.showEditButton = false;
  this.showDeleteButton=false;
  this.profileImageFileUrl = '';
  this.profileImageKey = '';
  this.showDeleteProfileButton = false;
  this.popupedit = false;
  this.profilePicUrl = "../../../../assets/Indietown_icons/upload-profile-pic.png";
  this.updateProfile.controls["profilePic"].setValue("");
  this.updateProfile.controls["profilePicKey"].setValue("");
}

croppedProfilePicture(event) {
  console.log("Profile Picture Image Cropped");
  console.log(event);
  this.croppedProfilePicRef = event;
}


profilePictureCancelled() {
  this.profilePicCropModalRef?.hide();
  if (this.originalProfilePicRef?.target?.value) {
    this.originalProfilePicRef.target.value = '';
  }
}

}

