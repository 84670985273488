<div class="d-flex align-items-center custom mt-2 width100mob" *ngIf="!userId">
    <div class="form-group col-lg-9 pl-0" style="padding-right: 25px;">
        <input type="text" [(ngModel)]="myInputValues" class="form-control" id="youtubeUrlNew"
            aria-describedby="emailHelp" placeholder="YouTube URL: Video or Playlist" style="border: 1px solid #E9E9EB;
            border-radius: 15px;">
    </div>
    <div>
        <button type="button" class="btn btn-dark btn-lg form-group" [disabled]="!myInputValues.length"
            (click)="addYoutubeUrl()" style="height: 50px;
        /* border-radius: 11px;
        font-size: 20px;
        font-weight: 500; */
        width: 120px;border-radius: 8px;
        background: var(--primary-colour, #0F0D0F);
        color: var(--new-style, #F8F9F8);
        /* text-align: center; */
        font-size: 16px;
        /* font-family: Poppins; */
        font-weight: 600;">Submit</button>
    </div>
</div>
<div *ngIf="showAddUrlLoader" style="text-align: center;">
    <img width="50"  src="../../../assets/Indietown_icons/Loading_icon.gif">
</div>
<div *ngIf="playList.length" style=" padding-right: 17px;" class="youtube-list d-flex" infinite-scroll [scrollWindow]="false"
    [ngClass]="{
        'flex-wrap': playList?.length,
        'justify-content-center': !playList?.length
    }"
    (scrolled)="loadMoreYoutubeData($event)" [infiniteScrollDistance]="6" infinite-scroll-disabled="!loadMoreYoutube">
    <div class="youtube-music-card ma-20" *ngFor="let video of playList; let videoIndex = index">
        <div *ngIf="video.contentType == 'video'" style="position: relative;">
            <img [src]="video?.imageUrl" class="card-img-top" height="179px" alt=""
                style="border-radius: 15px; width: 100%; height: 170px; object-fit: cover;" (click)="openYoutubeModalP('single',videoIndex,video.items[0].videoId)"/>
            <div class="play-container" (click)="openYoutubeModalP('single',videoIndex,video.items[0].videoId)">
                <img src="../../../assets/Indietown_icons/videoplayicon.svg" height="45" width="45" alt="" />
            </div>
            <div style="position: relative">
                <span class="youtubeduration">
                    {{ISO8601toDuration(video?.items[0].contentDetails?.duration)}}</span>
            </div>
            <div class="card-body" style="padding: 5px 5px 10px 5px" (click)="openYoutubeModalP('single',videoIndex,video.items[0].videoId)">
                <p class="youtubetitle">{{ video.name }}</p>
            </div>
            <div style="transform: translate(-16px, 0px);position: absolute;
            top: 14px;
            right: 4px;" (click)="openRemoveYoutubeModal('single',video._id,videoIndex)"
                *ngIf="!userId">
                <button type="button" class="btn btn-light" style="border-radius: 6px;
                border: 1px solid #E9E9EB;
                background: rgb(0 0 0 / 39%);
                color: #fff;">Remove</button>
            </div>
        </div>
        <div *ngIf="video.contentType == 'playlist'" style="position: relative">
            <img [src]="video?.imageUrl" class="card-img-top" height="179px" alt=""
                style="border-radius: 15px; width: 100%; height: 170px;  object-fit: cover;" (click)="getPlaylistItems(video._id,videoIndex)"/>
            <div class="play-container" (click)="getPlaylistItems(video._id,videoIndex)">
                <img src="../../../assets/Indietown_icons/videoplayicon.svg" height="45" width="45" alt="" />
            </div>
            <!-- <div style="position: relative">
                <span class="youtubeduration">
                    {{ISO8601toDuration(video?.items[0].contentDetails?.duration)}}</span>
            </div> -->
            <div style="background: #000000ad;width: 100%;height: 25px;bottom: 26px;border-radius: 0px 0 10px 10px;position: relative;"><span><img style="margin-left: 13px;
                width: 23px;" src="../../../assets/Indietown_icons/ytplalylist.svg"></span><span class="youtubeduration">playlist <b>{{video.totalCount}}</b></span></div>
            <div class="card-body" style="padding: 5px 5px 10px 5px; margin-top: -26px;" (click)="getPlaylistItems(video._id,videoIndex)">
                <p class="youtubetitle">{{ video.name }}</p>
            </div>
            <div (click)="openRemoveYoutubeModal('playlist',video._id,videoIndex)" style="margin-left: -78px;position: absolute;
            top: 14px;
            right: 20px;" class="removebtnpos" *ngIf="!userId">
                <button type="button" style="border-radius: 6px;
                border: 1px solid #E9E9EB;
                background: rgb(0 0 0 / 39%);
                color: #fff;" class="btn btn-light">Remove</button>
            </div>
        </div>

    </div>
</div>
<div *ngIf="!playList.length" class="noplaylistyet">
    No Playlist Yet !
</div>

<!-- <p> <b>time {{getTime(i.id)}}</b></p> -->
<!-- class="play-img" -->
<!-- (unstarted)="unstarted($event)" (buffering)="buffering($event)" (paused)="paused($event)" -->
<ng-template #youtubePlayVideo>
    <div class="modal-body text-center delete-modal align-items-center">
        <yt-player [videoId]="currentlyPlayingVideoId" [options]="videoPlayerOptions"
            (stateChange)="onStateChange($event)"></yt-player>
        <button style="position:absolute;top:26px;right:36px;color:white;" type="button" class="close pull-right"
            aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="d-flex justify-content-center">
            <div (click)="playPreviousVideo()" class="mr-2" style="cursor: pointer;">
                <img class="backward" style="transform: rotate(180deg);" id="youtubePrevious"
                            src="../../../assets/Indietown_icons/skip-forward.svg" alt="">
            </div>
            <div (click)="playNextVideo()" style="cursor: pointer;">
                <img class="forward" id="youtubeForward"
                            src="../../../assets/Indietown_icons/skip-forward.svg" alt="">
            </div>
        </div>
        
    </div>
</ng-template>

<ng-template #youtubePlayList>
    <div class="modal-header p-0">
        <button style="height: 34px;position: fixed;top: 12px;right: 9px;width: 30px;color: black;" type="button" class="close pull-right"
        aria-label="Close" (click)="close()">
        <span aria-hidden="true"><img _ngcontent-pcj-c158="" src="../../../assets/Indietown_icons/close.svg" alt="" style="filter: brightness(0); width: 27px;height: 18px;margin-top: -6px;"></span>
    </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <div *ngFor="let videoSong of selectedYoutubePlaylist;let index=index">
            <div class="spotify-music-card m-2">
                <div class="row" style="align-items: center">
                    <div class="col-1 col-md-1 mob-md-1" (click)="openYoutubeModalP('playlist',index,videoSong.videoId)"
                        style="padding-left: 25px;">
                        <img [src]="videoSong.imageUrl" alt="" height="60" width="60" style="border-radius: 10px" />
                    </div>
                    <div class="col-lg-8 col-md-8 mob-md-9 ml-4" style="padding-left: 32px;"
                        (click)="openYoutubeModalP('playlist',index,videoSong.videoId)">
                        <p class="spot-title" style="color: var(--primary-colour, #0F0D0F);
                        font-size: 16px;
                        font-family: Poppins;">{{videoSong.name}}</p>
                    </div>
                    <div *ngIf="!userId" (click)="openRemoveYoutubeModal('playlistItem',videoSong._id,index)" style="margin-left: 30px;
                                                        margin-top: -8px;">
                        <button type="button" style="border:1px solid #E9E9EB" class="btn btn-light">Remove</button>
                    </div>
                    <!-- <div style="transform: translate(-16px, 0px);" (click)="removeYoutube(videoSong._id,index)"
                        *ngIf="!userId">
                        <button type="button" class="btn btn-light" style="border-radius: 6px;
                        border: 1px solid #E9E9EB;
                        background: rgba(233, 233, 235, 0.30);">Remove</button>
                    </div> -->
                </div>
            </div>
        </div>
        <div *ngIf="showPlaylistLoader" style="text-align: center;">
            <img width="50"  src="../../../assets/Indietown_icons/Loading_icon.gif">
        </div>
    </div>
</ng-template>

<ng-template #removeYoutubeModal>
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="removeModalref.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center align-items-center">
      <p style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;">Are you sure you want to remove this {{removingItem}}?</p>
      <div class="modal-btn d-flex" style="margin-top: 39px;">
        <button class="btn btn-red nobtn" (click)="removeYoutube()" style="color: black;margin-right: 10px;font-weight:bold;">Yes</button>
        <button class="btn btn-blue logoutbtn" (click)="removeModalref.hide()" style="background-color: black;font-weight:bold;">No</button>
      </div>
      <div *ngIf="showRemoveUrlLoader" style="text-align: center;">
        <img width="50"  src="../../../assets/Indietown_icons/Loading_icon.gif">
      </div>
    </div>
</ng-template>