import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { BsModalService } from "ngx-bootstrap/modal";
import { SharedService } from 'src/app/shared/services/shared.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AnalyticsEvents, SVConstants } from 'src/app/app.constants';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  submitted: boolean = false;
  error = error.err;
  loginForm:FormGroup;
  isMobile: boolean = false;
  // ***********************
  hide = true;
  hide1 = true;
  hide2 = true;
  // *********************

  userType: any = 1;

  check: number = 1;
  // Slider
  slides = [
    // {image: '../../../../assets/sliders/indieslider1.jpg', text: 'First'},
    // // {image: '../../../../assets/sliders/indieslider2.jpg',text: 'Second'},
    // {image: '../../../../assets/sliders/indieslider4.png',text: 'Second', id:'indielastimg'},

    // {image: '../../../../assets/sliders/indieslider3.jpg',text: 'Third', id:'indielastimg'}


    // {image: '../../../../assets/sliders/Slider-01.jpg', text: 'First'},
    // {image: '../../../../assets/sliders/Slider-02.jpg',text: 'Second', id:'indielastimg'},
    // {image: '../../../../assets/sliders/Slider-03.jpg',text: 'Third', id:'indielastimg'}

    {image: '../../../../assets/sliders/indieslider1.jpg', text: 'First'},
    {image: '../../../../assets/sliders/Slider-03.jpg',text: 'Second', id:'indielastimg'},
    {image: '../../../../assets/sliders/Slider-01.jpg',text: 'Third', id:'indielastimg'}
  ];
 noWrapSlides = false;
 showIndicator = true;
 modalRef: any;
 profileDetails:any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private service: SharedService,
    private analytics: AngularFireAnalytics
  ) { 
    if (screen.width < 640) {
      // true for mobile device
      this.isMobile = true;
    } else {
      // false for not mobile device
      this.isMobile = false;
    }
  }

  @ViewChild("template") template : TemplateRef<any>;



  receiveEmail:any;

  ngOnInit() {
    this.initForm();
    // let a = localStorage.getItem('receiveEmail');
    // let b = a?.slice(1, a.length-1);
    // this.loginForm.get("email").patchValue(b);   
    // localStorage.removeItem('receiveEmail'); 
    let currentUser = this.authService.currentUserValue;
    if(currentUser){
      // this.router.navigateByUrl('/home');
      this.service.getProfile().subscribe((res:any) => {
        if(res.type == 'success'){
          this.profileDetails = res.data;
          console.log("profile Details",this.profileDetails);
          if(res.data.invitationCode==null){
            this.router.navigateByUrl('/register');  
          }
          else if(res.data.isOtpVerified==false){
            this.router.navigateByUrl('/register/phone');     
          }else if(!this.profileDetails?.role){
            this.router.navigateByUrl('/register/select-role');    
          }
          else{
            if(localStorage.getItem('screensCompleted')){
              if(localStorage.getItem('screensCompleted') == 'playlist' || localStorage.getItem('screensCompleted') == 'all' || localStorage.getItem('screensCompleted') == 'gener'){
                this.router.navigateByUrl('/home');
              }
              else{
                this.router.navigateByUrl('/register/'+localStorage.getItem('screensCompleted'));
              }
            }
            else if(res.data.bandName && res.data.location?.coordinates?.length){
              if(res.data.role == 'Artist'){
                if(res.data.genereIds?.length){
                  this.router.navigateByUrl('/home');
                }
                else
                  this.router.navigateByUrl('/register/select-role');
              }
              else if(res.data.role == 'Community'){
                if(res.data.communityIds?.length){
                  this.router.navigateByUrl('/home');
                }
                else
                  this.router.navigateByUrl('/register/select-role');
              }
              else
                this.router.navigateByUrl('/register/select-role');
            }
            else{
              // if(this.profileDetails?.role == 'Community'){
              //   this.router.navigateByUrl('/register/what-you-do');
              // }
              // else{
                this.router.navigateByUrl('/register/select-role');
              // }
              
            }
            
          }

          // localStorage.removeItem('receiveEmail');
        }
      });
      // if(localStorage.getItem('screensCompleted')){
      //   if(localStorage.getItem('screensCompleted') == 'playlist'){
      //     this.router.navigateByUrl('/home');
      //   }
      //   else{
      //     this.router.navigateByUrl('/register/'+localStorage.getItem('screensCompleted'));
      //   }
      // }
      // else if(currentUser.bandName){
      //   this.router.navigateByUrl('/home');
      // }
      // else{
      //   if(this.profileDetails?.role == 'Community'){
      //     this.router.navigateByUrl('/register/what-you-do');
      //   }
      //   else{
      //     this.router.navigateByUrl('/register/select-role');
      //   }
      // }
    }
   }

  get f() {
    return this.loginForm.controls;
  }

  emailreg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*])[\w !@#$%&*]/;
  passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*~`^\(\)_+=\{\[\}\]\|\:;"'\\<,>\/.\?-])[\w !@#$%&*~`^\(\)_+=\{\[\}\]\|\:;"'\\<,>\/.\?-]/;
  initForm(){
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(this.emailreg)]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
    });
  }

  onAdminSelection(event: any) {
    console.log('admin', event);
    if (event.target.checked == true) {
      this.userType = 1;
      this.check = 1;
    }
  }

  onSupportSelection(event: any) {
    if (event.target.checked == true) {
      this.userType = 5;
      this.check = 2;
    }
  }

  onZoneSelection(event: any) {
    if (event.target.checked == true) {
      this.userType = 6;
      this.check = 3;
    }
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid || this.loginForm.pristine) return;
    this.submitted = false;
    let user: any = {
      email: this.loginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password,
    };

    console.log(user);
    
    this.authService.login(user).subscribe(
      (res: any) => {
        if(res.type == 'success'){
          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.service.getProfile().subscribe((res:any) => {
            if(res.type == 'success'){
              this.profileDetails = res.data;
              console.log("profile Details",this.profileDetails);
              if(res.data.invitationCode==null){
                this.router.navigateByUrl('/register');  
              }
              else if(res.data.isOtpVerified==false){
                this.router.navigateByUrl('/register/phone');     
              }else if(!this.profileDetails?.role){
                this.router.navigateByUrl('/register/select-role');    
              }
              else{
                if(localStorage.getItem('screensCompleted')){
                  if(localStorage.getItem('screensCompleted') == 'playlist'){
                    this.router.navigateByUrl('/home');
                    this.analytics.logEvent(AnalyticsEvents.LOGIN,{isSignedUp:false});
                  }
                  else{
                    this.router.navigateByUrl('/register/'+localStorage.getItem('screensCompleted'));
                  }
                }
                else if(res.data.bandName && res.data.location?.coordinates?.length){

                  if(res.data.role == 'Artist'){
                    if(res.data.genereIds?.length){
                      this.router.navigateByUrl('/home');
                      this.analytics.logEvent(AnalyticsEvents.LOGIN,{isSignedUp:false});
                    }
                    else
                      this.router.navigateByUrl('/register/select-role');
                  }
                  else if(res.data.role == 'Community'){
                    if(res.data.communityIds?.length){
                      this.router.navigateByUrl('/home');
                      this.analytics.logEvent(AnalyticsEvents.LOGIN,{isSignedUp:false});
                    }
                    else
                      this.router.navigateByUrl('/register/select-role');
                  }
                  else
                    this.router.navigateByUrl('/register/select-role');
                  // debugger;
                  // this.router.navigateByUrl('/home');
                  // this.analytics.logEvent(AnalyticsEvents.LOGIN,{isSignedUp:false});
                }
                else{
                  // if(this.profileDetails?.role == 'Community'){
                  //   this.router.navigateByUrl('/register/what-you-do');
                  // }
                  // else{
                    this.router.navigateByUrl('/register/select-role');
                  // }
                  
                }
                
              }
    
              localStorage.removeItem('receiveEmail');
            }
          });
          // localStorage.setItem('password', JSON.stringify(this.loginForm.value.password));
        }
        else{
          this.toastr.error(res.msg);
        }
      },
      (err) => {
        if(err.error?.message?.message=='Please connect to admin as you are blocked.'){
            this.modalRef = this.modalService.show(this.template, {class: 'adminblockedpopup'});
        }else{
          if(err.error?.message?.isValidPassword=='false'){
            this.toastr.error('Wrong password. Please try again.');
          }


          if(err.error?.message?.isValidEmail == false){
              this.toastr.error(err.error.message.message);
          };
          // console.log(err.statusText);
          // if(err.statusText=='Forbidden'){
          //   this.toastr.error('Password combination is not matching');
          // }else{
            if(err.error?.message?.message=='Wrong password. Please try again.'){
              this.toastr.error(err.error.message.message);    
            }

            if(err.error?.message=='Request Validation failed'){
              this.toastr.error("Wrong password. Please try again.");
            }
            
          // }
          // console.log(err.error.message.isValidPassword=='false'){
          //   this.toastr.error(err.error.message.message);  
          // }
          // if(err.error.message == 'object'){
          //   alert('object');
          // }
          // this.toastr.error(err.error.message.message);

          console.log(err);
          console.log(err.status);
          console.log(err.statusText);
          if(err.status == 0 && err.statusText.includes('Unknown Error')){
            console.log("Server Unreachable");
            this.toastr.error("Something went wrong");
          }
        }
        
      }
    );
  }

  openModal(template: TemplateRef<any>){
    this.modalRef = this.modalService.show(template);
  }

  removeError(e){
    this.submitted = false;
    e.target.value = e.target.value.replace(/^\s+/g, '');
    if(e.key=='Enter' && this.loginForm.valid){
        this.login();
    }
  }
}
