<app-layout></app-layout>

<div style="padding-left: 18%; padding-top: 100px;background-color: #F6F6F6;padding-bottom: 20px;">
    <div class="about-container spotify-container">
        <div class="d-flex mb-4">
            <span><img src="assets/Indietown_icons/Spotify_Logo_RGB_Green.png" /></span>
            <!-- <h1 class="spotyfy-text">Spotify</h1> -->
        </div>
    </div>
    <!-- (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')" -->
    <div class="d-flex">
        <!-- <div *ngFor="let track of spotifyList; let spotifyIndex = index" class="d-inine-block mr-4"> -->
            <div class="cursorpointer">
                <img [src]=" spotifySongDetails?.imageUrl" alt="" height="265" width="265" style="object-fit: cover;"/>
            </div>
            <div class="mt-2 cursorpointer d-flex ml-4" style="flex-direction: column;">
                <div class="track-name d-inline-block">{{ spotifySongDetails?.name }}</div>
                <!-- <div *ngIf="spotifySongDetails?.name?.length > 16" class="d-inline">...</div> -->
                <div class="track-artist-name d-inline-block mt-3">{{ spotifySongDetails?.items[0]?.artists[0]?.name }}</div>
                <!-- <div *ngIf="spotifySongDetails?.name?.length > 16" class="d-inline">...</div> -->
                <div class="mt-5">
                    <img src="assets/Indietown_icons/Heart-Shape.png">
                </div>
            </div>
            
        <!-- </div> -->
    </div>
    <div class="d-flex justify-content-center align-items-center mt-4" style="width: 75px;height: 75px;background-color: #0F0D0F;border-radius: 50%;">
        <img src="assets/Indietown_icons/polygon_3_play.png" alt="" style="margin-left: 9px;">
    </div>
</div>
