import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
// import { RegistrationPartService } from '../../registration-part/services/registration.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import { LocationStrategy } from '@angular/common';
// import { UrlService } from '../../registration-part/services/url.service';
import { filter } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'shared-update-cover',
  templateUrl: './update-cover.component.html',
  styleUrls: ['./update-cover.component.scss']
})
export class UpdateCoverComponent implements OnInit {

  submitted: boolean = false;
  imgURLCover:any;
  currentUser:any;
  selectedFile:any;
  profilePicUrl:any;
  coverPicUrl:any;
  profileData:any;
  popupedits: boolean;
  croppedCoverPicRef: any;
  coverImageFileUrl:any;
  coverImageKey:any;
  change:boolean = false;
  coverPictureImageChangedEvent: string;
  originalCoverPicRef: any;
  coverPicCropperUrl: string;
  coverPictureImageTransform: {};
  coverPictureScale: number;
  originalCoverPicName: any;
  coverPictureCropModalRef: any;
  showEditIcon:boolean=false;
  showDeleteCoverButton:boolean;

  @Output() coverPicDetails = new EventEmitter();
  @Input() profileURL;
  
  @ViewChild("template") template: TemplateRef<any>;
  constructor(
    // private service: RegistrationPartService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private location: LocationStrategy,
    // private urlService: UrlService,
    private sharedService: SharedService
  ) {   }

  ngOnInit(): void {

    this.profilePicUrl = "../../../../assets/Indietown_icons/upload-profile-pic.png";
    this.coverPicUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';

    this.sharedService.getProfile().subscribe((res:any)=> {
        if(res.type == 'success'){
          this.profileData = res.data;
          if(res.data?.coverImage.fileUrl){
            this.showEditIcon = true;
            this.showDeleteCoverButton = true;
            this.coverPicUrl = res.data?.coverImage.fileUrl;
            this.coverImageFileUrl =res.data?.coverImage.fileUrl,
            this.coverImageKey = res.data?.coverImage.key;
            this.updateProfile.controls["coverPic"].setValue(this.coverPicUrl);
            this.updateProfile.controls["coverPicKey"].setValue(this.coverImageKey);
            // debugger;
          }

          // if(res.data?.profileImage.fileUrl){
          //   this.profilePicUrl = res.data?.profileImage.fileUrl;
          // }
          if(this.profileURL){
            this.profilePicUrl = this.profileURL;
          }
        }
    })    
  }
  updateProfile = new FormGroup({
    profilePic: new FormControl(''),
    profilePicKey: new FormControl(''),
    coverPic: new FormControl(''),
    coverPicKey: new FormControl(''),
  });
  uploadProfile(e:any){

  let files = e.target.files;
  let file = e.target.files[0];
  let fd = new FormData();
  fd.append("file", file);
  if (e.target.files && e.target.files.length) {
    for (const file of e.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        document.getElementById('updatecoverpict').style.borderRadius="15px";
        img.onload = () => {
            this.sharedService.uploadProfile(fd).subscribe((res:any)=>{
              this.change = true;
              this.showEditIcon = true;
              this.showDeleteCoverButton = true;
              this.coverPicUrl = res.data.fileUrl;
              this.coverImageFileUrl = res.data.fileUrl
              this.coverImageKey = res.data.key;
            })
        }
      }
  }
}
}

save(){


    let data = {
        "coverImage":{
            // "fileUrl": this.coverImageFileUrl,
            // "key": this.coverImageKey
            "fileUrl": this.updateProfile.controls["coverPic"].value,
            "key": this.updateProfile.controls["coverPicKey"].value,
        }
      } 

      this.sharedService.updateProfile(data).subscribe((res:any)=> {
        if(res.type == 'success'){
            // this.router.navigateByUrl("/profile/main");
            this.coverPicDetails.emit(JSON.stringify(data));
        }
      })
}

// deleteCoverPic(){
//   this.showDeleteCoverButton = false;
//   this.showEditIcon = false;
//     this.coverPicUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';
//     this.coverImageFileUrl ='',
//     this.coverImageKey = ''
// }
deleteCoverPic(){
  // debugger;
  this.showDeleteCoverButton = false;
  this.showEditIcon = false;
  // document.getElementById('profilepic').style.bottom="";
  // document.getElementById('editicons').style.bottom="";
  // document.getElementById('editicons').style.right="";
  this.popupedits = false;
    this.coverPicUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';
    document.getElementById('coverpicurll').style.objectFit="";
    this.coverImageFileUrl ='',
    this.coverImageKey = '';
    this.updateProfile.controls["coverPic"].setValue("");
    this.updateProfile.controls["coverPicKey"].setValue("");
}
coverPictureCancelled(){
  this.coverPictureCropModalRef?.hide();
  if(this.originalCoverPicRef?.target?.value){
    this.originalCoverPicRef.target.value = '';
  }
}
closeProfilePicModal() {
  this.coverPictureCropModalRef?.hide()
  // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //   this.router.navigate(['/register/cover-pic']);
  // });

}
coverPictureZoomOut() {
  // if(this.scale == 1){
  //   return;
  // }
  if (this.coverPictureScale >1) {
    this.coverPictureScale -= .1;
    this.coverPictureImageTransform = {
      ...this.coverPictureImageTransform,
      scale: this.coverPictureScale
    };
  }
}
coverPictureZoomImage(e) {
  var slider: any = document.getElementById("myRangeCoverPic");;
  console.log(slider.value)
  this.coverPictureScale = slider.value;
  this.coverPictureImageTransform = {
    ...this.coverPictureImageTransform,
    scale: slider.value
  };
}
croppedCoverPicture(event) {
  console.log("Cover Picture Image Cropped");
  console.log(event);
  this.croppedCoverPicRef = event;
}
scale:number;
coverPictureZoomIn() {
  // if (this.coverPictureScale <= 2) {
  //   this.coverPictureScale += .1;
  //   this.coverPictureImageTransform = {
  //     ...this.coverPictureImageTransform,
  //     scale: this.coverPictureScale
  //   };
  // }

  if (this.coverPictureScale <= 2) {
    this.coverPictureScale += .1;
    console.log( this.scale)
    if (this.coverPictureScale <= 10) {
      console.log( "hello")
      this.coverPictureImageTransform = {
        ...this.coverPictureImageTransform,
        scale: this.coverPictureScale
      };
    } else {
      console.log( "hi")
      this.coverPictureScale = 1;
      this.coverPictureScale += .1;
      this.coverPictureImageTransform = {
        ...this.coverPictureImageTransform,
        scale: this.coverPictureScale
      };
    }
  }
}
dataURItoBlob(dataURI) {
  const parts = dataURI.split(';base64,');
  const byteString = atob(parts[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/png' });    
  return blob;
}
uploadCoverProfile(){
  // debugger;
  let file = this.croppedCoverPicRef.base64;
  let fd = new FormData();
  const imageFile = new File([this.dataURItoBlob(file)], this.originalCoverPicName, { type: 'image/png' });
  fd.append("file", imageFile);
  this.sharedService.uploadProfile(fd).subscribe((res:any)=>{
    this.coverPicUrl = res.data.fileUrl;
    // document.getElementById('coverpicurll').style.objectFit="cover";
    // if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // document.getElementById('coverpicurll').style.height="200px";
    // }
    // document.getElementById('profilepic').style.bottom="117px";
    // document.getElementById('editicons').style.bottom="56px";
    // document.getElementById('editicons').style.right="-332px";
    this.updateProfile.controls["coverPic"].setValue(res.data.fileUrl);
    this.updateProfile.controls["coverPicKey"].setValue(res.data.key);
    this.showDeleteCoverButton = true;
    this.coverPictureCropModalRef.hide();
    if(this.originalCoverPicRef?.target?.value){
      this.originalCoverPicRef.target.value = '';
    }
    // this.showDeleteProfileButton = true;
    // this.profilePicUrl = res.data.fileUrl;
    // this.showDeleteProfileButton = true;
    // this.updateProfile.controls["profilePic"].setValue(res.data.fileUrl);
    // this.updateProfile.controls["profilePicKey"].setValue(res.data.key);
    // this.profilePicCropModalRef.hide();
  })
}

openCoverPictureModal(type,fileChangedEvent:any,coverPicCropModal:TemplateRef<any>){
  // debugger;
  this.popupedits = false;
  this.coverPictureImageChangedEvent = '';
  this.originalCoverPicRef = '';
  this.coverPicCropperUrl = '';
  this.coverPictureImageTransform = {};
  this.coverPictureScale = 1;

  let ngbModalOptions: any = {
    backdrop: 'static',
    keyboard: false,
    class:'profilepiccropmodall',
  };

  if(type == 'choose'){
    this.originalCoverPicRef = fileChangedEvent;
    this.coverPictureImageChangedEvent = fileChangedEvent;
    this.originalCoverPicName = fileChangedEvent?.target?.files[0]?.name;
    this.coverPictureCropModalRef = this.modalService.show(coverPicCropModal, ngbModalOptions);  
  }
  else if(type == 'edit'){
    this.coverPictureImageChangedEvent = null;
    this.coverPicCropperUrl = this.coverPicUrl; 
    this.coverPictureCropModalRef = this.modalService.show(coverPicCropModal, ngbModalOptions);  
  }

}

}

