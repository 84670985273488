import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  submitted: boolean = false;
  modalRef: BsModalRef;
  resendMail:any;



  @ViewChild("template") template: TemplateRef<any>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) { }

  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })

  ngOnInit(): void {
    this.resendMail = localStorage.getItem('receiveEmail');
    // this.resendMail = this.resendMail.splice(1);
    // alert(this.resendMail.length-1);
    this.resendMail = this.resendMail.slice(1,this.resendMail.length-1);

  }

  get f() {
    return this.resetForm.controls;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  sendEmailToLogin:any;

  resetPassword() {
    this.submitted = true;
    if (this.resetForm.invalid || this.resetForm.pristine) return;
    this.submitted = false;

    this.authService.forgetPassword({email:this.resetForm.value.email.toLowerCase(), userType:1})
    .subscribe((res: any) => {
      if (res.status == 200) {
        this.sendEmailToLogin = this.resetForm.value.email;
        this.openModal(this.template);
        this.sendEmailToLogin = this.resetForm.value.email.toLowerCase();
        this.authService.SendToChild1(this.sendEmailToLogin);
        localStorage.setItem('receiveEmail', JSON.stringify(this.sendEmailToLogin));
      }
    })
    // this.authService.emailValidation({email:this.resetForm.value.email.toLowerCase(), userType:1})
    // .subscribe((res:any) => {
    //   if(res.status == 404){
    //     this.toastr.error(res.error);
    //   }

    //   if(res.status == 200){
    //     this.authService.forgetPassword({email:this.resetForm.value.email.toLowerCase(), userType:1})
    //     .subscribe((res: any) => {
    //       if (res.status == 200) {
    //         this.sendEmailToLogin = this.resetForm.value.email;
    //         this.openModal(this.template);
    //         this.sendEmailToLogin = this.resetForm.value.email.toLowerCase();
    //         this.authService.SendToChild1(this.sendEmailToLogin);
    //         localStorage.setItem('receiveEmail', JSON.stringify(this.sendEmailToLogin));
    //       }
    //     })
    //   }
    // },
    // (err) => {
    //   console.log(err);
    //   this.toastr.error('email not registered');
    // })
  }

  OkPress(){
    this.modalRef.hide();
    // this.authService.SendToChild1(this.resetForm.value.email);
    // this.authService.SendToChild1(this.sendEmailToLogin);
    this.router.navigateByUrl('/login');
  }

  resendPassword(){
    this.authService.forgetPassword({email:this.resendMail.toLowerCase()})
    .subscribe((res: any) => {
      if (res.type == 'success') {
        this.sendEmailToLogin = this.resetForm.value.email;
        // this.openModal(this.template);
        this.toastr.success(res.message);
        this.sendEmailToLogin = this.resetForm.value.email.toLowerCase();
        this.authService.SendToChild1(this.sendEmailToLogin);
      }
    },(error: any) => {
      this.toastr.error(error.error.message);
    })
  }

}
