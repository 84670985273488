import { Injectable, OnInit } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { AuthService } from '../auth/services/auth.service';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { tap } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";



@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        private toastrService: ToastrService

    ) { }

    currentUser:any;


    ngOnInit(): void {
            this.currentUser = this.authService.currentUserValue;
    }

    

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authService.currentUserValue;
        const basicAuthCredsBase64 = this.authService.basicAuthCredsBase64;
        if(currentUser){
            request = request.clone({
                setHeaders: {
                    'Authorization': currentUser.token
                    //accessToken:"Bearer "+currentUser.token
                }
            })
        }

        // request = request.clone({ headers: request.headers.append('Authorization', "Basic "+basicAuthCredsBase64) });

        return next.handle(request).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                        if (event.body.status == 401) {
                                if(event.body.error.errorCode == 44){
                                    this.router.navigate(['/auth/login']);
                                    this.toastrService.error("Session expired please re-login again");
                                }else{
                                    this.router.navigate(['/auth/login']);
                                    this.toastrService.error('Invalid email or password');
                                }
                        }
                    }
                },
                error => {
                    if (error.status == 503) {
                        if (error.error.errorCode == 101 || error.error.errorCode == 102 || error.error.errorCode == 104) {
                            if (error.error.errorCode == 101) {
                                this.router.navigate(['/auth/login']);
                                this.toastrService.error('You are already logged in with different device');
                            }
                            this.toastrService.error('You are already logged in with different device');
                            this.router.navigate(['/auth/login']);
                            
                        }

                    } 
                    if(error.status == 0 && error.statusText.includes('Unknown Error')){
                        console.log("Server Unreachable i");
                        this.toastrService.error("Something went wrong");
                    }
                    // else if (error.status == 403) {
                    //     this.toastrService.error(error.error.message);
                    //     this.router.navigate(['/auth/login']);
                    // }   
                    
                    // else if (error.status == 500) {
                    //     this.toastrService.error(error.error.message);
                    // }   
                    // else{
                    //     this.router.navigate(['/auth/login']);
                    // }
                    // else {
                    //     this.toastrService.error('You are already logged in with different device');
                    //     this.router.navigate(['/auth/login']);
                    // }
                }
            )
        );
    }
}