<!-- <p>spotify-view works!</p> -->
<app-layout></app-layout>

<div style="margin-left: 18%; margin-top: 100px;">
    <div class="about-container spotify-container">
        <div class="d-flex mb-4">
            <span><img src="assets/Indietown_icons/Spotify_Logo_RGB_Green.png" /></span>
            <!-- <h1 class="spotyfy-text">Spotify</h1> -->
        </div>
    </div>
    <!-- (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')" -->
    <div class="d-flex">
        <div *ngFor="let track of spotifyList; let spotifyIndex = index" class="d-inine-block mr-4">
            <div class="cursorpointer" (click)="navigateToSelectedSong('singleTrack',spotifyIndex)">
                <img [src]=" track?.imageUrl" alt="" height="243" width="243" style="object-fit: cover;"/>
            </div>
            <div class="track-name mt-2 cursorpointer">
                <div class="d-inline-block">{{ track?.name | slice:0:16 }}</div>
                <div *ngIf="track?.name?.length > 16" class="d-inline">...</div>
            </div>
            <div class="track-artist-name">
                <div class="d-inline-block">{{ track?.items[0]?.artists[0]?.name | slice:0:16 }}</div>
                <div *ngIf="track?.name?.length > 16" class="d-inline">...</div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="mt-3" style="margin-bottom: 50px">
    <div class="row" style="margin: 0px">
        <div class="col-lg-7 h-500">
            <div class="d-flex align-items-center">
                <div class="form-group col-lg-8">
                    <input type="text" class="form-control" id="spotifyUrlNew" aria-describedby="emailHelp"
                        placeholder="Enter Url">
                </div>
                <div>
                    <button type="button" class="btn btn-dark btn-lg" (click)="addSpotifyUrl()">Submit</button>
                </div>
            </div>
            <div class="spotify-music-card m-2" *ngFor="let track of spotifyList; let spotifyIndex = index">
                <div class="row" style="align-items: center">
                    <ng-template [ngIf]="track.contentType == 'track'">

                        <div class="col-1 col-md-1 mob-md-1">
                            <img [src]="track?.imageUrl" alt="" height="60" width="60" style="border-radius: 15px" />
                        </div>
                        <div class="col-lg-9 col-md-9 mob-md-9" style=""
                            (click)="playSelectedSong('singleTrack',spotifyIndex,'')">
                            <p class="spot-title">{{ track?.name }}</p>
                        </div>

                        <div class="col-lg-2 col-md-2 mob-md-2">
                            <p class="spot-time">
                                {{ getTimeInMinuteAndSeconds(track?.items[0].duration_ms) }}
                            </p>
                        </div>



                    </ng-template>
                    <ng-template [ngIf]="track.contentType == 'playlist'">
                        <mat-accordion>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div class="col-1 col-md-1 mob-md-1">
                                            <img [src]="track?.imageUrl" alt="" height="60" width="60"
                                                style="border-radius: 15px" />
                                        </div>

                                        <div class="col-lg-9 col-md-9 mob-md-9" style="">
                                            <p class="spot-title">{{ track?.name }}</p>
                                        </div>
                                    </mat-panel-title>
                                    
                                </mat-expansion-panel-header>
                                <ul>
                                    <li *ngFor="let subtrack of track.items;let subTrackIndex = index" class="d-flex"
                                        (click)="playSelectedSong('playlist',spotifyIndex,subTrackIndex)">
                                        <div class="col-1 col-md-1 mob-md-1">
                                            <img [src]="subtrack?.imageUrl" alt="" height="60" width="60"
                                                style="border-radius: 15px" />
                                        </div>
                                        <div class="col-lg-9 col-md-9 mob-md-9" style="">
                                            <p class="spot-title">{{ subtrack?.name }}</p>
                                        </div>

                                        <div class="col-lg-2 col-md-2 mob-md-2">
                                            <p class="spot-time">
                                                {{ getTimeInMinuteAndSeconds(subtrack?.duration_ms) }}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </mat-expansion-panel>

                        </mat-accordion>
                        


                        
                    </ng-template>


                </div>
            </div>
            
            
            <div class="spotifyplayer">
                <div class="d-flex w-80">
                    <div class="saudioplayermob">
                        <div class="d-flex spotplayermab">
                            <img class="spotifyplayerimg" [src]="currentlyPlayingSpotifySong?.cover" alt="">
                            <div class="spotifytxtbox">
                                <span class="spotifysongname">{{currentlyPlayingSpotifySong?.name}}</span>
                                <span class="spotifyalbum">{{currentlyPlayingSpotifySong?.albumName}}</span>
                            </div>
                        </div>
                        <div class="saudioplayer visible-xs justify-content-center align-items-center">
                            <img class="volumee" src="../../../assets/Indietown_icons/volume-up.svg" alt="">
                            <img class="volumee" style="display: none;"
                                src="../../../assets/Indietown_icons/Volume Off.svg" alt="">
                            <input class="sprogressbar" id="volumeprogress" type="range" min="1" max="100" value="50">
                        </div>
                    </div>

                    <div class="saudioplayer btnsbox">
                        <div class="playerbtns d-flex">
                            <img class="shuffle" *ngIf="!spotifyShuffleModeOn" (click)="spotifySongShuffleToggle()"
                                src="../../../assets/Indietown_icons/shuffle.svg" alt="">
                            <img class="shuffle" *ngIf="spotifyShuffleModeOn" (click)="spotifySongShuffleToggle()"
                                src="../../../assets/Indietown_icons/shuffle-dark.svg" alt="">
                            <img class="backward" id="spotifyPrevious" (click)="spotifyPlayPrevious()"
                                src="../../../assets/Indietown_icons/skip-forward.svg" alt="">
                            <img class="backward" style="display:none;"
                                src="../../../assets/Indietown_icons/skip-forward-disabled.svg" alt="">
                            <img class="playbtn" style="display: none;"
                                src="../../../assets/Indietown_icons/spotifyplayerd.svg" alt="">
                            <img class="playbtn" id="spotifySongPlay" (click)="spotifySongPlay()"
                                src="../../../assets/Indietown_icons/spotifyplayer.svg" alt="">
                            <img class="playbtn" id="spotifySongPause" (click)="spotifySongPause()"
                                style="display: none;" src="../../../assets/Indietown_icons/pauseicon.svg" alt="">
                            <img class="forward" id="spotifyNext" (click)="spotifyPlayNext()"
                                src="../../../assets/Indietown_icons/skip-forward.svg" alt="">
                            <img class="forward" style="display:none;"
                                src="../../../assets/Indietown_icons/skip-forward-disabled.svg" alt="">
                            <img class="repeat" *ngIf="!spotifySongRepeatModeOn" (click)="spotifySongRepeatToggle()"
                                src="../../../assets/Indietown_icons/repeat.svg" alt="">
                            <img class="repeat" *ngIf="spotifySongRepeatModeOn" (click)="spotifySongRepeatToggle()"
                                src="../../../assets/Indietown_icons/repeat-dark.svg" alt="">
                        </div>
                        <div class="splayer d-flex justify-content-center">
                            <p class="currenttime commontimer">{{spotifySongPlayedTill}}</p>
                            <input class="sprogressbar" id="audioprogress" type="range" min="1" max="100"
                                [value]="spotifySongProgressBarValue" (change)="changeSongPosition($event)">
                            <p class="remainingtime commontimer">
                                {{getTimeInMinuteAndSeconds(currentlyPlayingSpotifySong?.durationInMilli)}}</p>

                        </div>
                    </div>
                    <div class="saudioplayer hidden-xs d-flex justify-content-center align-items-center">
                        <img class="volumee" *ngIf="spotifyVolumeOn" (click)="spotifyVolumeToggle()"
                            src="../../../assets/Indietown_icons/volume-up.svg" alt="">
                        <img class="volumee" *ngIf="!spotifyVolumeOn" (click)="spotifyVolumeToggle()"
                            src="../../../assets/Indietown_icons/Volume Off.svg" alt="">
                        <input class="sprogressbar" id="volumeprogress" type="range" min="1" max="100"
                            [value]="spotifyVolume" (change)="volumeChange($event)">
                    </div>
                </div>

            </div>

        </div>
        <div class="col-lg-5 suggestionscardd" (scroll)="onScroll($event)"
            [ngClass]="{'positionfix': sidebarFixed==true}" style="margin-top: -9%;">
            <app-suggestions-card></app-suggestions-card>
        </div>
    </div>
</div> -->