import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/components/login/login.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "user",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },



      {
        path: "analytics-dashboard",
        loadChildren: () =>
        import("../app/dashboard/dashboard.module").then(
          (m) => m.DashboardModule
        )
      },

      {
        path: "home",
        loadChildren: () =>
          import("./views/home/home.module").then(
            (m) => m.HomeModule
          ),
      },

      {
        path: "notifications",
        loadChildren: () =>
          import("./views/notifications/notifications.module").then(
            (m) => m.NotificationsModule
          ),
      },

      {
        path: "settings",
        loadChildren: () =>
          import("./views/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
      },

      {
        path: "profile",
        loadChildren: () =>
          import("./views/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
      },

      {
        path: "messages",
        loadChildren: () =>
          import("./views/messages/messages.module").then(
            (m) => m.MessagesModule
          ),
      },


      {
        path: "register",
        loadChildren: () =>
          import("./views/registration-part/registration-part.module").then(
            (m) => m.RegistrationPartModule
          ),
      },

      {
        path: "search-result",
        loadChildren: () =>
          import("./views/search-result/search-result.module").then(
            (m) => m.SearchResultModule
          ),
      },

      {
        path: "view-profile",
        loadChildren: () =>
          import("./views/view-profile/view-profile.module").then(
            (m) => m.ViewProfileModule
          ),
      },

    ],
  },
  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
