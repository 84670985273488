<div style="height: 100vh; width: 100%;background-color: black;" class="test splashscrn">
    <div class="main">
          <!-- <img src="../../../../assets/Indietown_icons/splash-screen.svg" alt=""> -->
          <img src="assets/Indietown_icons/Splash_1x.png" alt="">
          <!-- <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator">
            <slide *ngFor="let slide of slides; let index=index">
               <img [src]="slide.image" alt="image slide" style="display: block; width: 100%;">
               <div class="carousel-caption">
               </div>
            </slide>
         </carousel> -->
          <!-- <button class="btn btn-block" style="position: fixed;bottom: 20px;left: 48%;width: 50px;background-color: aliceblue;" routerLink="/auth/login">></button> -->
    </div>
</div>

