import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class NotificationsService {

    url: any = environment.API_URL;

    public newNotification$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
        private http: HttpClient
    ) {

    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    friendRequestList(page,pageSize){
        return this.http.get(this.url + `api/friend/fr-list?page=${page}&pageSize=${pageSize}`);
    }

    getBandSuggestions(data:any){
        return this.http.post(this.url + 'api/user/get-bands-suggession', data);
    }

    getProfile(){
        return this.http.get(this.url + 'api/user/profile');
    }

    activityList(data:any){
        return this.http.get(this.url + 'api/friend/fr-list');       
    }

    acceptRejectRequest(id:any, data:any){
        return this.http.put(this.url + `api/friend/update-friend-request/${id}`,data);
    }

    AddFriend(data:any){
        return this.http.post(this.url + 'api/friend/add-friend', data);
    }

    cancelRequest(data,id){
        return this.http.put(this.url + `api/friend/update-friend-request/${id}`,data);
    }

    getNotifications(page,pageSize){
        // return this.http.get(this.url + 'api/notification/web-list');
        return this.http.get(this.url + `api/notification/list?page=${page}&pageSize=${pageSize}`);
    }

    getPostDetails(postId){
        return this.http.get(this.url + `api/post/${postId}`)
    }

    readNotifications(){
        return this.http.put(this.url + 'api/notification/read',{});
    }
}