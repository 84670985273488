import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacysetting',
  templateUrl: './privacysetting.component.html',
  styleUrls: ['./privacysetting.component.scss']
})
export class PrivacySettingComponent implements OnInit {
isMobile:boolean=false;
  constructor( ) { 
    if(screen.width < 640) {
      // true for mobile device
      this.isMobile = true;
    } else {
      // false for not mobile device
      this.isMobile = false;
    }
  }

  ngOnInit() {
   }

}
