<div class="friendSuggestionBox" style="margin-top: 9%;height: 395px">
    <div>
      <div class="card-header" style="padding: 20px;">
        <div class="d-flex justify-content-between align-items-center">
          <h1 class="friendrequesttxt">Suggested Friends</h1>
        </div>
      </div>
      <div class="scrollhidden" style="height: 319px; overflow: auto;">
      <div
        class="friendRequest-dropdown"
        *ngFor="let band of bandSuggestionList; let i = index"
      >
        <mat-card-header class="d-flex">
          <img
          routerLink="/user/profile" [queryParams]="{ id: band?._id, user:band?.bandName }"
            height="40"
            width="40"
            mat-card-avatar
            [src]="band?.profileImage?.fileUrl ? band?.profileImage?.fileUrl : '../../../../assets/Indietown_icons/upload-profile-pic.png'"
            alt=""
            style="border-radius: 100%; cursor: pointer;"
          />
          <mat-card-title class="bandName" style="overflow-wrap: anywhere;">{{
            band?.bandName |titlecase
          }}</mat-card-title>
          <!-- <mat-card-subtitle class="cityName" *ngIf="band?.communities.length>0">{{band?.communities[0]?.communityName}}</mat-card-subtitle> -->
          <!-- <mat-card-subtitle class="cityName"><span  *ngFor="let i of band?.communities;let index=index">{{i.communityName}} <span *ngIf="band?.communities.length>1 && index<=band?.communities.length-2"> | </span></span></mat-card-subtitle> -->
          <mat-card-subtitle style="overflow-wrap: anywhere;">{{band?.communityIds.length>0 ? ((band?.communityIds[0]?.communityName != null ? band?.communityIds[0]?.communityName : '') + (band?.communityIds[1]?.communityName!=null ?  " | " +band?.communityIds[1]?.communityName : '')): ((band?.genereIds[0]?.genereName!=null ? band?.genereIds[0]?.genereName : '') +  (band?.genereIds[1]?.genereName!=null ? " | " +band?.genereIds[1]?.genereName : '') + (band?.genereIds[2]?.genereName ? " | " +band?.genereIds[2]?.genereName :
            ''))}}</mat-card-subtitle>
        </mat-card-header>

        <mat-card-actions class="addFriendButtons">
          <button
          style="border-radius: 8px; box-shadow: none;"
            class="btn btn-dark addFriend btn-sm"
            (click)="AddFriend(band._id, i)"
            id="addFriendText{{ band?._id }}"
            *ngIf="band.friendStatus == 'NOT_FRIEND'"
          >
            Add Friend
          </button>
          <button
          style="border-radius: 8px; box-shadow: none;color: black !important;background-color:white !important;"
            class="btn btn-dark addFriend btn-sm"
            (click)="AddFriend(band._id, i)"
            id="addFriendText{{ band?._id }}"
            *ngIf="band.friendStatus == 'REQUEST_SENT'"
          >
            <!-- cancel request -->
            Pending
          </button>
          <button
          style="border-radius: 8px; box-shadow: none;color: black !important;background-color:white !important;"
            class="btn btn-dark addFriend btn-sm"
            (click)="AddFriend(band._id, i)"
            id="addFriendText{{ band?._id }}"
            *ngIf="band.friendStatus == 'ACCEPT_REQUEST'"
          >
            Accept
          </button>
        </mat-card-actions>
      </div>
    </div>
  </div>
</div>
<ng-template #cancelRequest>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalref.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center align-items-center">
    <p style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;">Are you sure you want to cancel <br>
      this request?</p>
    <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
      <button class="btn btn-red nobtn" (click)="cancelFriendRequest()" style="color: black;margin-right: 10px;font-weight:bold;">Yes</button>
      <button class="btn btn-blue logoutbtn" (click)="modalref.hide()" style="background-color: black;font-weight:bold;">No</button>
    </div>
    <div *ngIf="showCancelRequestLoader" style="text-align: center;">
      <img width="50"  src="../../../assets/Indietown_icons/Loading_icon.gif">
    </div>
  </div>
</ng-template>