import { Component, Inject, OnInit } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-spotify-view',
  templateUrl: './spotify-view.component.html',
  styleUrls: ['./spotify-view.component.scss']
})
export class SpotifyViewComponent implements OnInit {

  constructor(
    private service: SharedService,
    private toastrService: ToastrService,
    private spinner: SpinnerVisibilityService,
    @Inject(DOCUMENT) private document: Document,
    private modalService: BsModalService,
    private route: Router
  ) { }

  userId;
  ngOnInit(): void {
    this.getSpotifyList();
    // Read userID from route pending

  }

  spotifyList = [];
  spotifyPageNumber = 1;
  spotifyPageLimit = 20;
  getSpotifyList(){
    this.spinner.show();
    this.service.getSpotifyList(this.userId,this.spotifyPageNumber,this.spotifyPageLimit).subscribe((res:any)=>{
      console.log(res);
      this.spinner.hide();
      if(res.data.length){
        for(let i=0;i<res.data.length;i++){
          if(res.data[i].contentType == 'playlist' || res.data[i].contentType == 'album'){
            res.data[i].expanded = false;
          }
        }
      }
      this.spotifyList = res.data;
    },(err) => {
      this.spinner.hide();
      console.log("some error occured");
      this.toastrService.info("some error occured, please try again");
    }
    )
  }

  navigateToSelectedSong(type,spotifyIndex){
    localStorage.setItem("spotifySongType",type);
    localStorage.setItem("spotifySongDetails",JSON.stringify(this.spotifyList[spotifyIndex]));
    this.route.navigateByUrl("/spotify-play");
  }

}
