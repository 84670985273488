import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../services/shared.service';
import { YtPlayerService } from 'yt-player-angular';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent implements OnInit {

  @ViewChild("youtubePlayVideo") youtubePlayVideo: TemplateRef<any>;

  @ViewChild("youtubePlayList") youtubePlayList: TemplateRef<any>;

  @Input() userId;

  @ViewChild("removeYoutubeModal") removeYoutubeModal: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    private service: SharedService,
    private toastrService: ToastrService,
    private ytPlayerService: YtPlayerService
  ) { }

  ngOnInit(): void {
    this.getYoutubePlaylist();
  }

  playList = [];
  NextPageTokenForYoutube;
  moreVideosComing: Boolean = false;
  myInputValues = '';

  page = 1;
  pageSize = 20;

  modalRef: any;
  loadMoreYoutube: Boolean = true; 

  getYoutubePlaylist() {
    this.service.getYoutubeList(this.userId,this.page,this.pageSize).subscribe((res: any) => {

      if(res.data && res.data.length){
        this.playList = res?.data;
      }
      // this.playList = res?.data?.youtubeResult;
      // console.log(res.data.videos);
      // this.playList = res.data.videos;
      // if (res.data?.pageInfo?.nextPageToken) {
      //   this.moreVideosComing = true;
      // }
      // this.NextPageTokenForYoutube = res.data.pageInfo.nextPageToken;
    })
  }

  youtubePlayData;
  currentlyPlayingVideoId;
  videoPlayerOptions = {
    autoplay: true,
    width:906,
    height:596
  }
  selectedVideoFrom;
  selectedVideoIndex;
  openYoutubeModalP(type,index,videoId) {
    // console.log(videoIndex);
    // console.log(data);
    // this.youtubePlayData=data;

    this.currentlyPlayingVideoId = videoId;
    this.selectedVideoFrom = type;
    this.selectedVideoIndex = index;

    let initialState: ModalOptions = {
      // initialState: {
      //   videoId: this.currentlyPlayingVideoId,
      //   videoIndex,
      //   playList:this.playList,
      //   videoPlayerOptions : {
      //     autoplay: true,
      //     width:906,
      //     height:596
      //   }
      // } as Partial<Object>,
      class: 'ytmodalpopup'
    };
    this.modalRef = this.modalService.show(this.youtubePlayVideo, initialState);



    // this.openModal(this.youtubePlay);
    // setTimeout(()=>{
    //   this.currentlyPlayingVideoId = data.url.split('v=')[1];
    //   console.log(data);
    //   console.log(this.currentlyPlayingVideoId);
    //   this.ytPlayerService.play();
    //   // console.log(this.youtubePlayer);
    //   // this.youtubePlayer.load('GKSRyLdjsPA')
    //   // this.youtubePlayer.setVolume(100)

    //   // this.youtubePlayer.on('playing', () => {
    //   //   console.log(this.youtubePlayer.getDuration()) // => 351.521
    //   // });
    // },5000)

  }

  ISO8601toDuration(input) {
    var H = this.formatTimeUnit(input, 'H');
    var M = this.formatTimeUnit(input, 'M');
    var S = this.formatTimeUnit(input, 'S');

    if (H == "00") {
      H = "";
    } else {
      H += ":"
    }

    return H + M + ':' + S;
  }

  formatTimeUnit(input, unit){
    var index = input.indexOf(unit);
    var output = "00"
    if(index < 0){
      return output; // unit isn't in the input
    }
  
    if(isNaN(input.charAt(index-2))){
      return '0' + input.charAt(index-1);
    }else{
      return input.charAt(index-2) + input.charAt(index-1);
    }
  }

  showAddUrlLoader: Boolean = false;
  addYoutubeUrl(){
    this.showAddUrlLoader = true;
    console.log((<HTMLInputElement>document.getElementById("youtubeUrlNew")).value);
    let youtubeUrlNew = (<HTMLInputElement>document.getElementById("youtubeUrlNew")).value;
    this.service.addYoutubeUrl({url:youtubeUrlNew}).subscribe((res:any)=>{
      this.showAddUrlLoader = false;
      console.log(res);
      if(res?.data?.items?.length){
        res.data.totalCount = res.data?.items?.length;
      }
      this.playList.unshift(res.data);
      (<HTMLInputElement>document.getElementById("youtubeUrlNew")).value = '';
      this.myInputValues = '';
    },
    (err)=>{
      this.showAddUrlLoader = false;
      console.log(err);
      console.log(err?.error?.message);
      if(err?.error?.message?.includes('iFrame code already added')){
        this.toastrService.info("iFrame code already added");
      }
      else {
        this.toastrService.info(err?.error?.message);
      }
      (<HTMLInputElement>document.getElementById("youtubeUrlNew")).value = '';
      this.myInputValues = '';
    })
  }
  // transFormUrl(url){
  //   // return this.domSanitizer.sanitize(SecurityContext.URL, url);
  //   return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  // }
  iframeSource = '';
  // soundcloudModalP(iframesrc:any){
  //   let initialState: ModalOptions = {
  //     initialState: {
  //       source:iframesrc
  //     } as Partial<Object>,
  //     class: 'soundcloudpopup'
  //   };
  //   this.iframeSource = iframesrc;
  //   this.modalRef = this.modalService.show(this.soundcloudIframe, initialState);
  // }

  // removeYoutube(id,index){
    // this.service.removeYoutubeUrl(id).subscribe((res:any)=>{
    //   console.log(res);
    //   if(res.type == 'success'){
    //     this.playList.splice(index,1);
    //   }
    // })
  // }

  loadMoreYoutubeData(e){
    console.log("scrolled fired");
    if(this.loadMoreYoutube){
      console.log("loading more")
      this.page++;
      this.getMoreYoutubeList();
    }
  }

  // loadMoreSoundcloud:Boolean = true;
  // userId;
  getMoreYoutubeList(){
    this.service.getYoutubeList(this.userId,this.page,this.pageSize).subscribe((res:any)=>{
      console.log(res);
      if(res.data.length){
        this.playList.push(...res.data);
      }
      else{
        this.loadMoreYoutube = false;
      }
    })
  }

  openYoutubePlayVideoModal(videoIndex, data: any) {
    console.log(videoIndex);
    console.log(data);
    this.youtubePlayData=data;
    this.currentlyPlayingVideoId = data.url.split('v=')[1];
    let initialState: ModalOptions = {
      initialState: {
        videoId: this.currentlyPlayingVideoId,
        videoIndex,
        playList:this.playList,
        videoPlayerOptions : {
          autoplay: true,
          width:906,
          height:596
        }
      } as Partial<Object>,
      class: 'ytmodalpopup'
    };
    this.modalRef = this.modalService.show(this.youtubePlayVideo, initialState);

  }

  selectedYoutubePlaylist = [];
  selectedYoutubePlaylistIndex;
  youtubePlayListModalRef;
  showPlaylistLoader: Boolean = false;
  getPlaylistItems(id,index){
    // this.showPlaylistLoader = true;
    this.youtubePlayListModalRef = this.modalService.show(this.youtubePlayList,{class:'youtubeplaylisstpopup'});
    this.showPlaylistLoader = true;
    this.service.getYoutubePlaylistItems(id).subscribe((res:any)=>{
      this.showPlaylistLoader = false;
      console.log(res);
      this.selectedYoutubePlaylist = res.data;
      this.selectedYoutubePlaylistIndex = index;
    },err => {
      this.showPlaylistLoader = false;
      this.toastrService.info("please try after sometime");
    })
  }

  close(){
    
    this.youtubePlayListModalRef.hide();
    this.selectedYoutubePlaylist = [];
  }

  getNextVideo(){

    console.log("from get next video");

    if(this.selectedVideoFrom == 'single'){
      console.log("single");
      this.checkNextAndPlay();
    }
    else if(this.selectedVideoFrom == 'playlist'){
      console.log("playlist");
      this.selectedVideoIndex == this.selectedYoutubePlaylist.length-1 ? this.selectedVideoIndex = 0 : this.selectedVideoIndex = this.selectedVideoIndex+1;
      // this.selectedVideoIndex = this.selectedVideoIndex+1;
      this.currentlyPlayingVideoId = this.selectedYoutubePlaylist[this.selectedVideoIndex].videoId;
      this.playVideo(this.currentlyPlayingVideoId);
    }
    else{
      this.toastrService.info("unable to find the video type");
    }
    
    // this.ytPlayerService.
  }

  checkNextAndPlay(){
    console.log("check next and play");
    // debugger;
    // this.selectedVideoIndex = this.selectedVideoIndex + 1;
    for(let i=this.selectedVideoIndex;i<this.playList.length;){
      if(i == this.playList.length-1){
        i = 0;
      }
      else{
        i = i + 1;
      }

      if(this.playList[i].contentType == 'video'){
        this.selectedVideoIndex = i;
        this.playVideo(this.playList[i].items[0].videoId);
        break;
      }
    }
    
  }

  playVideo(videoId){
    console.log("play video",videoId);
    // this.ytPlayerService.destroy();
    console.log(this.ytPlayerService.destroyed);
    this.ytPlayerService.load(videoId);
    // this.ytPlayerService.play();
    // this.ytPlayerService.pause();
    // this.ytPlayerService.playVideo();
    //this.ytPlayerService.on('unstarted', () => {})
    // player.on('ended', () => {})
    // player.on('playing', () => {})
    // player.on('paused', () => {})
    // player.on('buffering', () => {})
    // player.on('cued', () => {})
    // this.ytPlayerService.
    // this.ytPlayerService.loadVideoById({})
  }

  apiChange(e){
    console.log("api change event",e)
  }

  ready(e){
    console.log("ready",e)
  }

  unstarted(e){
    console.log("unstarted",e);
  }

  buffering(e){
    console.log("buffering",e);
  }

  paused(e){
    console.log("paused",e);
  }

  onStateChange(e){
    console.log(e);
    // if(e.type == 6){
    //   setTimeout(()=>{
    //     // let elm: any = document.getElementsByClassName("ytp-chrome-top-buttons")[0];
    //     // elm.style.display = 'none';
    //     // debugger;
    //     const x = document.querySelector("iframe").contentWindow;
    //     //x = window.frames[0];

    //     x.document.querySelector("body").style.backgroundColor = "blue";
    //     // this would turn the 1st iframe in document blue.

    //     console.log(x);
    //     console.log(document.getElementsByClassName("ytp-chrome-top-buttons")[0]);
    //   },10000);
    // }  
    if(e.type == 3){
      console.log("going to next");
      this.getNextVideo();
    }
  }

  removeYoutubeId;
  removeYoutubeIndex;
  removeModalref;
  removingItem = '';

  // openRemoveYoutubeUrlModal(id, index){
  //   this.removeYoutubeId = id;
  //   this.removeYoutubeIndex = index;
  //   this.removeModalref = this.modalService.show(this.removeYoutubeUrlModal);
  // }

  openRemoveYoutubeModal(type,id, index){
    if(type == 'single'){
      this.removingItem = 'video';
    }
    else if(type == 'playlist'){
      this.removingItem = 'playlist';
    }
    else if(type == 'playlistItem'){
      this.removingItem = 'track from playlist';
    }
    this.removeYoutubeId = id;
    this.removeYoutubeIndex = index;
    this.removeModalref = this.modalService.show(this.removeYoutubeModal,{class : 'removeytmodal'});
  }

  removeYoutube(){
    this.showRemoveUrlLoader = true;
    switch(this.removingItem){
      case 'video' : this.removeYoutubeUrl(); break;
      case 'playlist' : this.removePlaylist(); break;
      case 'track from playlist' : this.removePlaylistItem(); break;
    }
  }
  showRemoveUrlLoader: Boolean = false;
  removeYoutubeUrl(){
    this.service.removeYoutubeUrl(this.removeYoutubeId).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.playList.splice(this.removeYoutubeIndex,1);
      }
      this.removeModalref.hide();
      this.showRemoveUrlLoader = false;
    },err => {
      this.showRemoveUrlLoader = false;
      this.removeModalref.hide();
      this.toastrService.info("please try after sometime");
    })
  }

  removePlaylist(){
    let id = this.playList[this.removeYoutubeIndex]._id;
    this.service.removeYoutubePlaylist(id).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.playList.splice(this.removeYoutubeIndex,1);
      }
      this.removeModalref.hide();
      this.showRemoveUrlLoader = false;
    },err => {
      this.showRemoveUrlLoader = false;
      this.removeModalref.hide();
      this.toastrService.info("please try after sometime");
    })
  }

  removePlaylistItem(){
    let id = this.selectedYoutubePlaylist[this.removeYoutubeIndex]._id;
    this.service.removeYoutubePlaylistItem(id).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.selectedYoutubePlaylist.splice(this.removeYoutubeIndex,1);
        if(!this.selectedYoutubePlaylist.length){
          this.playList.splice(this.selectedYoutubePlaylistIndex,1);
          this.youtubePlayListModalRef?.hide();
        }
      }
      this.removeModalref.hide();
      this.showRemoveUrlLoader = false;
    },err => {
      this.showRemoveUrlLoader = false;
      this.removeModalref.hide();
      this.toastrService.info("please try after sometime");
    })
  }

  // currentlyPlayingVideoType;
  // currentlyPlayingVideoIndex;
  // currentlyPlayingPlaylistIndex;

  playPreviousVideo(){

    this.getPreviousVideo();

  }

  playNextVideo(){

    this.getNextVideo();


  }

  getPreviousVideo(){

    console.log("from get previous video");

    if(this.selectedVideoFrom == 'single'){
      console.log("single");
      this.checkPrevoiusSingleAndPlay();
    }
    else if(this.selectedVideoFrom == 'playlist'){
      console.log("playlist");
      this.selectedVideoIndex == 0 ? this.selectedVideoIndex = this.selectedYoutubePlaylist.length-1 : this.selectedVideoIndex = this.selectedVideoIndex-1;
      // this.selectedVideoIndex = this.selectedVideoIndex+1;
      this.currentlyPlayingVideoId = this.selectedYoutubePlaylist[this.selectedVideoIndex].videoId;
      this.playVideo(this.currentlyPlayingVideoId);
    }
    else{
      this.toastrService.info("unable to find the video type");
    }
    
    // this.ytPlayerService.
  }

  checkPrevoiusSingleAndPlay(){
    console.log("check previous single and play");
    // debugger;
    // this.selectedVideoIndex = this.selectedVideoIndex + 1;
    for(let i=this.selectedVideoIndex;i<this.playList.length;){
      if(i == 0){
        i = this.playList.length-1;
      }
      else{
        i = i - 1;
      }

      if(this.playList[i].contentType == 'video'){
        this.selectedVideoIndex = i;
        this.playVideo(this.playList[i].items[0].videoId);
        break;
      }
    }
    
  }
  @Input('addYoutube')
  set addYoutube(data: any) {
      //do whatever you want with your data here, this data will be passed from parent component
      console.log("youtube to add",data);
      if(data && Object.keys(data).length){
        if(data?.items?.length){
          data.totalCount = data?.items?.length;
        }
        this.playList.unshift(data);
      }
  }

}
