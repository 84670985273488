// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: "http://localhost:3000/",   // indie_town api
  // API_URL: "https://stg-indietown-api.approd.ca/",   // stage indie_town api

  // BaseChatUrl: "http://localhost:3000", // production server

  API_URL: "https://indietown-api.approd.ca/",
  BaseChatUrl: "https://indietown-api.approd.ca",
  basicAuthUsername:'admin',
  basicAuthpwd:'JKJ@IKHUKHYJSHsd',
  firebase:{
    apiKey: "AIzaSyA3UHR97zBSoC0TdNWkWGxOSh5lgCQ64W8",
    authDomain: "indietown-3c44c.firebaseapp.com",
    projectId: "indietown-3c44c",
    storageBucket: "indietown-3c44c.appspot.com",
    messagingSenderId: "380016103554",
    appId: "1:380016103554:web:25c12eec1688ec5eb2649b",
    measurementId: "G-WWGS9T9LW6"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
