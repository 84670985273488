<div class="d-flex justify-content-between" style="width: 100%; padding: 10px;    padding-inline: 0px;" *ngIf="spotifyList.length">
    <div>
        <span><img style="    margin-right: 7px;" src="../../../assets/Indietown_icons/spotify-green-black.svg" alt=""></span>
        <span class="musictitle">Spotify</span>
    </div>
    <div style="padding-top: 2%;" label="Spotify" *ngIf="spotifyList.length>3">
        <span class="seealltext" (click)="navigateToSpotify()">See all</span>
    </div>
</div>

<div class="mt-2" *ngIf="spotifyList.length">
    <div [ngClass]="{'spotifycardh' : spotifyList.length>0}">
    <div class="spotify-card align-items-center" *ngFor="let i of spotifyList | slice:0:3" >
        <!-- (click)="openSpotifyModal(i)" -->
            <div class="row" style="height: 80px;align-items: center;" (click)="navigateToSpotify()">
                <div class="col-lg-2 mob-md-2">
                        <img [src]="i?.imageUrl" alt="" height="60" width="60" style="border-radius: 10px;">
                </div>
                <div class="col-lg-7 mob-md-7" style="padding-top: 1%;padding-left: 20.99px;">
                        <p style="margin-bottom: 5px;">
                            <span class="musicsubtitle" *ngIf="i?.name.length>=15">{{i?.name.slice(0,15)}} ...</span>
                            <span class="musicsubtitle" *ngIf="i?.name.length<15">{{i?.name}}</span>
                        </p>
                        <p class="musicsubtitle" *ngIf="i.contentType == 'track'">{{getTimeRemaining(i?.items[0].duration_ms)}}</p>
                        <!-- <audio controls>
                            <source [src]="i?.track?.preview_url" type="audio/ogg"> 
                            <source [src]="i?.track?.preview_url" type="audio/mpeg">
                            Your browser does not support the audio tag.
                        </audio> -->
                </div>
                <div class="col-lg-3 mob-md-2" style="margin-top: 11px;">
                        <img class="videoicnnn" src="../../../assets/Indietown_icons/videoicn.svg" alt="" height="30" width="30">
                        <!-- <img src="../../../assets/Indietown_icons/music-video.svg" -->
                </div>
            </div>
    </div>
</div>
    <h1 *ngIf="!spotifyList.length" style="text-align: center; display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 17%;">No Data Found</h1>

</div>