import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-spotify-play',
  templateUrl: './spotify-play.component.html',
  styleUrls: ['./spotify-play.component.scss']
})
export class SpotifyPlayComponent implements OnInit {

  spotifySongId;
  constructor(
    private route: ActivatedRoute
  ) { 
    this.route.queryParams.subscribe(params => {
      this.spotifySongId = params['id'];
    });
  }

  spotifySongDetails;
  spotifySongType;

  ngOnInit(): void {
    this.spotifySongDetails = JSON.parse(localStorage.getItem("spotifySongDetails"));
    this.spotifySongType = localStorage.getItem("spotifySongType");
    console.log(this.spotifySongDetails);
    console.log(this.spotifySongType);
  }

  getSpotifySongDetails(id){
    return localStorage.getItem("spotifySongDetails");
  }

}
