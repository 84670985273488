import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ProfileService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    createPost(data:any){
        return this.http.post(this.url + 'api/post/create-post', data);
    }

    getPosts(){
        return this.http.get(this.url + 'api/post/list');
    }

    getProfile(){
        return this.http.get(this.url + 'api/user/profile');
    }


    uploadImage(data:any){
        return this.http.post(this.url + 'api/file/upload', data);
    }

    createShow(data:any){
        return this.http.post(this.url + 'api/show/create', data);
    }

    reportPost(data:any,comment:any){
        return this.http.put(this.url + `api/post/report-post/${data}`,comment);
    }

    createComment(data:any){
        return this.http.post(this.url + 'api/comment/create', data);
    }

    getCommentsList(data:any){
        return this.http.get(this.url + `api/comment/list/${data}`)
    }

    getBandSuggestions(data:any){
        return this.http.post(this.url + 'api/user/get-bands-suggession', data);
    }

    AddFriend(data:any){
        return this.http.post(this.url + 'api/friend/add-friend', data);
    }

    acceptRequest(data:any){
        return this.http.post(this.url + 'api/friend/accept', data);
    }

    friendRequestList(){
        return this.http.get(this.url + 'api/friend/fr-list');
    }

    acceptRejectRequest(id:any, data:any){
        return this.http.put(this.url + `api/friend/update-friend-request/${id}`,data);
    }

    silentPost(id:any, data:any){
        return this.http.put(this.url + `api/post/silent-post/${id}`,data);
    }

    reportComment(postId:any, commentId:any,comment:any, data:any){
        return this.http.put(this.url + `api/comment/report`,{postId,commentId,comment});
    }

    updateProfile(data:any){
        return this.http.put(this.url + 'api/user/update-profile', data);
    }

    getFriendsList(){
        return this.http.get(this.url + 'api/friend/list');
    }

    updatePlayList(data:any){
        return this.http.put(this.url + 'api/user/update-play-list', data);
    }

    getPlayList(){
        return this.http.get(this.url + 'api/user/playlist');
    }

    unFriend(id:any,data){
        return this.http.put(this.url + `api/friend/unfriend-user/${id}`,data);
    }

    
    getPostById(id:any){
        return this.http.get(this.url + `api/post/${id}`);
    }

    getProfileById(id:any){
        return this.http.get(this.url + `api/user/user-profile/${id}`);
    }

    getArtistGenerList(){
        return this.http.get(this.url + 'api/genere/list');
    }

    

}  