import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RegistrationPartService } from '../../views/registration-part/services/registration.service';
import { SharedService } from '../services/shared.service';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-register-playlist',
  templateUrl: './register-playlist.component.html',
  styleUrls: ['./register-playlist.component.scss','../../views/registration-part/community-register/community-register.component.scss']
})
export class RegisterPlaylistComponent implements OnInit {

  // youtubeUrlRegex = /^(https:\/\/)((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  // // spotify old -> ^((?:https?:)?\/\/)?((?:www|m)\.)?((?:open.))?((?:spotify\.com))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$
  // spotifyUrlRegex = /^(https:\/\/)((?:www|m)\.)?((?:open.))?((?:spotify\.com))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  // soundcloudUrlRegex = /^(https?:\/\/)?(www.)?(m\.)?soundcloud\.com\/[\w\-\.]+(\/)+[\w\-\.]+?$/;
  // bandcampUrlRegex = /^https?:\/\/(www\.)?(bandcamp\.com|snd\.sc)\/.*$/;

  youtubeUrlRegex = "^https?:\\/\\/(?:www\\.)?(?:youtube\\.com\\/(?:playlist\\?list=|watch\\?v=)|youtu\\.be\\/)(?!channel\\/)([a-zA-Z0-9_-]+)";
  spotifyUrlRegex = "((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:open.))?((?:spotify\\.(com|link)))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?";
  soundcloudUrlRegex = "https://w.soundcloud.com";
  bandcampUrlRegex = "https://bandcamp.com";

  updateProfile = new FormGroup({
    bandName: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('',),
    country: new FormControl('',),
    profilePic: new FormControl(''),
    profilePicKey: new FormControl(''),
    coverPic: new FormControl(''),
    coverPicKey: new FormControl(''),
    about: new FormControl(''),
    spotifyURL: new FormControl('',Validators.pattern(this.spotifyUrlRegex)),
    soundcloudURL: new FormControl('',Validators.pattern(this.soundcloudUrlRegex)),
    bandcampURL: new FormControl('', Validators.pattern(this.bandcampUrlRegex)),
    youtubeURL: new FormControl('',Validators.pattern(this.youtubeUrlRegex)),
    lat: new FormControl(''),
    long: new FormControl(''),
    searchLoaction: new FormControl(''),
    gener: new FormControl(''),
    name: new FormControl(''),
    locationLat: new FormControl(''),
    locationLong: new FormControl(''),
    slider:new FormControl('200'),
    listgener:new FormControl('')
  });

  isMobile:any;
  spotifySelected: boolean;
  spotifyimgsrc: string;
  soundcloudimgsrc: string;
  bandcampimgsrc: string;
  soundcloudSelected: boolean;
  bandcampSelected: boolean;
  nameErrorMsg: string;
  bioErrorMsg: string;
  youtubeError: boolean;
  spotifyError: boolean;
  soundcloudError: boolean;
  bandcampError: boolean;
  invalidName: boolean;
  invalidBio: boolean;
  // f: any;
  spotifyErrorMsg: string;
  youtubeErrorMsg: string;
  soundcloudErrorMsg: string;
  bandcampErrorMsg: string;
  sliderValue: number;
  
  @Output() closePopup = new EventEmitter();

  @Output() urlAdded = new EventEmitter();

  @ViewChild("showErrorModal") showErrorModal: TemplateRef<any>;



  constructor(
    private service: RegistrationPartService,
    private sharedService: SharedService,
    private router: Router,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    ) { 
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // true for mobile device
        this.isMobile = true;
      } else {
        // false for not mobile device
        this.isMobile = false;
      }
  }

  ngOnInit(): void {
    this.onSpotifySelected();
    // this.onSoundcloudSelected();

    // Use backend to get info
    // if(localStorage.getItem('playlist')){
    //   let playlistDetails = JSON.parse(localStorage.getItem('playlist'));
    //   this.updateProfile.controls.spotifyURL.patchValue(playlistDetails.spotify.url);
    //   this.updateProfile.controls.youtubeURL.patchValue(playlistDetails.youtube.url);
    //   this.updateProfile.controls.bandcampURL.patchValue(playlistDetails.bandCamp.url);
    //   this.updateProfile.controls.soundcloudURL.patchValue(playlistDetails.soundClub.url);
    // }

    /**
     * STEPS TO FOLLOW
     * 1.save the url's
     * 2.add condition while displaying tabs or find another way
     * 3.add the validations popup etc..!
     */

    this.modalService.onHide.subscribe((val)=>{
      console.log("hidden sjsjfk");
      console.log(val);
      this.errorModalOpen = false;
    })
    this.modalService.onShow.subscribe((val)=>{
      console.log("Sfsdfsdfsdf show");
      console.log(val);
      this.errorModalOpen = true;
    })
  }

  get f() {
    return this.updateProfile.controls;
  }

  spotifyURL;
  soundcloudURL;
  bandcampURL;
  youtubeURL;
  showErrorModalRef:BsModalRef;
  errorModalOpen;

  onSpotifySelected(){
    this.spotifySelected = true;
    this.spotifyimgsrc ="../../../../assets/Indietown_icons/spotify-selected.svg";
    this.soundcloudimgsrc="../../../../assets/Indietown_icons/soundcloud-not-selected.svg";
    this.bandcampimgsrc="../../../../assets/Indietown_icons/bandcamp-notselected.svg";
    this.soundcloudSelected = false;
    this.bandcampSelected = false;
    this.updateProfile.get('bandcampURL').patchValue('');
    this.updateProfile.get('soundcloudURL').patchValue('');
    this.soundcloudURL = '';
    this.bandcampURL = '';
  }
  
  onSoundcloudSelected(){
    this.spotifySelected = false;
    this.spotifyimgsrc ="../../../../assets/Indietown_icons/spotify-not-selected.svg";
    this.soundcloudimgsrc="../../../../assets/Indietown_icons/soundcloud-selected.svg";
    this.bandcampimgsrc="../../../../assets/Indietown_icons/bandcamp-notselected.svg";
    this.soundcloudSelected = true;
    this.bandcampSelected = false;
    this.updateProfile.get('bandcampURL').patchValue('');
    this.updateProfile.get('spotifyURL').patchValue('');
    this.spotifyURL = '';
    this.bandcampURL = '';
  }
  
  onbandcampSelected(){
    this.spotifySelected = false;
    this.spotifyimgsrc ="../../../../assets/Indietown_icons/spotify-not-selected.svg";
    this.soundcloudimgsrc="../../../../assets/Indietown_icons/soundcloud-not-selected.svg";
    this.bandcampimgsrc="../../../../assets/Indietown_icons/bandcamp-selected.svg";
    this.soundcloudSelected = false;
    this.bandcampSelected = true;
    this.updateProfile.get('soundcloudURL').patchValue('');
    this.updateProfile.get('spotifyURL').patchValue('');
    this.spotifyURL = '';
    this.soundcloudURL = '';
  }

  CheckEnterPress(e:any){
    this.nameErrorMsg = '';
    this.bioErrorMsg = '';
    this.youtubeError=false;
    this.spotifyError=false;
    this.soundcloudError=false;
    this.bandcampError=false;
    this.invalidName = false;
    this.invalidBio = false;
    if (e.keyCode == 13) {               
      e.preventDefault();
      return false;
    } 
  }

  showLoader: Boolean = false;

  async save(){
    let ssybResponse;
    let youtubeResponse;

    
    this.clearAllMessages();

    let t1 = false;
    let t2 = false;
    let term = '';

    if((this.spotifyURL || this.soundcloudURL || this.bandcampURL) && this.youtubeURL){
      if(this.spotifyURL){
        t1 = new RegExp(this.spotifyUrlRegex).test(this.spotifyURL);
      }
      else if(this.soundcloudURL){
        t1 = new RegExp(this.soundcloudUrlRegex).test(this.soundcloudURL);
      }
      else if(this.bandcampURL){
        t1 = new RegExp(this.bandcampUrlRegex).test(this.bandcampURL);
      }

      t2 = new RegExp(this.youtubeUrlRegex).test(this.youtubeURL);

      if(!t1 && !t2){
        this.toastrService.error("Both are Invalid URLs");
        return;
      }
      else if(!t1){
        if(this.spotifyURL)
          this.toastrService.error("Spotify Album, Single or Playlist URL only");
        else if(this.soundcloudURL)
          this.toastrService.error("please enter valid soundcloud Iframe");
        else
          this.toastrService.error("please enter valid bandcamp Iframe");
        return;
      }
      else if(!t2){
        this.toastrService.error("YouTube Video or Playlist URL only");
        return;
      }

    }
    else if(this.youtubeURL){
      if(!new RegExp(this.youtubeUrlRegex).test(this.youtubeURL)){
        this.toastrService.error("YouTube Video or Playlist URL only");
        return;
      }
    }
    else{
      if(this.spotifyURL){
        if(!new RegExp(this.spotifyUrlRegex).test(this.spotifyURL)){
          this.toastrService.error("Spotify Album, Single or Playlist URL only");
          return;
        }
      }
      else if(this.soundcloudURL){
        if(!new RegExp(this.soundcloudUrlRegex).test(this.soundcloudURL)){
          this.toastrService.error("please enter valid soundcloud Iframe");
          return;
        }
      }
      else if(this.bandcampURL){
        if(!new RegExp(this.bandcampUrlRegex).test(this.bandcampURL)){
          this.toastrService.error("please enter valid bandcamp Iframe");
          return;
        }
      }
    }


    this.showLoader = true;

    if(this.spotifyURL){
      // if(!new RegExp(this.spotifyUrlRegex).test(this.spotifyURL)){
      //   this.toastrService.error("please enter valid spotify url");
      //   this.showLoader = false;
      //   return;
      // }
      ssybResponse = await this.addSpotifyURL();
      console.log("ssybResponse ",ssybResponse);
    }
    else if(this.soundcloudURL){
      // if(!new RegExp(this.soundcloudUrlRegex).test(this.soundcloudURL)){
      //   this.toastrService.error("please enter valid soundcloud iframe code");
      //   this.showLoader = false;
      //   return;
      // }
      ssybResponse = await this.addSoundcloudIframe();
      console.log("ssybResponse ",ssybResponse);
    }
    else if(this.bandcampURL){
      // if(!new RegExp(this.bandcampUrlRegex).test(this.bandcampURL)){
      //   this.toastrService.error("please enter valid bandcamp iframe code");
      //   this.showLoader = false;
      //   return;
      // }
      ssybResponse = await this.addBandcampIframe();
      console.log("ssybResponse ",ssybResponse);
    }

    if(this.youtubeURL){
      // if(!new RegExp(this.youtubeUrlRegex).test(this.youtubeURL)){
      //   this.toastrService.error("please enter valid youtube url");
      //   this.showLoader = false;
      //   return;
      // }
      youtubeResponse = await this.addYoutubeURL();
      console.log("youtubeResponse ",youtubeResponse);
    }

    this.showLoader = false;

    console.log(ssybResponse);
    console.log(youtubeResponse);
    this.showErrorModalRef = this.modalService.show(this.showErrorModal,{class : 'showErrorModal'});
  }

  addSpotifyURL(){

    //check spotify validation here

    return new Promise((resolve,reject)=>{
      this.sharedService.addSpotifyUrl({url:this.spotifyURL}).subscribe((res:any)=>{
        console.log(res);
        if(res.type == 'success'){
          this.spotifySuccessMsg = "Spotify URL added";
          // this.toastrService.info(this.spotifySuccessMsg);
          this.spotifyURL = '';
          // this.urlAdded.emit("showSpotifyTab");
          this.urlAdded.emit({name:"showSpotifyTab",data:res.data});
          resolve(this.spotifySuccessMsg);
          // this.checkAndCloseTheModal();
          
        }
       },(err)=>{
        console.log(err);
        // this.toastrService.info(err?.error?.message);
        if(err?.error?.message == 'Invalid URL'){
          this.spotifyErrorMsg = 'Spotify Album, Single or Playlist URL only';
        }
        else{
          if(Object.keys(err?.error?.message)?.length){
            this.spotifyErrorMsg = err?.error?.message;
          }
          else{
            this.spotifyErrorMsg = "Spotify Album, Single or Playlist URL only";
          }
        }
        // this.spotifyErrorMsg = 'Spotify - ' + err?.error?.message;

        resolve(this.spotifyErrorMsg);
        
        // if(!this.errorModalOpen)
        //   this.showErrorModalRef = this.modalService.show(this.showErrorModal,{class : ''});
      })
    })
    
  }

  addMore(){
    
    // debugger;
    // if(this.spotifySuccessMsg || this.soundcloudSuccessMsg || this.bandcampSuccessMsg){
    //   this.spotifyURL = '';
    //   this.soundcloudURL = '';
    //   this.bandcampURL = '';
    // }
    // if(this.youtubeSuccessMsg){
    //   this.youtubeURL = '';
    // }
    // this.clearAllMessages();
    this.showErrorModalRef.hide();

  }

  continue(){
    this.clearAllMessages();
    this.showErrorModalRef?.hide();
    this.closePopup.emit();
    // this.updateProfileAll();
  }

  clearAllMessages(){
    this.spotifySuccessMsg = '';
    this.soundcloudSuccessMsg = '';
    this.bandcampSuccessMsg = '';
    this.youtubeSuccessMsg = '';
    this.spotifyErrorMsg = '';
    this.soundcloudErrorMsg = '';
    this.bandcampErrorMsg = '';
    this.youtubeErrorMsg = '';
  }

  addSoundcloudIframe(){
    return new Promise((resolve,reject)=>{
      this.sharedService.addSoundcloudIframe({iframeCode:this.soundcloudURL}).subscribe((res:any)=>{
        console.log(res);
        if(res.type == 'success'){
          this.soundcloudSuccessMsg = "SoundCloud embed code added";
          // this.toastrService.info(this.soundcloudSuccessMsg);
          this.soundcloudURL = '';
          // this.urlAdded.emit("showSoundcloudTab");
          this.urlAdded.emit({name:"showSoundcloudTab",data:res.data});
          resolve(this.soundcloudSuccessMsg);
          // this.checkAndCloseTheModal();
        }
      },
      (err)=>{
        console.log(err);
        console.log(err?.error?.message);
        // this.toastrService.error(err?.error?.message);
        if(err?.error?.message == 'Invalid iFrame code'){
          this.soundcloudErrorMsg = 'Invalid SoundCloud embed code';
        }
        else if(err?.error?.message == 'iFrame code already added'){
          this.soundcloudErrorMsg = 'SoundCloud iFrame code already added';
        }
        else{
          if(Object.keys(err?.error?.message)?.length){
            this.soundcloudErrorMsg = err?.error?.message;
          }
          else{
            this.soundcloudErrorMsg = "Invalid SoundCloud embed code";
          }
          
        }
        // this.soundcloudErrorMsg = 'Soundcloud - ' + err?.error?.message;
        resolve(this.soundcloudErrorMsg);
        // if(!this.errorModalOpen)
        //   this.showErrorModalRef = this.modalService.show(this.showErrorModal,{class : ''});
      })
    })
  }

  addBandcampIframe(){
    return new Promise((resolve,reject)=>{
      this.sharedService.addBandcampIframe({iframeCode:this.bandcampURL}).subscribe((res:any)=>{
        console.log(res);
        if(res.type == 'success'){
          this.bandcampSuccessMsg = "Bandcamp embed code added";
          // this.toastrService.info(this.bandcampSuccessMsg);
          this.bandcampURL = '';
          this.urlAdded.emit({name:"showBandcampTab",data:res.data});
          // this.checkAndCloseTheModal();
          resolve(this.bandcampSuccessMsg);
        }
      },
      (err)=>{
        console.log(err);
        console.log(err?.error?.message);
        // this.toastrService.info(err?.error?.message);
        if(err?.error?.message == 'Invalid iFrame code'){
          this.bandcampErrorMsg = 'Invalid Bandcamp embed code';
        }
        else if(err?.error?.message == 'iFrame code already added'){
          this.bandcampErrorMsg = 'Bandcamp iFrame code already added';
        }
        else{
          if(Object.keys(err?.error?.message)?.length){
            this.bandcampErrorMsg = err?.error?.message;
          }
          else{
            this.bandcampErrorMsg = "Invalid Bandcamp embed code";
          }
        }
        // this.bandcampErrorMsg = 'Bandcamp' + err?.error?.message;
        resolve(this.bandcampErrorMsg);
        // if(!this.errorModalOpen)
        //   this.showErrorModalRef = this.modalService.show(this.showErrorModal,{class : ''});
      })
    })
    
  }

  spotifySuccessMsg;
  soundcloudSuccessMsg;
  bandcampSuccessMsg;
  youtubeSuccessMsg;

  addYoutubeURL(){

    return new Promise((resolve,reject)=>{
      this.sharedService.addYoutubeUrl({url:this.youtubeURL}).subscribe((res:any)=>{
        console.log(res);
        if(res.type == 'success'){
          this.youtubeSuccessMsg = "YouTube URL added";
          // this.toastrService.info(this.youtubeSuccessMsg);
          this.youtubeURL = '';
          // this.urlAdded.emit("showYoutubeTab");
          this.urlAdded.emit({name:"showYoutubeTab",data:res.data});
          // this.checkAndCloseTheModal();
          resolve(this.youtubeSuccessMsg);
        }
      },
      (err)=>{
        console.log(err);
        console.log(err?.error?.message);
        // this.toastrService.info(err?.error?.message);
        if(err?.error?.message == 'Invalid URL'){
          this.youtubeErrorMsg = 'YouTube Video or Playlist URL only';
        }
        else{
          if(Object.keys(err?.error?.message)?.length){
            this.youtubeErrorMsg = err?.error?.message;
          }
          else{
            this.youtubeErrorMsg = "YouTube Video or Playlist URL only";
          }
        }
        
        resolve(this.youtubeErrorMsg);
        // if(!this.errorModalOpen)
        //   this.showErrorModalRef = this.modalService.show(this.showErrorModal,{class : ''});
      })
    })
    
  }

  checkAndCloseTheModal(){
    if(!this.spotifyErrorMsg && !this.soundcloudErrorMsg && !this.bandcampErrorMsg && !this.youtubeErrorMsg){
      // this.closeBothModals();
    }
  }

  connectSpcialAccount(){
    if(this.f.spotifyURL?.errors?.pattern){
      this.spotifyError = true;
      this.spotifyErrorMsg = 'Please enter a valid url';
      document.getElementById('getstartedbuttonid').style.display="flex";
      return
    }
    
    else if(this.f.youtubeURL?.errors?.pattern){
      this.youtubeError = true;
      this.youtubeErrorMsg = 'Please enter a valid url';
      document.getElementById('container1').style.height="auto";
      document.getElementById('progressbar').style.position="relative";
      return
    }

    else if(this.f.soundcloudURL?.errors?.pattern){
      this.soundcloudError = true;
      this.soundcloudErrorMsg = 'Please enter a valid url';
    } 

    else if(this.f.bandcampURL?.errors?.pattern){
      this.bandcampError = true;
      this.bandcampErrorMsg = 'Please enter a valid url';
    }

    else{
      this.youtubeError=false;
      this.spotifyError=false;
      this.soundcloudError=false;
      this.bandcampError=false;
      this.sliderValue = 80;
      // (<HTMLInputElement>document.querySelector(".slidepage")).style.marginLeft="-78.3%";
      this.updateProfileData();
      this.getBandSuggestions();
  document.getElementById('communityflow').classList.add('tabartistflow');
  // document.getElementById('getstartedbuttonid').style.display="";
  document.getElementById('container1').classList.add('communityartistflow');
    }
   
  }

  notFount;
  bandSuggestionList:any = [];

  getBandSuggestions(){
    let data = {
      // "radius": 200,
      // "genre": this.generName,
      // "phoneBook":["7780984169"],
      "pageNumber":1,
      // "bandName":this.updateProfile.value.bandName,
      // "bandName":'',
      // "location":{
      //   "lat": this.updateProfile.value.lat,
      //   "long":this.updateProfile.value.long,    
      // }
    }

    this.service.getBandSuggestions(data).subscribe((res:any) => {
      if(res.type == 'success'){
        if(res.data.length == 0){
          this.notFount = true;
        }
        else{
          this.bandSuggestionList = res.data;
          this.bandSuggestionList = this.bandSuggestionList.slice(0,8);
        }
        
        // if(this.bandSuggestionList.length == 0){
        //   this.service.getBandSuggestions({"location":{"lat": '',"long":''}}).subscribe((res:any) => {
        //     if(res.type == 'success'){
        //       this.bandSuggestionList = res.data;
        //       this.bandSuggestionList = this.bandSuggestionList.slice(0,8);
        //     }
        //   })
        // }
      }
    })
  }

  skipconnectSpcialAccount(){
    document.getElementById('container1').style.height="auto";
    // document.getElementById('getstartedbuttonid').style.display="flex";
    document.getElementById('progressbar').style.position="relative";
  document.getElementById('communityflow').classList.add('tabartistflow');
    this.youtubeError=false;
    this.spotifyError=false;
    this.soundcloudError=false;
    this.bandcampError=false;
    this.sliderValue = 80;
    this.getBandSuggestions();
    // localStorage.setItem("screensCompleted",'playlist');
    this.updateProfileAll();
    // this.router.navigateByUrl('/register/find-other-artist');
  }

  updateProfileData() {
    // throw new Error('Method not implemented.');
    // let data = {
    //   "spotify" : {url:this.updateProfile.value.spotifyURL},
    //   "youtube" : {url:this.updateProfile.value.youtubeURL},
    //   "bandCamp": {url: this.updateProfile.value.bandcampURL},
    //   "soundClub": {url:this.updateProfile.value.soundcloudURL}
    // };
    let data = {};
    
    

    if(this.updateProfile.value.spotifyURL){
      data['spotify'] = {url:this.updateProfile.value.spotifyURL};
    }
    if(this.updateProfile.value.youtubeURL){
      data["youtube"] = {url:this.updateProfile.value.youtubeURL};
    }
    if(this.updateProfile.value.bandcampURL){
      data["bandCamp"] = {url: this.updateProfile.value.bandcampURL};
    }
    if(this.updateProfile.value.soundcloudURL){
      data["soundClub"] = {url:this.updateProfile.value.soundcloudURL};
    }
    
    localStorage.setItem('playlist',JSON.stringify(data));
    localStorage.setItem("screensCompleted",'playlist');

    this.service.updatePlayList(data).subscribe((res:any)=> {
      if(res.type =='success'){
        // this.router.navigateByUrl('/register/find-other-artist');
        this.updateProfileAll();
      }
    })
    // let playData;
    // if(this.updateProfile.value.youtubeURL!=''){
    //   if(this.updateProfile.value.spotifyURL== '' && this.updateProfile.value.soundcloudURL == ''){
    //     playData = {
    //       "youtube":{
    //        "url":this.updateProfile.value.youtubeURL
    //       },
    //       "bandCamp":{
    //        "url":this.updateProfile.value.bandcampURL
    //       }
    //     }
    //     this.service.updatePlayList(playData).subscribe((res:any)=> {
    //       if(res.type =='success'){
    //         this.router.navigateByUrl('/register/find-other-artist');
    //       }
    //     })
    //   }
    // }

    // if(this.updateProfile.value.youtubeURL!=''){
    //   if(this.updateProfile.value.spotifyURL== '' && this.updateProfile.value.bandcampURL == ''){
    //     playData = {
    //       "youtube":{
    //       "url":this.updateProfile.value.youtubeURL
    //       },
    //       "soundClub":{
    //         "url":this.updateProfile.value.soundcloudURL
    //       },
    //     }
    //     this.service.updatePlayList(playData).subscribe((res:any)=> {
    //       if(res.type =='success'){
    //         this.router.navigateByUrl('/register/find-other-artist');
    //       }
    //     })
    //   }
    // }

    // if(this.updateProfile.value.youtubeURL!=''){
    //   if(this.updateProfile.value.soundcloudURL== '' && this.updateProfile.value.bandcampURL == ''){
    //     playData = {
    //       "youtube":{
    //       "url":this.updateProfile.value.youtubeURL
    //       },
    //       "spotify":{
    //         "url":this.updateProfile.value.spotifyURL
    //       }
    //     }
    //     this.service.updatePlayList(playData).subscribe((res:any)=> {
    //       if(res.type =='success'){
    //         this.router.navigateByUrl('/register/find-other-artist');
    //       }
    //     })
    //   }
    // }
    
  }

  updateProfileAll(){
    let data = {
      "role": localStorage.getItem('selectedRole'),
      "address":{
          "city": JSON.parse(localStorage.getItem('address')).address.city,
          "state": JSON.parse(localStorage.getItem('address')).address.state,
          "country": JSON.parse(localStorage.getItem('address')).address.country,
      },
      "bandName": JSON.parse(localStorage.getItem('address')).bandName,
      "location":{
          "lat": JSON.parse(localStorage.getItem('address')).location.lat,
          "long": JSON.parse(localStorage.getItem('address')).location.long    
      },
      "profileImage":{
          "fileUrl": JSON.parse(localStorage.getItem('profilePicture'))?.fileUrl,
          "key": JSON.parse(localStorage.getItem('profilePicture'))?.key
      },

      // "playList": {
      //   "spotify" : JSON.parse(localStorage.getItem('profilePicture')).,
      //   "youtube" : {url:this.updateProfile.value.youtubeURL},
      //   "bandCamp": {url: this.updateProfile.value.bandcampURL},
      //   "soundClub": {url:this.updateProfile.value.soundcloudURL}
      // },
      "coverImage":{
          "fileUrl": JSON.parse(localStorage.getItem('coverPicture'))?.fileUrl,
          "key": JSON.parse(localStorage.getItem('coverPicture'))?.fileUrl,
      },
      "email":localStorage.getItem('email'),
      "bio": localStorage.getItem('bio'),
      "communityIds": localStorage.getItem('selectedRole') != 'Artist'?localStorage.getItem('communityIds').split(","):[],
      "genereIds": localStorage.getItem('selectedRole') == 'Artist'?JSON.parse(localStorage.getItem('geners')):[]
    } 

    this.service.updateProfile(data).subscribe((res:any)=> {
      if(res.type == 'success'){
        console.log("success");
        this.router.navigateByUrl('/register/find-other-artist');
      }
    })
  }

  closeAddPlaylistModal(){
    this.clearAllMessages();
    this.showErrorModalRef?.hide();
    this.closePopup.emit();
  }
}
