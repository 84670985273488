<!-- <div id="forgot-password">
  <div class="container-fluid">
    <div class="d-flex logoimg">
      <div class="logo">
        <img
          src="../../../../assets/Indietown_icons/login-logo.svg"
          alt="logo"
        />
      </div>
    </div>
  </div>
</div> -->

<div class="centerplacement" style="display: flex;
height: 100%;
align-items: center;
justify-content: center;">
<div class="mob-pad" style="background: #FFFFFF;
border: 1px solid rgba(15, 13, 15, 0.05);
box-shadow: 0px 14px 34px rgb(0 0 0 / 5%);
border-radius: 20px;
height: auto;
padding: 33px 47px;
padding-bottom: 10px;
display: flex;
margin-top: 46px;
flex-direction: column;">
<div class="d-flex justify-content-center y-2" style="margin-top: 25px;
margin-bottom: 80px;">
  <h1 style="margin-top: 50px; margin-bottom: 15px;"><span class="text-grey">Update </span> <span *ngIf="this.profileData?.role == 'Artist'">Genre</span> <span  *ngIf="this.profileData?.role == 'Community'">Community</span> </h1>
</div>
<div class="mob-w100" style="margin-left: 18px;width: 753px;">
  <div class="selectedgenre">Selected {{chips.size}}</div>
  <div class="selectedtxt">You can rearrange these genres by dragging</div>
  <!-- <mat-chip-list class="selectegener" [multiple]="true" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)"> -->
    <!-- <mat-chip>
              <span *ngIf="this.profileData.role=='Artist'">Genrename <img src="../../../assets/Indietown_icons/genreselect.svg" alt=""></span>
              <span *ngIf="this.profileData.role!='Artist'">Genrename <img src="../../../assets/Indietown_icons/genreselect.svg" alt=""></span>
    </mat-chip> -->
    <!-- <mat-chip *ngFor="let option of chips;let j = index" [selected]="chips.has(option)" cdkDrag>
      <span *ngIf="this.profileData.role=='Artist'">{{ option.genereName }}<img src="../../../assets/Indietown_icons/genreselect.svg" alt="" (click)="toggleChip(option)"></span>
      <span *ngIf="this.profileData.role!='Artist'">{{ option.communityName }}<img src="../../../assets/Indietown_icons/genreselect.svg" alt="" (click)="toggleChip(option)"></span>
    </mat-chip>
</mat-chip-list> -->

  <div class="selectegener d-flex" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
    <div *ngFor="let option of chips" class="pl-2 pr-2 mr-2" style="background: black;
    color: white;
    padding: 6px;
    border-radius: 20px;" cdkDrag>
      <span *ngIf="this.profileData.role=='Artist'">{{ option.genereName }}<img class="ml-2" src="../../../assets/Indietown_icons/genreselect.svg" alt="" (click)="toggleChip(option)"></span>
      <span *ngIf="this.profileData.role!='Artist'">{{ option.communityName }}<img src="../../../assets/Indietown_icons/genreselect.svg" alt="" (click)="toggleChip(option)"></span>
    </div>
  </div>
  <!-- <div class="d-flex" cdkDropList [cdkDropListOrientation]="horizontal" (cdkDropListDropped)="drop($event)">
    <div cdkDrag>1</div>
    <div cdkDrag>2</div>
    <div cdkDrag>3</div>

  </div> -->
  
</div>
<div style="margin-top: 10px;">
  <p *ngIf="this.role =='Artist'" style="color: #CFCFCF;text-align: left;padding-left: 20px;" class="choosegenre">Select 1 -3</p>
  <p *ngIf="this.role !='Artist'" style="color: #CFCFCF;text-align: left;padding-left: 20px;" class="choosegenre">Select 1 -2</p>
</div>

<form action="#" [formGroup]="generForm">
      <div class="d-flex justify-content-center" style="margin-bottom: 50px;">
        <div class="genrelist">
          <mat-chip-list [multiple]="true" selectable >
                <mat-chip *ngFor="let option of genreList"
                          (click)="toggleChip(option)"
                          [selected]="chips.has(option)">
                          <span *ngIf="this.profileData.role=='Artist'">{{ option.genereName }}</span>
                          <span *ngIf="this.profileData.role!='Artist'">{{ option.communityName }}</span>
                </mat-chip>
          </mat-chip-list>

          <!-- <mat-chip-list
            aria-label="Fish selection"
            multiple="true"
            selectable
            (change)="onSelectGener($event)"
            style="margin-top: 5%"
            formControlName="gener"
            id="matChipList"
          >
            <mat-chip
              #chipRef="matChip"
              *ngFor="let chip of genreList"
              (click)="chipRef.selectViaInteraction()"
              [selected]="false"
              [value]="chip"
              [removable]="true"
            >
            <span *ngIf="this.profileData.role=='Artist'">{{ chip.genereName }}</span>
            <span *ngIf="this.profileData.role!='Artist'">{{ chip.communityName }}</span>
             <span hidden>{{ chip._id }}</span>
            </mat-chip>
          </mat-chip-list> -->
        </div>
      </div>
</form>


      

</div>
<div class="save-button">
  <button class="btn btn-primary" (click)="save()" [disabled]="chipControl.value.size<1"> Save </button>
</div>
</div>