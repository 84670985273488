<div class="row" style="margin: 0px">
    <div class="col-lg-7 p-0 h-500" [ngClass]="{'spotifySongSelectedff' : spotifySongSelected}"
        style="margin-bottom: 0px;" infinite-scroll [scrollWindow]="false" (scrolled)="loadMoreSpotifyTracks($event)"
        [infiniteScrollDistance]="6">
        <!-- <h2>In-App Integration Coming Soon!</h2> -->
        <div class="d-flex align-items-center spotifyurlbox" *ngIf="!userId" style="    width: 97%;">
            <div class="form-group col-lg-9 pl-0">
                <!-- Enter Spotify URL -->
                <input type="text" [(ngModel)]="myInputValuedyn" class="form-control" id="spotifyUrlNew"
                    aria-describedby="emailHelp" placeholder="Spotify URL: Album, Single, Playlist"
                    style="height: 50px;border: 1px solid #E9E9EB;">
            </div>
            <div>
                <button type="button" class="btn btn-dark btn-lg form-group" [disabled]="!myInputValuedyn.length"
                    (click)="addSpotifyUrl()" style="height: 50px;
                    /* border-radius: 11px; */
                    /* font-size: 20px; */
                    /* font-weight: 500; */
                    width: 120px;
                    border-radius: 8px;
                    background: var(--primary-colour, #0F0D0F);
                    color: var(--new-style, #F8F9F8);
                    /* text-align: center; */
                    font-size: 16px;
                    /* font-family: Poppins; */
                    font-weight: 600;">Submit</button>
            </div>
        </div>
        <!-- SPOTIFY NEW UI -->
        <!-- <div class="d-flex">
            <div *ngFor="let track of spotifyList.slice(0,3); let spotifyIndex = index" class="d-inine-block mr-4">
                <div class="cursorpointer" (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')">
                    <img [src]=" track?.imageUrl" alt="" height="182" width="192" style="object-fit: cover;"/>
                </div>
                <div class="track-name mt-2 cursorpointer" (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')">
                    <div class="d-inline-block">{{ track?.name | slice:0:16 }}</div>
                    <div *ngIf="track?.name?.length > 16" class="d-inline">...</div>
                </div>
                <div class="track-artist-name">
                    <div class="d-inline-block">{{ track?.items[0]?.artists[0]?.name | slice:0:16 }}</div>
                    <div *ngIf="track?.name?.length > 16" class="d-inline">...</div>
                </div>
            </div>
        </div> -->
            <!-- <ng-template *ngIf="openSpotify">
                hhhhh
                <div *ngFor="let track of spotifyList.slice(3); let spotifyIndex = index" class="d-inine-block mr-4">
                    <div class="">
                        <img [src]=" track?.imageUrl" alt="" height="182" width="192" style="object-fit: cover;"/>
                    </div>
                    <div class="track-name mt-2">
                        <div class="d-inline-block">{{ track?.name | slice:0:16 }}</div>
                        <div *ngIf="track?.name?.length > 16" class="d-inline">...</div>
                    </div>
                    <div class="track-artist-name">
                        <div class="d-inline-block">{{ track?.items[0]?.artists[0]?.name | slice:0:16 }}</div>
                        <div *ngIf="track?.name?.length > 16" class="d-inline">...</div>
                    </div>
                </div>
            </ng-template> -->
        
        <!-- <div class="spotify-logo-open d-block d-flex align-items-center mt-4">
            <img src="assets/Indietown_icons/Spotify_Logo_RGB_Green_2_New.png" width="35px" height="34px"> <div style="font-weight: 500;font-size: 20px;line-height: 30px;display: inline-block;">OPEN SPOTIFY</div> 
         </div> -->
        

        <div class="spotify-music-card m-2" style="padding: 24px;padding-right: 0px;" *ngFor="let track of spotifyList; let spotifyIndex = index">
            <div class="row" style="align-items: center">
                <ng-template [ngIf]="track.contentType == 'track'" class="spotifysingleitem">
                    <div class="col-1 col-md-1 mob-md-1 cursorpointer" (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')">
                        <img [src]="track?.imageUrl" alt="" height="60" width="60" style="border-radius: 15px" />
                    </div>
                    <div class="col-lg-7 col-md-7 mob-md-7 cursorpointer" style="" (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')">
                        <p class="spot-title">{{ track?.name }}</p>
                    </div>
                    <div  style="margin-top: 0px;margin-left: 43px;justify-content: flex-end;" class="spotifybtn d-flex col-lg-3 col-md-3">
                        <button *ngIf="!userId" (click)="openRemoveSpotifyModal('single',spotifyIndex,'')" type="button" style="border:1px solid #E9E9EB;margin-top: 7px;" class="btn btn-light">Remove</button>
                        <p class="spot-time" style="margin-left: 12px;margin-top: 10px;">
                            {{ getTimeInMinuteAndSeconds(track?.items[0].duration_ms) }}
                        </p>
                    </div>
                </ng-template>
            </div>
        </div>

        <!-- playSelectedSong('singleTrack',spotifyIndex,'') -->
        <!-- playSelectedSong('singleTrack',spotifyIndex,'') -->

        <!-- <div class="spotify-music-card m-2" style="padding: 24px;padding-right: 0px;" *ngFor="let track of spotifyList; let spotifyIndex = index">
            <div class="row" style="align-items: center">

                <ng-template [ngIf]="track.contentType == 'track'" class="spotifysingleitem">
                    
                    <div class="col-1 col-md-1 mob-md-1 cursorpointer" (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')">
                        <img [src]="track?.imageUrl" alt="" height="60" width="60" style="border-radius: 15px" />
                    </div>
                    <div class="col-lg-7 col-md-7 mob-md-7 cursorpointer" style="" (click)="navigateToSelectedSong('singleTrack',spotifyIndex,'')">
                        <p class="spot-title">{{ track?.name }}</p>
                    </div>
                    <div  style="margin-top: 0px;
                                        margin-left: 43px;
    justify-content: flex-end;" class="spotifybtn d-flex col-lg-3 col-md-3">
                        <button *ngIf="!userId" (click)="openRemoveSpotifyModal('single',spotifyIndex,'')" type="button" style="border:1px solid #E9E9EB;
                        margin-top: 7px;
                    " class="btn btn-light">Remove</button>
                        <p class="spot-time" style="    margin-left: 12px;
                        margin-top: 10px;">
                            {{ getTimeInMinuteAndSeconds(track?.items[0].duration_ms) }}
                        </p>
                    </div>
                </ng-template>

                Hiding Spotify due to new Features
                 <ng-template [ngIf]="track.contentType == 'playlist' || track.contentType == 'album'">
                    <mat-accordion>
                        <mat-expansion-panel hideToggle (opened)="playlistOpened(spotifyIndex)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="d-flex align-items-center">
                                        <div class="col-1 col-md-1 mob-md-1 p-0 cursorpointer">
                                            <img [src]="track?.imageUrl" alt="" height="60" width="60"
                                                style="border-radius: 15px;margin-top: 10px;" />
                                        </div>

                                        <div class="col-lg-9 col-md-9 mob-md-9 playlist-title cursorpointer" style="">
                                            <p class="spot-title" style="">{{ track?.name }}</p>
                                        </div>
                                    </div>
                                    <div class="spotifybtn" style="margin-left: -78px;
                                                        display: flex;
                                                        margin-top: 10px;
                                                    ">
                                        <button *ngIf="!userId" click-stop-propagation (click)="openRemoveSpotifyModal('playlist',spotifyIndex,'')" style="border:1px solid #E9E9EB; margin-top: 7px;"
                                            type="button" class="btn btn-light">Remove</button>
                                        <p  class="downarrowspot" [ngClass]="{'userprofilearrow': userId}">
                                            <i class="arrow down"></i>
                                        </p>
                                    </div>
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <ul style="padding: 0 !important;" class="fordesktop">
                                <li *ngFor="let subtrack of track.items;let subTrackIndex = index" class="d-flex"
                                     style="padding-top: 10px;padding-bottom: 10px;    align-items: center;
                                                        margin-bottom: 15px; border: 1px solid #0000001A;padding-left: 8px;border-radius: 15px;width: 98%;margin-left: 2px;">
                                    <div class="col-1 col-md-1 mob-md-1 p-0 cursorpointer" (click)="navigateToSelectedSong('playlist',spotifyIndex,subTrackIndex)">
                                        <img [src]="subtrack?.imageUrl" alt="" height="60" width="60"
                                            style="border-radius: 15px" />
                                    </div>
                                    <div class="col-lg-7 col-md-7 mob-md-7 cursorpointer" style="" (click)="navigateToSelectedSong('playlist',spotifyIndex,subTrackIndex)">
                                        <p class="spot-title">{{ subtrack?.name }}</p>
                                    </div>
                                    <div *ngIf="!userId"  style="margin-left: 82px;
                                                        margin-top: 0px;display: flex; cursor: auto; ">
                                        <button type="button" (click)="openRemoveSpotifyModal('playlistItem',spotifyIndex,subTrackIndex)" style="border:1px solid #E9E9EB;margin-top: 7px;"
                                            class="btn btn-light">Remove</button>
                                            <p class="spot-time" style="    margin-left: 12px;
                                            margin-top: 10px;">
                                                {{ getTimeInMinuteAndSeconds(subtrack?.duration_ms) }}
                                            </p>
                                    </div>
                                   
                                    
                                    <div *ngIf="userId" class="col-lg-4 col-md-4 mob-md-2" style="cursor: auto;">
                                        <p class="spot-time" style="    margin-top: 14px;">
                                            {{ getTimeInMinuteAndSeconds(subtrack?.duration_ms) }}
                                        </p>
                                    </div>
                                </li>
                                <li *ngIf="track.expanded" style="text-align: center;">
                                  <img width="50"  src="../../../assets/Indietown_icons/Loading_icon.gif">
                                </li>
                            </ul>
                            <ul style="padding: 0 !important;" class="formobilee">
                                <li *ngFor="let subtrack of track.items;let subTrackIndex = index" class="d-flex"
                                     style="padding-top: 10px;padding-bottom: 10px;    align-items: center;
                                                      margin-bottom: 10px;">
                                    <div class="firstdiv cursorpointer" (click)="navigateToSelectedSong('playlist',spotifyIndex,subTrackIndex)">
                                        <div class="col-1 col-md-1 mob-md-1 p-0">
                                            <img [src]="subtrack?.imageUrl" alt="" height="60" width="60"
                                                style="border-radius: 15px" />
                                        </div>
                                        <div class="col-lg-7 col-md-7 mob-md-7" style="">
                                            <p class="spot-title">{{ subtrack?.name }}</p>
                                        </div>
                                    </div>
                                    <div class="seconddiv">
                                        <div *ngIf="!userId"  style="margin-left: 62px;
                                                        margin-top: -8px;cursor: auto; " class="d-flex">
                                            <button type="button" (click)="openRemoveSpotifyModal('playlistItem',spotifyIndex,subTrackIndex)" style="border:1px solid #E9E9EB"
                                                class="btn btn-light">Remove</button>
                                                <p class="spot-time" style="    margin-left: 12px;
                                                margin-top: 10px;">
                                                    {{ getTimeInMinuteAndSeconds(subtrack?.duration_ms) }}
                                                </p>
                                        </div>

                                        <div *ngIf="userId" class="col-lg-4 col-md-4 mob-md-2" style="cursor: auto;">
                                            <p class="spot-time" style="    margin-top: 14px;">
                                                {{ getTimeInMinuteAndSeconds(subtrack?.duration_ms) }}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="track.expanded" style="text-align: center;">
                                    <img width="50"  src="../../../assets/Indietown_icons/Loading_icon.gif">
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-template>
            </div>
        </div> -->
        <div *ngIf="showAddUrlLoader" style="text-align: center;">
            <img width="50" src="../../../assets/Indietown_icons/Loading_icon.gif">
        </div>

        <div class="spotifyplayer" *ngIf="spotifySongSelected">
            <div class="d-flex w-80">
                <div class="saudioplayermob">
                    <div class="d-flex spotplayermab">
                        <img class="spotifyplayerimg" [src]="currentlyPlayingSpotifySong?.imageUrl" alt="">
                        <div class="spotifytxtbox">
                            <span class="spotifysongname">{{currentlyPlayingSpotifySong?.name}}</span>
                            <span class="spotifyalbum">{{currentlyPlayingSpotifySong?.albumName}}</span>
                        </div>
                    </div>
                    <div class="saudioplayer visible-xs justify-content-center align-items-center">
                        <img class="volumee" style="cursor:pointer;" src="../../../assets/Indietown_icons/volume-up.svg"
                            alt="">
                        <img class="volumee" style="display: none;cursor:pointer"
                            src="../../../assets/Indietown_icons/Volume Off.svg" alt="">
                        <input class="sprogressbar" id="volumeprogress" type="range" min="0" max="100" value="50">
                    </div>
                </div>

                <div class="saudioplayer btnsbox">
                    <div class="playerbtns d-flex">
                        <img class="shuffle" *ngIf="!spotifyShuffleModeOn" (click)="spotifySongShuffleToggle()"
                            src="../../../assets/Indietown_icons/shuffle.svg" alt="">
                        <img class="shuffle" *ngIf="spotifyShuffleModeOn" (click)="spotifySongShuffleToggle()"
                            src="../../../assets/Indietown_icons/shuffle-dark.svg" alt="">
                        <img class="backward" id="spotifyPrevious" (click)="spotifyPlayPrevious()"
                            src="../../../assets/Indietown_icons/skip-forward.svg" alt="">
                        <img class="backward" style="display:none;"
                            src="../../../assets/Indietown_icons/skip-forward-disabled.svg" alt="">
                        <img class="playbtn" style="display: none;"
                            src="../../../assets/Indietown_icons/spotifyplayerd.svg" alt="">
                        <img class="playbtn" id="spotifySongPlay" style="width: 40px; height: 40px;"
                            (click)="spotifySongPlay()" src="../../../assets/Indietown_icons/spotifyplayer.svg" alt="">
                        <img class="playbtn" id="spotifySongPause" (click)="spotifySongPause()"
                            style="display: none; width: 40px; height: 40px;"
                            src="../../../assets/Indietown_icons/pauseicon.svg" alt="">
                        <img class="forward" id="spotifyNext" (click)="spotifyPlayNext()"
                            src="../../../assets/Indietown_icons/skip-forward.svg" alt="">
                        <img class="forward" style="display:none;"
                            src="../../../assets/Indietown_icons/skip-forward-disabled.svg" alt="">
                        <img class="repeat" *ngIf="!spotifySongRepeatModeOn" (click)="spotifySongRepeatToggle()"
                            src="../../../assets/Indietown_icons/repeat.svg" alt="">
                        <img class="repeat" *ngIf="spotifySongRepeatModeOn" (click)="spotifySongRepeatToggle()"
                            src="../../../assets/Indietown_icons/repeat-dark.svg" alt="">
                    </div>
                    <div class="splayer d-flex justify-content-center">
                        <p class="currenttime commontimer">{{spotifySongPlayedTill}}</p>
                        <input class="sprogressbar" id="audioprogress" type="range" min="1" max="100"
                            [value]="spotifySongProgressBarValue" (change)="changeSongPosition($event)">
                        <p class="remainingtime commontimer">
                            {{getTimeInMinuteAndSeconds(currentlyPlayingSpotifySong?.duration_ms)}}</p>

                    </div>
                </div>
                <div class="saudioplayer hidden-xs d-flex justify-content-center align-items-center">
                    <img class="volumee" style="cursor: pointer;" *ngIf="spotifyVolumeOn"
                        (click)="spotifyVolumeToggle()" src="../../../assets/Indietown_icons/volume-up.svg" alt="">
                    <img class="volumee" style="cursor: pointer;" *ngIf="!spotifyVolumeOn"
                        (click)="spotifyVolumeToggle()" src="../../../assets/Indietown_icons/Volume Off.svg" alt="">
                    <input class="sprogressbar" id="volumeprogress" type="range" min="1" max="100"
                        [value]="spotifyVolume" (change)="volumeChange($event)">
                </div>
            </div>

        </div>

        <div *ngIf="!spotifyList.length" style="    margin-left: -47px;" class="noplaylistyet">
            No Playlist Yet !
        </div>

    </div>
    <div class="col-lg-5 suggestionscardd" (scroll)="onScroll($event)" [ngClass]="{'positionfix': sidebarFixed==true}"
        style="margin-top: -9%;">
        <app-suggestions-card></app-suggestions-card>
    </div>
</div>

<ng-template #removeSpotifyModal>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="removeModalref.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center align-items-center">
        <p style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;">Are you sure you want to remove
            this {{removingItem}}?</p>
        <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
            <button class="btn btn-red nobtn" (click)="removeSpotify()"
                style="color: black;margin-right: 10px;font-weight:bold;">Yes</button>
            <button class="btn btn-blue logoutbtn" (click)="removeModalref.hide()"
                style="background-color: black;font-weight:bold;">No</button>
        </div>
        <div *ngIf="showRemoveUrlLoader" style="text-align: center;">
            <img width="50" src="../../../assets/Indietown_icons/Loading_icon.gif">
        </div>
    </div>
</ng-template>