import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { RegistrationPartService } from '../../registration-part/services/registration.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'shared-update-city',
  templateUrl: './update-city.component.html',
  styleUrls: ['./update-city.component.scss']
})
export class UpdateCityComponent implements OnInit {

  submitted: boolean = false;
  imgURLCover: any;
  currentUser: any;
  selectedFile: any;
  profilePicUrl: any;
  coverPicUrl: any;
  profileData: any;
  profileImageFileUrl: any;
  profileImageKey: any;
  change: boolean = false;
  genersData: any = [];
  generIdsList: any = [];
  genreList: any = [];
  sample: any = [];
  role: any;

  @Output() addressDetails = new EventEmitter();

  @ViewChild("template") template: TemplateRef<any>;
  constructor(
    // private service: RegistrationPartService,
    private router: Router,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private cdr:ChangeDetectorRef
  ) { }


  ngOnInit(): void {
    this.profilePicUrl = "../../../../assets/Indietown_icons/upload-profile-pic.png";
    this.coverPicUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';

    console.log("from heee");
    // setTimeout(()=>{
    // console.log(1);
    // this.initAutocomplete();
    // console.log(2);
    // },5000);

    // this.initAutocomplete();

    this.sharedService.getProfile().subscribe((res: any) => {
      if (res.type == 'success') {
        this.profileData = res.data;
        // this.cityForm.get('bandName').setValue(this.profileData?.bandName);
        this.cityForm.get('city').setValue(this.profileData?.address?.city);
        this.cityForm.get('state').setValue(this.profileData?.address?.state);
        this.cityForm.get('country').setValue(this.profileData?.address?.country);
        this.cityForm.get('lat').setValue(this.profileData?.location?.coordinates[0]);
        this.cityForm.get('lng').setValue(this.profileData?.location?.coordinates[1]);
      }
    })
  }


  // bandName: new FormControl('', Validators.required),
  cityForm = new FormGroup({
    
    city: new FormControl('', Validators.required),
    state: new FormControl(''),
    country: new FormControl('', Validators.required),
    lat: new FormControl('', Validators.required),
    lng: new FormControl('', Validators.required),
  })

  nameError: any;

  updateName() {
    // if (this.cityForm.value.bandName.trim() == '') {
    //   this.invalidName = true;
    //   this.nameError = '*Please enter your name';
    //   return
    // } else {
    //   this.invalidName = false;
    //   this.nameError = '';
    // }


    let data = {
      "address": {
        "city": this.cityForm.value.city,
        "state": this.cityForm.value.state,
        "country": this.cityForm.value.country,

      },
      // "bandName": this.cityForm.value.bandName.trim(),
      "location": {
        "lat": this.cityForm.value.lat,
        "long": this.cityForm.value.lng
      }
    }
    console.log(data);
    this.sharedService.updateProfile(data).subscribe((res: any) => {
      if (res.type == 'success') {
        // this.router.navigateByUrl("/profile/main");
        this.addressDetails.emit(JSON.stringify(data));
      }
    })
  }


  latitude: any;
  longitude: any;

  initAutocomplete() {
    console.log(3);
    const map = new google.maps.Map(
      document.getElementById("map1234") as HTMLElement,
      {
        center: { lat: this.latitude ? parseFloat(this.latitude) : 28.7041, lng: this.longitude ? parseFloat(this.longitude) : 77.1025 },
        zoom: 13,
        mapTypeId: "roadmap",
      }
    );
    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input123") as HTMLInputElement;
    console.log(input);
    const searchBox = new google.maps.places.Autocomplete(input, {
      types: ['(cities)']
    });;

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("place_changed", () => {
      const place = searchBox.getPlace();

      // if (places.length == 0) {
      //   return;
      // }  

      if (!Object.keys(place)?.length) {
        return;
      }

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      // places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        console.log("Returned place contains no geometry");
        return;
      }

      const icon = {
        url: place.icon as string,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25)
      };

      this.cityForm.get('lat').patchValue(place.geometry.location.lat());
      this.cityForm.get('lng').patchValue(place.geometry.location.lng());
      this.codeLatLng(place.geometry.location.lat(), place.geometry.location.lng());

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
      // });
      map.fitBounds(bounds);
    });

  }



  cityName: any;
  codeLatLng(lat: any, lng: any) {
    let geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[1]) {


          var country = null, countryCode = null, city = null, state = null;
          var c, lc, component;
          for (var r = 0, rl = results.length; r < rl; r += 1) {
              var result = results[r];
                console.log(result)

              if (!city && (result.types[0] === 'locality' || result.types[0] === 'postal_town' || result.types[0] === 'postal_code')) {
                  for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                      component = result.address_components[c];

                      if (component.types[0] === 'locality' || component.types[0] === 'postal_town') {
                          city = component.long_name;

                          break;
                      }
                  }
              }
             
              else if ( result.types[0] === "administrative_area_level_1") {
               
                  for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                      component = result.address_components[c];
                      console.log(component, "component")
                      if (component.types[0] === 'administrative_area_level_1') {
                          state = component.long_name;
                          break;
                      }
                  }
              } else if (!country && result.types[0] === 'country') {
                  country = result.address_components[0].long_name;
                  countryCode = result.address_components[0].short_name;
              }

              if (city && country) {
                  break;
              }
          }

          console.log("City: " + city + ", State: " + state + ", Country: " + country + ", Country Code: " + countryCode);
          if(city && state && country){
            // (<HTMLInputElement>document.getElementById('pac-input'))?.value = city;
            this.cityForm.controls['country'].setValue(country);
            (<HTMLInputElement>document.getElementById('pac-input123')).value = city;
            this.cityName = city;
            this.cityForm.controls['city'].setValue(city);
            this.cityForm.controls['state'].setValue(state);
            // && this.cityForm.value.bandName
            if (this.cityForm.value.city && this.cityForm.value.country) {
              (<HTMLInputElement>document.getElementById('nameAndAddressNext')).disabled = false;
            }
          }
          else{
            this.toastr.info("please search with your city name");

          }

          // this.cityForm.controls['country'].setValue(results[len - 1].formatted_address.toString());
          // (<HTMLInputElement>document.getElementById('pac-input123')).value = results[0].address_components[3].long_name;
          // this.cityName = results[0].address_components[3].long_name;
          // this.cityForm.controls['city'].setValue(this.cityName);
          // this.cityForm.controls['state'].setValue(a.long_name);
          // if (this.cityForm.value.city && this.cityForm.value.country && this.cityForm.value.bandName) {
          //   (<HTMLInputElement>document.getElementById('nameAndAddressNext')).disabled = false;
          // }

          // let a = [];
          // a = results[0].formatted_address.split(",");

          // for (i = 0; i < results[0].address_components.length; i++) {

          //   let len = results.length;
          //   this.cityForm.controls['country'].setValue(results[len - 1].formatted_address.toString());

          //   (<HTMLInputElement>document.getElementById('pac-input123')).value = results[0].address_components[3].long_name;

          //   this.cityName = results[0].address_components[3].long_name;
          //   this.cityForm.controls['city'].setValue(this.cityName);
          //   console.log("11112222");
          //   if (results[0].address_components[i].types[0] == 'postal_code')
          //     (<HTMLInputElement>document.getElementById('abcd')).value = results[0].address_components[i].short_name;
          //   console.log("11112222", (<HTMLInputElement>document.getElementById('abcd')).value);
          // }

          // for (var i = 0; i < results[0].address_components.length; i++) {
          //   for (var b = 0; b < results[0].address_components[i].types.length; b++) {

          //     //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
          //     if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
          //       let a = results[0].address_components[i];
          //       this.cityForm.controls['state'].setValue(a.long_name);
          //       if (this.cityForm.value.city && this.cityForm.value.country && this.cityForm.value.bandName) {
          //         (<HTMLInputElement>document.getElementById('nameAndAddressNext')).disabled = false;
          //       }
          //       // (<HTMLInputElement>document.getElementById('pac-input')).value=cityArray[0];
          //       break;
          //     }
          //   }
          // }
        } else {
          alert("No results found with the given city name");
        }
      } else {
      }
    });
  }


  charCode: any;
  invalidName: any;
  CheckEnterPress(e: any) {
    this.invalidName = false;
    if (e.keyCode == 13) {
      e.preventDefault();
      return false;
    }
  }

  removeCity(e: any) {
    if (e == '') {
      this.hideLocSuggestions();
      this.cityForm.get('state').setValue('');
      this.cityForm.get('country').setValue('');
    }
    else{
      console.log(e);
      this.hideLocSuggestions();
      this.getLocationSuggestions(e);
    }
  }

  getLocationSuggestions(key){
    // delete (document.getElementById("programmingLanguages") as HTMLUListElement).children
    (document.getElementById("programmingLanguages2") as HTMLUListElement).innerHTML = '';
    this.cdr.detectChanges();
    const service = new google.maps.places.AutocompleteService();
    // getQueryPredictions
    // '(regions)','(cities)'
    service.getPlacePredictions({ input: key, types: ['(cities)'] }, (predictions)=>{
      console.log(predictions);
      // (document.getElementById("programmingLanguages") as HTMLUListElement).innerHTML = '';
      this.hideLocSuggestions();
      predictions.forEach((prediction) => {
        if(prediction?.description?.split(',')?.length >= 2){
          const option = document.createElement("li");
          option.appendChild(document.createTextNode(prediction.description));
          option.setAttribute("class","suggestionItemClass");
          option.setAttribute("description",prediction.description);
          option.setAttribute("style","padding:10px;");
          option.setAttribute("placeId",prediction.place_id);
          // option.setAttribute("country",prediction.description.split(",")[2]);
          (document.getElementById("programmingLanguages2") as HTMLUListElement).appendChild(option);
        }
      });
      this.setHandler();
    });
  }

  setHandler(){
    (document.getElementById("programmingLanguages2") as HTMLUListElement).childNodes.forEach((node)=>{
      node.addEventListener('click', (e:any) => {  
        console.log("yeas i am here");
        console.log(e.target.getAttribute('description'));
        let desc = e.target.getAttribute('description');
        let placeId = e.target.getAttribute('placeId');
        (<HTMLInputElement>document.getElementById('pac-input123')).value = desc;
        this.hideLocSuggestions();
        if(desc.split(",")?.length == 2){
          this.getLatLngFromAddress(desc.split(",")[0],desc.split(",")[1]?.trim(),placeId)
        }
        else if(desc.split(",")?.length > 2){
          this.getLatLngFromAddress(desc.split(",")[0],desc.split(",")[2]?.trim(),placeId)
        }
        // this.getLatLngFromAddress(desc.split(",")[0],desc.split(",")[2]?.trim())
        // debugger;
      });
    })
  }

  hideLocSuggestions(){
    (document.getElementById("programmingLanguages2") as HTMLUListElement).innerHTML = '';
    // (<HTMLElement>document.getElementById('programmingLanguages')).click();
    this.cdr.detectChanges();
  }
  
  getLatLngFromAddress(city, country,placeId){

    var address = city +", "+ country;
    var geocoder = new google.maps.Geocoder();

    let obj;

    if(placeId)
      obj = {placeId};
    else
      obj = {address};
  
    geocoder.geocode( obj, (results:any, status) => {
  
      if (status == google.maps.GeocoderStatus.OK) {
        // $('#latitude').val(results[0].geometry.location.Pa);
        // $('#longitude').val(results[0].geometry.location.Qa);
        console.log(results[0].geometry.location?.lat());
        console.log(results[0].geometry.location?.lng());
        
        this.cityForm.get('lat').patchValue(results[0].geometry.location?.lat());
        this.cityForm.get('lng').patchValue(results[0].geometry.location?.lng());
        this.codeLatLng(results[0].geometry.location?.lat(), results[0].geometry.location?.lng());
        // this.disabledSlider = false;
        // this.searchData();
  
      } else {
        console.log("Geocode was not successful for the following reason: " + status);
      }
    });
  }
}